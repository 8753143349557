import { SecLoansApplicants } from '@sec-loans-types'
import { assertValue } from '@shared'
import { YesNo } from '@ui-common/types/types'
import { formatNumber } from '@ui-common/utils/helpers'
import { testIsValidMonetaryValue } from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { InstallmentInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  hasCoApplicant: boolean,
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> =>
  create((data, changedFields) => {
    only(changedFields)

    test('hasInstallment', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasInstallment).inside(Object.values(YesNo))
    })

    omitWhen(data.hasInstallment !== YesNo.Yes, () => {
      testIsValidMonetaryValue('totalInstallment', data.totalInstallment, formatMessage)

      testIsValidMonetaryValue('monthlyInstallment', data.monthlyInstallment, formatMessage)

      omitWhen(!data.totalInstallment || !data.monthlyInstallment, () => {
        test(
          'monthlyInstallment',
          formatMessage(
            { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
            {
              firstValue: formatMessage({ id: 'sec-loans-applicant-info-quick-loans-installment-label' }),
              secondValue: formatMessage({ id: 'sec-loans-total-price-label' }),
            },
          ),
          () => {
            const totalInstallment = assertValue(data.totalInstallment, 'data.totalInstallment')
            const monthlyInstallment = assertValue(data.monthlyInstallment, 'data.monthlyInstallment')
            enforce(formatNumber(totalInstallment) >= formatNumber(monthlyInstallment)).isTruthy()
          },
        )
      })

      omitWhen(!hasCoApplicant, () => {
        test('installmentOwnership', formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(data.installmentOwnership).inside(Object.values(SecLoansApplicants))
        })
      })
    })
  })

import { Link } from '@s-group/design-system-components/Link/Link'
import { Button } from '@ui-components/actions/Button'
import { EmojiType } from '@ui-components/assets/Emojis'
import { ExternalLinkIcon } from '@ui-components/assets/Icons'
import { ErrorComponent } from '@ui-components/containers/ErrorComponent'
import { ErrorVisualMode } from '@ui-components/containers/ErrorComponentTypes'
import { LooseFormSectionContent } from '@ui-components/containers/LooseSectionContent'
import { Divider } from '@ui-components/decorative/Divider'
import { CardSection } from '@ui-components/global/CardStyling'
import { Colors } from '@ui-components/global/colors'
import { FlexColumnContainer } from '@ui-components/global/CommonStyling'
import { Assistant } from '@ui-components/layout/Assistant'
import { BodyText } from '@ui-components/typography/Typography'
import { PropsWithChildren } from 'react'
import { styled } from 'styled-components'

import { RequestIdSection } from './RequestIdSection'

const BottomContainer = styled.div`
  height: 100%;
  width: 100%;
  > div {
    padding: 0;
  }
`

const ActionContainer = styled(FlexColumnContainer)`
  align-items: center;
  justify-content: center;
  justify-content: space-between;
`

export type TransitionActionButton = {
  onClick: () => void
  label: string
}

export type TransitionActionLink = {
  href: string
  label: string
}

interface TransitionErrorIndicatorCommonProps {
  heading: string
  isInfoBackground?: boolean
  requestId?: string
}

export interface TransitionErrorIndicatorWithButtonProps extends TransitionErrorIndicatorCommonProps {
  action?: TransitionActionButton
  mode: ErrorVisualMode
  text?: React.ReactNode
}

export interface TransitionErrorIndicatorWithLinkProps extends TransitionErrorIndicatorCommonProps {
  action: TransitionActionLink
  emoji: EmojiType
  text: React.ReactNode
}

export type TransitionErrorIndicatorProps = PropsWithChildren<
  TransitionErrorIndicatorWithButtonProps | TransitionErrorIndicatorWithLinkProps
>

function isTransitionErrorIndicatorWithLinkProps(
  props: TransitionErrorIndicatorProps,
): props is TransitionErrorIndicatorWithLinkProps {
  return !!props.action && 'href' in props.action
}

export const TransitionErrorIndicator = (props: PropsWithChildren<TransitionErrorIndicatorProps>): JSX.Element => {
  return isTransitionErrorIndicatorWithLinkProps(props) ? (
    <TransitionErrorWithLink {...props} />
  ) : (
    <TransitionErrorWithButton {...props} />
  )
}

const TransitionErrorWithButton = (props: PropsWithChildren<TransitionErrorIndicatorWithButtonProps>) => {
  const { action, heading, text, mode, requestId, children } = props

  const showRequestIdSection = requestId && <RequestIdSection requestId={requestId} />
  return (
    <LooseFormSectionContent>
      <ErrorComponent heading={heading} content={text} mode={mode} />
      {action && (
        <ActionContainer>
          <Button label={action.label} onClick={action.onClick} />
        </ActionContainer>
      )}
      {children && (
        <>
          <Divider />
          {children}
        </>
      )}
      <BottomContainer>{showRequestIdSection}</BottomContainer>
    </LooseFormSectionContent>
  )
}

const TransitionErrorWithLink = ({
  action,
  heading,
  text,
  emoji,
  children,
}: PropsWithChildren<TransitionErrorIndicatorWithLinkProps>) => {
  return (
    <Assistant title={heading} leadText={text} emoji={emoji} twoColumnLayoutSupport={true} fullViewportHeight={true}>
      <NonDecoratedLink href={action.href} aria-label={action.label}>
        <LinkContainer>
          <BodyText>{action.label}</BodyText>
          <ExternalLinkIcon />
        </LinkContainer>
      </NonDecoratedLink>
      {children}
    </Assistant>
  )
}

const LinkContainer = styled(CardSection)`
  display: flex;
  flex-direction: row;
  color: ${Colors.TextDefaultNeutral};
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin: 0px;
`

const NonDecoratedLink = styled(Link)`
  text-decoration: none;
`

import { MAX_REASONABLE_NUMBER, SecLoansLotType } from '@shared'
import { testIsValidMonetaryValue } from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, omitWhen, only, Suite } from 'vest'

import { isGoingToBeOwnLot, isGoingToBeRentalLot } from './realEstateLivingExpensesInfoStepUtils'
import { RealEstateLivingExpensesInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  lotType: SecLoansLotType | undefined,
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changeFields) => {
    only(changeFields)

    testIsValidMonetaryValue(
      'monthlyHeatingExpense',
      data.monthlyHeatingExpense,
      formatMessage,
      MAX_REASONABLE_NUMBER,
      true,
      0,
    )

    testIsValidMonetaryValue(
      'otherMonthlyExpenses',
      data.otherMonthlyExpenses,
      formatMessage,
      MAX_REASONABLE_NUMBER,
      true,
      0,
    )

    omitWhen(isGoingToBeRentalLot(lotType), () => {
      testIsValidMonetaryValue(
        'yearlyRealEstateTax',
        data.yearlyRealEstateTax,
        formatMessage,
        MAX_REASONABLE_NUMBER,
        true,
        0,
      )
    })

    omitWhen(isGoingToBeOwnLot(lotType), () => {
      testIsValidMonetaryValue('yearlyLotRent', data.yearlyLotRent, formatMessage, MAX_REASONABLE_NUMBER, true, 0)
    })
  })
}

import {
  assertValue,
  LocationInfoData,
  OptionalAndNullable,
  SecLoanPurposeType,
  SecLoansHousingLoanTargetType,
} from '@shared'
import { removeConditionalValues } from '@ui-common'
import { convertUndefinedToNull } from '@ui-common/utils/form'
import { ConditionalValues } from '@ui-common-types'

import { CommonMunicipality, resolveLocation, resolveOtherLocation } from '../../../../utils/locationInfo'
import { LocationInfoStepData, StepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { LocationInfoFormData } from './LocationInfoStep'

export const convertToFormValues = (
  stepData: LocationInfoStepData | undefined,
  conditionalValues?: ConditionalValues<Omit<LocationInfoFormData, 'isComplete'>>,
): OptionalAndNullable<LocationInfoFormData> => {
  const ret = convertUndefinedToNull({
    location: resolveLocation(stepData?.municipality) ?? null,
    otherLocation: resolveOtherLocation(stepData?.municipality) ?? null,
    preciseLocation: stepData?.preciseLocation ?? null,
  })
  return conditionalValues ? removeConditionalValues(ret, conditionalValues) : ret
}

export const convertToLocationInfoSubmitData = (formData: LocationInfoFormData): LocationInfoStepData => {
  const municipality = formData.location === CommonMunicipality.Other ? formData.otherLocation : formData.location
  return {
    municipality: assertValue(municipality, 'municipality'),
    preciseLocation: formData.preciseLocation,
  }
}

export const getAndValidateLocationTypeData: StepDataValidator<LocationInfoData | undefined | null> = (
  validationData,
  metadata,
) => {
  const isConstructionPurpose = metadata?.applicationPurpose === SecLoanPurposeType.Construction
  const isTargetTypeNotKnown =
    validationData.targetInfo?.housingLoanInfo?.targetTypeInfo?.targetType === SecLoansHousingLoanTargetType.NotKnown

  if (!isConstructionPurpose && !isTargetTypeNotKnown) {
    return null
  }

  const isValidData = isFormDataValid(
    convertToFormValues(validationData.targetInfo?.locationInfoData),
    createFormValidator,
  )
  return isValidData ? validationData.targetInfo?.locationInfoData : undefined
}

import {
  SecLoansApplicationMetadata,
  SecLoansHeatingType,
  SecLoansHousingSalesSite,
  SecLoansLotOwnership,
  SecLoansResidenceType,
  SecLoansTransactionDateKnown,
  SecLoansValidationConstants,
} from '@shared'
import { YesNo } from '@ui-common/types/types'
import { toNumber } from '@ui-common/utils/helpers'
import {
  validateDate,
  validateDecimal,
  validateInteger,
  validateIntegerFromListOrOther,
  validateShortTextInput,
  validateTextArea,
} from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { WizardData } from '../../../types'
import { getShownInformation, getShownInformationFromApplication } from './residenceInfoStepUtils'
import { ResidenceDataTypes, ResidenceInfoFormData as FormData } from './types'

const TWO_YEARS_MILLISECONDS = 2 * 365 * 24 * 60 * 60 * 1000

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  applicationData: WizardData,
  applicationMetadata: SecLoansApplicationMetadata,
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changedFields) => {
    only(changedFields)

    const includedData = getShownInformationFromApplication(
      data,
      applicationData,
      applicationMetadata,
      getShownInformation,
    )

    omitWhen(toNumber(data.livingSquareArea) === undefined || toNumber(data.totalSquareArea) === undefined, () =>
      test(
        'livingSquareArea',
        formatMessage(
          { id: 'common-validate-value-integer' },
          { minValue: '1', maxValue: toNumber(data.totalSquareArea) },
        ),
        () => {
          enforce(toNumber(data.livingSquareArea)).lessThanOrEquals(toNumber(data.totalSquareArea))
        },
      ),
    )

    // ENUMS

    omitWhen(!includedData.includes(ResidenceDataTypes.LotType), () =>
      test('lotType', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.lotType).inside(Object.values(SecLoansLotOwnership))
      }),
    )

    omitWhen(!includedData.includes(ResidenceDataTypes.HeatingType), () =>
      test('heatingType', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.heatingType).inside(Object.values(SecLoansHeatingType))
      }),
    )

    omitWhen(!includedData.includes(ResidenceDataTypes.TransactionDateKnown), () => {
      test('transactionDateKnown', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.transactionDateKnown).inside(Object.values(SecLoansTransactionDateKnown))
      })

      omitWhen(
        data.transactionDateKnown !== SecLoansTransactionDateKnown.Yes &&
          data.transactionDateKnown !== SecLoansTransactionDateKnown.YesEstimated,
        () => {
          validateDate({
            fieldName: 'transactionDate',
            formatMessage,
            isRequired: true,
            valueToValidate: data.transactionDate,
            minMax: { minimumValue: new Date(), maximumValue: new Date(new Date().getTime() + TWO_YEARS_MILLISECONDS) },
          })
        },
      )
    })

    omitWhen(!includedData.includes(ResidenceDataTypes.SalesSite) || data.hasSalesSite !== YesNo.Yes, () => {
      test('salesSite', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.salesSite).inside(Object.values(SecLoansHousingSalesSite))
      })

      omitWhen(data.salesSite !== SecLoansHousingSalesSite.Other, () => {
        validateShortTextInput(
          'salesSiteOther',
          formatMessage,
          data.salesSiteOther,
          SecLoansValidationConstants.SALES_SITE_OTHER_MAX_LENGTH,
        )
      })

      omitWhen(data.salesSite === SecLoansHousingSalesSite.Other, () => {
        validateShortTextInput(
          'salesSiteTargetId',
          formatMessage,
          data.salesSiteTargetId,
          SecLoansValidationConstants.SALES_SITE_ID_MAX_LENGTH,
        )
      })
    })

    omitWhen(!includedData.includes(ResidenceDataTypes.ResidenceType), () =>
      test('residenceType', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.residenceType).inside(Object.values(SecLoansResidenceType))
      }),
    )

    omitWhen(!includedData.includes(ResidenceDataTypes.IsCollateralForSBankLoan), () =>
      test('isCollateralForSBankLoan', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.isCollateralForSBankLoan).inside(Object.values(YesNo))
      }),
    )
    // ENUMS END

    // INTEGERS FROM LIST
    omitWhen(!includedData.includes(ResidenceDataTypes.NumberOfFloors), () => {
      validateIntegerFromListOrOther(
        'numberOfFloors',
        formatMessage,
        data.numberOfFloors,
        data.numberOfFloorsOther,
        SecLoansValidationConstants.MIN_NUMBER_OF_FLOORS,
        SecLoansValidationConstants.MAX_NUMBER_OF_FLOORS,
      )
    })

    omitWhen(!includedData.includes(ResidenceDataTypes.NumberOfRooms), () => {
      validateIntegerFromListOrOther('numberOfRooms', formatMessage, data.numberOfRooms, data.numberOfRoomsOther)
    })
    // INTEGERS FROM LIST END

    // BOOLEANS
    omitWhen(!includedData.includes(ResidenceDataTypes.PipeRenovationDone), () =>
      test('isPipeRenovationDone', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.isPipeRenovationDone).inside(Object.values(YesNo))
      }),
    )
    // BOOLEANS END

    // DECIMALS
    omitWhen(!includedData.includes(ResidenceDataTypes.LivingArea), () => {
      validateDecimal(formatMessage, 'livingSquareArea', data.livingSquareArea, 1, 999.9, 1, true)
    })

    omitWhen(!includedData.includes(ResidenceDataTypes.TotalArea), () => {
      validateDecimal(formatMessage, 'totalSquareArea', data.totalSquareArea, 1, 999.9, 1, true)
    })

    omitWhen(!includedData.includes(ResidenceDataTypes.LotArea), () =>
      validateDecimal(
        formatMessage,
        'lotSquareArea',
        data.lotSquareArea,
        SecLoansValidationConstants.MIN_LOT_SQUARE_AREA,
        SecLoansValidationConstants.MAX_LOT_SQUARE_AREA,
        1,
        true,
      ),
    )
    // DECIMALS END

    // TEXT AREAS
    omitWhen(!includedData.includes(ResidenceDataTypes.RoomLayout), () =>
      validateTextArea(
        formatMessage,
        'roomLayoutDescription',
        data.roomLayoutDescription,
        SecLoansValidationConstants.ROOM_LAYOUT_MAX_LENGTH,
      ),
    )

    omitWhen(!includedData.includes(ResidenceDataTypes.RenovationHistory), () =>
      validateTextArea(
        formatMessage,
        'renovationHistory',
        data.renovationHistory,
        SecLoansValidationConstants.RENOVATIONS_HISTORY_MAX_LENGTH,
        false,
      ),
    )

    omitWhen(!includedData.includes(ResidenceDataTypes.PlannedRenovations), () =>
      validateTextArea(
        formatMessage,
        'plannedRenovations',
        data.plannedRenovations,
        SecLoansValidationConstants.PLANNED_RENOVATIONS_MAX_LENGTH,
      ),
    )

    omitWhen(!includedData.includes(ResidenceDataTypes.RenovationNeeds), () =>
      validateTextArea(
        formatMessage,
        'renovationNeeds',
        data.renovationNeeds,
        SecLoansValidationConstants.RENOVATION_NEEDS_MAX_LENGTH,
        false,
      ),
    )
    // TEXT AREAS END

    // INTEGERS
    omitWhen(!includedData.includes(ResidenceDataTypes.NumberOfApartments), () => {
      validateInteger(
        formatMessage,
        'numberOfApartments',
        data.numberOfApartments,
        SecLoansValidationConstants.MIN_NUMBER_OF_APARTMENTS,
        SecLoansValidationConstants.MAX_NUMBER_OF_APARTMENTS,
        true,
      )
    })
    // INTEGERS END

    // OPTIONAL

    omitWhen(!includedData.includes(ResidenceDataTypes.RealEstateId), () =>
      validateShortTextInput(
        'realEstateId',
        formatMessage,
        data.realEstateId,
        SecLoansValidationConstants.REAL_ESTATE_ID_MAX_LENGTH,
        false,
      ),
    )
    omitWhen(!includedData.includes(ResidenceDataTypes.RealEstateId), () => {
      test('realEstateId', formatMessage({ id: 'validate-real-estate-id' }), () => {
        if (data.realEstateId) {
          enforce(data.realEstateId).isValidRealEstateId()
        }
      })
    })
    // OPTIONAL END

    // OTHERS
    omitWhen(!includedData.includes(ResidenceDataTypes.BuildYear), () => {
      test('buildYear', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.buildYear).isNotEmpty()
      })

      test('buildYear', formatMessage({ id: 'validate-value-valid-year' }), () => {
        enforce(data.buildYear).isValidYear(
          SecLoansValidationConstants.MINIMUM_BUILD_YEAR,
          SecLoansValidationConstants.MAXIMUM_BUILD_YEAR,
        )
      })
    })
    // OTHERS END
  })
}

import { assertValue, ExpensesInfoData } from '@shared'
import { convertUndefinedToNull } from '@ui-common/utils/form'
import { getYesNoDefault, numberToString, toNumber } from '@ui-common/utils/helpers'
import { IntlShape } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'

import { getAndValidateApplicantData } from '../../../../utils/helpers'
import { ApplicantStepDataValidator, ExpensesInfoStepBase, ExpensesInfoStepData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { ChildAlimonyForm, ExpensesInfoFormData } from './ExpensesInfoStep'
import { createFormValidator } from './formValidator'

export const convertToFormValues = (expensesInfo: ExpensesInfoStepBase | undefined): ExpensesInfoFormData => {
  return convertUndefinedToNull({
    hasCarOwnership: getYesNoDefault(!!expensesInfo, !!expensesInfo?.carOwnershipType),
    hasAlimony: getYesNoDefault(!!expensesInfo, !!expensesInfo?.childAlimony?.length),
    carOwnershipType: expensesInfo?.carOwnershipType,
    childAlimony: (expensesInfo?.childAlimony?.map((c) => ({
      ...c,
      alimonyAmount: numberToString(c.alimonyAmount),
      id: uuidv4(),
    })) || []) as ChildAlimonyForm[],
  })
}

export const convertToExpensesInfoSubmitData = (formData: ExpensesInfoFormData): ExpensesInfoStepData => {
  const childAlimony = formData.childAlimony?.map((e) => ({
    ageGroup: assertValue(e.ageGroup, 'e.ageGroup'),
    alimonyAmount: assertValue(toNumber(e.alimonyAmount), 'e.alimonyAmount'),
  }))
  const expensesInfoData: ExpensesInfoStepBase = {
    carOwnershipType: formData.carOwnershipType,
    childAlimony,
  }
  return expensesInfoData
}

export const getAndValidateExpensesInfoTypeData: ApplicantStepDataValidator<ExpensesInfoData> = (validationData) => {
  const expensesInfoData = validationData.applicantInfo?.expensesInfoData
  const hasCoApplicant = !!validationData.applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant

  const validateApplicant = (isSecondApplicant: boolean) => {
    const applicantData = isSecondApplicant ? expensesInfoData?.secondApplicant : expensesInfoData
    return isFormDataValid(convertToFormValues(applicantData), (formatMessage: IntlShape['formatMessage']) =>
      createFormValidator(formatMessage),
    )
  }
  return getAndValidateApplicantData(validateApplicant, hasCoApplicant, expensesInfoData)
}

import { MAX_REASONABLE_NUMBER } from '@shared'
import { testIsValidMonetaryValue } from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, omitWhen, only, Suite } from 'vest'

import { WizardData } from '../../../types'
import { LivingExpensesInfoFormData as FormData } from './LivingExpensesInfoStep'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  applicationData: WizardData,
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changedFields) => {
    only(changedFields)

    testIsValidMonetaryValue('maintenanceFee', data.maintenanceFee, formatMessage, MAX_REASONABLE_NUMBER, true, 0)
    omitWhen(applicationData.targetInfo?.priceInfo?.isPayingCompanyLoanShare !== false, () => {
      testIsValidMonetaryValue(
        'housingCompanyLoanPayment',
        data.housingCompanyLoanPayment,
        formatMessage,
        MAX_REASONABLE_NUMBER,
        true,
        0,
      )
    })

    testIsValidMonetaryValue('otherExpenses', data.otherExpenses, formatMessage, MAX_REASONABLE_NUMBER, true, 0)
  })
}

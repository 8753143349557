import 'vest/enforce/date'

import {
  allowedCharsWithSpaceRule,
  assertValue,
  businessIdRule,
  companyRegisterNumberRule,
  DeepPartial,
  DomesticAddress,
  DropFirst,
  EMAIL_ADDRESS_MAX_LENGTH,
  EMAIL_ADDRESS_MIN_LENGTH,
  emailAddressRule,
  finnishMobilePhoneNumberRule,
  FinnishSSN,
  ForeignAddress,
  getNameValidationRuleFrontend,
  isUnderagedSsn,
  MAX_DOMESTIC_STREET_ADDRESS_TEXT_FIELD_LENGTH,
  MAX_FOREIGN_ADDRESS_LINE_1_TEXT_FIELD_LENGTH,
  MAX_FOREIGN_ADDRESS_LINE_2_TEXT_FIELD_LENGTH,
  MAX_FOREIGN_STREET_ADDRESS_TEXT_FIELD_LENGTH,
  MAX_REASONABLE_NUMBER,
  MIN_REASONABLE_NUMBER,
  monetaryValueRule,
  monthAndYearRule,
  OTHER_VALUE,
  percentageValueRule,
  PhoneNumber,
  phoneNumberRule,
  postalCodeRule,
  realEstateIdRule,
  ssnRule,
} from '@shared'
import { PHONE_COUNTRY_CODE } from '@ui-common-types'
import { fullDateStringToDate, getFirstOfCurrentMonth, textInputToDate } from '@utils/date'
import { IntlShape } from 'react-intl'
import { enforce, group, omitWhen, test } from 'vest'

import { trimPhoneNumber } from './form'
import { COUNTRY_CODE_FINLAND, getIllegalCharacters } from './helpers'
import { VestRuleReturn } from './types'

export const MINIMUM_REASONABLE_YEAR = 1950

// https://dvv.fi/en/reform-of-personal-identity-code

const integerPattern = /^-?([1-9]\d*|0)$/

const finnishMobilePhoneNumberRegExp = new RegExp(finnishMobilePhoneNumberRule)
const emailAddressRegExp = new RegExp(emailAddressRule)
const realEstateIdRegExp = new RegExp(realEstateIdRule)
const allowedCharsWithSpaceRegExp = new RegExp(allowedCharsWithSpaceRule)
const phoneNumberRegExp = new RegExp(phoneNumberRule)
const monthAndYearRegExp = new RegExp(monthAndYearRule)
const monetaryValueRegExp = new RegExp(monetaryValueRule)
const percentageValueRegExp = new RegExp(percentageValueRule)
const postalCodeRegExp = new RegExp(postalCodeRule)
const nameRegExp = new RegExp(getNameValidationRuleFrontend())
const yearPattern = /^[123]\d{3}$/
const yearRegExp = new RegExp(yearPattern)
const businessIdRegExp = new RegExp(businessIdRule)
const companyRegisterNumberRegExp = new RegExp(companyRegisterNumberRule)
const ssnRegExp = new RegExp(ssnRule)

const dynamicDecimalCountPattern = (maxDecimals: number): RegExp => {
  return new RegExp(`^[0-9]+(?:\\,[0-9]{0,${maxDecimals}})?$`)
}

export const isNotBlank = (str: string): VestRuleReturn => {
  return str.trim().length > 0
}

export const hasAllowedCharsWithSpaceOnly = (
  str: string,
  formatMessage: IntlShape['formatMessage'],
): VestRuleReturn => {
  return {
    pass: allowedCharsWithSpaceRegExp.test(str),
    message: () => {
      const illegalCharacters = getIllegalCharacters(str)

      return formatMessage(
        { id: 'common-validate-invalid-character-plural' },
        { length: illegalCharacters.length, chars: illegalCharacters },
      )
    },
  }
}

export const isValidFinnishMobilePhoneNumber = (str: string): VestRuleReturn => {
  return finnishMobilePhoneNumberRegExp.test(str)
}

export const isValidPhoneNumber = (str: string): VestRuleReturn => {
  if (!str) {
    return false
  }
  return phoneNumberRegExp.test(trimPhoneNumber(str))
}

export const isValidMonthAndYearFormat = (str: string): VestRuleReturn => {
  return monthAndYearRegExp.test(str)
}

export const isInteger = (value: string | number): VestRuleReturn => {
  return integerPattern.test(`${value}`)
}

export const isNotInPast = (monthAndYear: string): VestRuleReturn => {
  const date = textInputToDate(monthAndYear)
  const compareDate = getFirstOfCurrentMonth()
  return date >= compareDate
}

export const isNotInFuture = (monthAndYear: string): VestRuleReturn => {
  const date = textInputToDate(monthAndYear)
  const compareDate = getFirstOfCurrentMonth()
  return date <= compareDate
}

export const isNotBefore = (monthAndYear: string, monthAndYear2: string): VestRuleReturn => {
  const date1 = textInputToDate(monthAndYear)
  const date2 = textInputToDate(monthAndYear2)
  return date1 >= date2
}

export const isMonthYearAfter = (monthAndYear: string, monthAndYear2: string): VestRuleReturn => {
  const date1 = textInputToDate(monthAndYear)
  const date2 = textInputToDate(monthAndYear2)
  return date2 <= date1
}

export const isValidEmail = (candidate: string, formatMessage: IntlShape['formatMessage']): VestRuleReturn => {
  const length = candidate.trim().length
  return {
    pass:
      emailAddressRegExp.test(candidate) && length >= EMAIL_ADDRESS_MIN_LENGTH && length <= EMAIL_ADDRESS_MAX_LENGTH,
    message: formatMessage({ id: 'validate-email-format-error' }),
  }
}

export const isValidMonetaryValue = (
  inputValue: string | number,
  maxValue: number,
  minValue: number,
): VestRuleReturn => {
  const inputString = String(inputValue)
  const isCorrectlyFormatted = monetaryValueRegExp.test(inputString)
  if (!isCorrectlyFormatted) {
    return false
  }
  const number = Number(inputString.replace(/,/g, '.'))
  return number >= minValue && number <= maxValue
}

export const isValidPercentageValue = (inputValue: string | number): VestRuleReturn => {
  const inputString = String(inputValue)

  return percentageValueRegExp.test(inputString)
}

export const isValidYear = (inputValue: string | number, minValue = 1850, maxValue = 3000): VestRuleReturn => {
  const inputString = String(inputValue)
  if (yearRegExp.test(inputString)) {
    const number = Number(inputString)
    return number <= maxValue && number >= minValue
  }
  return false
}

export const isValidRealEstateId = (candidate: string): VestRuleReturn => {
  return realEstateIdRegExp.test(String(candidate).trim())
}

export const isValidSsn = (candidate: string): VestRuleReturn => {
  return FinnishSSN.isValidSsn(candidate) && ssnRegExp.test(candidate)
}

export const hasPostalCodeOnly = (str: string): VestRuleReturn => postalCodeRegExp.test(str)

export const isValidName = (str: string | undefined | null): VestRuleReturn => {
  if (!str) {
    return false
  }

  if (!str.trim()) {
    return false
  }
  return nameRegExp.test(str)
}

export const hasMaxDecimals = (str: string, maxDecimals: number): VestRuleReturn => {
  return dynamicDecimalCountPattern(maxDecimals).test(str)
}

export const isValidBusinessId = (str: string): VestRuleReturn => {
  return businessIdRegExp.test(str)
}

export const isValidCompanyRegisterNumber = (str: string): VestRuleReturn => {
  return companyRegisterNumberRegExp.test(str)
}

// consider getting rid of most of these since
// in most cases more validations than one is needed and
// the validation bundles below should be used instead
const matchers = {
  isNotBlank,
  hasAllowedCharsWithSpaceOnly,
  isValidFinnishMobilePhoneNumber,
  isValidMonthAndYearFormat,
  isInteger,
  isNotInFuture,
  isNotInPast,
  isNotBefore,
  isValidEmail,
  isValidPhoneNumber,
  isValidMonetaryValue,
  isValidPercentageValue,
  isValidSsn,
  hasPostalCodeOnly,
  isValidYear,
  hasMaxDecimals,
  isMonthYearAfter,
  isUnderagedSsn,
  isValidRealEstateId,
  isValidBusinessId,
  isValidCompanyRegisterNumber,
}

enforce.extend(matchers)

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace n4s {
    type CommonMatcher = typeof matchers
    type CommonCustomMatchers = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [K in keyof CommonMatcher]: (...args: DropFirst<Parameters<CommonMatcher[K]> | any[]>) => IRules<unknown>
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-object-type
    interface EnforceCustomMatchers<R> extends CommonCustomMatchers {}
  }
}

export const testIsValidMonetaryValue = (
  fieldName: string,
  fieldValue: string | number | undefined | null,
  formatMessage: IntlShape['formatMessage'],
  maxValue: number = MAX_REASONABLE_NUMBER,
  isRequired = true,
  minValue: number = MIN_REASONABLE_NUMBER,
): void => {
  omitWhen(!isRequired, () => {
    test(fieldName, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(fieldValue).isNotEmpty()
    })
  })
  omitWhen(!fieldValue, () => {
    test(fieldName, formatMessage({ id: 'common-validate-monetary-value' }, { maxValue, minValue }), () => {
      enforce(fieldValue).isValidMonetaryValue(maxValue, minValue)
    })
  })
}

export const testIsValidPercentageValue = (
  fieldName: string,
  fieldValue: string | number | undefined | null,
  formatMessage: IntlShape['formatMessage'],
  isRequired = true,
): void => {
  omitWhen(!isRequired, () => {
    test(fieldName, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(fieldValue).isNotEmpty()
    })
  })

  omitWhen(!fieldValue, () => {
    test(fieldName, () => {
      enforce(fieldValue).hasAllowedCharsWithSpaceOnly(formatMessage)
    })

    test(fieldName, formatMessage({ id: 'common-validate-percentage-value' }), () => {
      enforce(fieldValue).isValidPercentageValue()
    })
  })
}

export const validateTextArea = (
  formatMessage: IntlShape['formatMessage'],
  fieldName: string,
  textAreaData: string | undefined | null,
  maxLength: number,
  isRequired = true,
  minLength?: number,
): void => {
  omitWhen(!isRequired, () =>
    test(fieldName, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(textAreaData).isNotEmpty()
    }),
  )

  omitWhen(!minLength, () =>
    test(fieldName, formatMessage({ id: 'common-validate-too-few-characters' }, { chars: minLength }), () => {
      enforce(textAreaData).longerThanOrEquals(minLength)
    }),
  )

  omitWhen(!textAreaData, () => {
    test(fieldName, () => {
      enforce(textAreaData).hasAllowedCharsWithSpaceOnly(formatMessage)
    })

    test(
      fieldName,
      formatMessage(
        { id: 'common-validate-too-many-characters' },
        { chars: !textAreaData ? 0 : textAreaData.length - maxLength }, //This gets run even with undefined textAreaData
      ),
      () => {
        enforce(textAreaData).shorterThanOrEquals(maxLength)
      },
    )
  })
}

export const validateDecimal = (
  formatMessage: IntlShape['formatMessage'],
  fieldName: string,
  data: string | undefined | null,
  minValue: number,
  maxValue: number,
  maxDecimals: number,
  isRequired: boolean,
): void => {
  omitWhen(!isRequired, () =>
    test(fieldName, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data).isNotEmpty()
    }),
  )
  omitWhen(!data, () => {
    test(fieldName, () => {
      enforce(data).hasAllowedCharsWithSpaceOnly(formatMessage)
    })
    test(
      fieldName,
      formatMessage({ id: 'common-validate-value-decimal' }, { min: minValue, max: maxValue, decimals: maxDecimals }),
      () => {
        enforce(data).hasMaxDecimals(maxDecimals)
        const numberData = data?.replace(/,/g, '.')
        enforce(numberData).isBetween(minValue, maxValue)
      },
    )
  })
}

export const validateInteger = (
  formatMessage: IntlShape['formatMessage'],
  fieldName: string,
  data: string | undefined | null,
  minValue: number,
  maxValue: number,
  isRequired: boolean,
): void => {
  omitWhen(!isRequired, () =>
    test(fieldName, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data).isNotEmpty()
    }),
  )
  omitWhen(!data, () => {
    test(fieldName, formatMessage({ id: 'validate-common-integer' }), () => {
      enforce(data).isInteger()
    })
    test(fieldName, formatMessage({ id: 'common-validate-value-integer' }, { minValue, maxValue }), () => {
      enforce(data).isBetween(minValue, maxValue)
    })
  })
}

export const validateIntegerFromListOrOther = (
  fieldName: string,
  formatMessage: IntlShape['formatMessage'],
  selectedOption: string | undefined | null,
  otherValue: string | undefined | null,
  minValue = MIN_REASONABLE_NUMBER,
  maxValue = MAX_REASONABLE_NUMBER,
): void => {
  test(fieldName, formatMessage({ id: 'validate-required-field-missing' }), () => {
    enforce(selectedOption).isNotEmpty()
  })
  omitWhen(!!selectedOption && selectedOption !== OTHER_VALUE, () => {
    test(`${fieldName}Other`, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(otherValue).isNotEmpty()
    })
    omitWhen(otherValue === undefined, () => {
      test(`${fieldName}Other`, formatMessage({ id: 'validate-common-integer' }), () => {
        enforce(otherValue).isInteger()
      })
      test(`${fieldName}Other`, formatMessage({ id: 'common-validate-value-integer' }, { minValue, maxValue }), () => {
        enforce(otherValue).isBetween(minValue, maxValue)
      })
    })
  })
}

export const validateShortTextInput = (
  fieldName: string,
  formatMessage: IntlShape['formatMessage'],
  dataToValidate: string | undefined | null,
  maxLength: number,
  isRequired = true,
): void => {
  omitWhen(!isRequired, () => {
    test(fieldName, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(dataToValidate).isNotEmpty()
      enforce(dataToValidate).isNotBlank()
    })
  })
  omitWhen(!dataToValidate, () => {
    test(fieldName, () => {
      enforce(dataToValidate).hasAllowedCharsWithSpaceOnly(formatMessage)
    })
    test(fieldName, formatMessage({ id: 'common-validate-text-field-max-length' }), () => {
      enforce(dataToValidate).shorterThanOrEquals(maxLength)
    })
  })
}

export const validatePersonName = (
  fieldName: string,
  formatMessage: IntlShape['formatMessage'],
  dataToValidate: string | undefined | null,
  maxLength: number,
  isRequired = true,
): void => {
  validateShortTextInput(fieldName, formatMessage, dataToValidate, maxLength, isRequired)

  omitWhen(!dataToValidate, () => {
    test(fieldName, formatMessage({ id: 'common-validate-name' }), () => {
      enforce(isValidName(dataToValidate)).isTruthy()
    })
  })
}

/**
 * validations includes:
 *
 * date {@link  isNotEmpty}
 *
 * date {@link  isValidMonthAndYearFormat}
 *
 * date {@link  isNotInPast}
 * */
export const validateFutureMonthAndYear = ({
  fieldName,
  formatMessage,
  dateToValidate,
  isRequired = true,
}: {
  fieldName: string
  formatMessage: IntlShape['formatMessage']
  dateToValidate?: string | null
  minimumYear?: number
  isRequired: boolean
}): void => {
  validateMonthAndYear({ fieldName, formatMessage, dateToValidate, isRequired })

  omitWhen(!dateToValidate, () => {
    test(fieldName, formatMessage({ id: 'validate-date-must-be-in-future' }), () => {
      enforce(dateToValidate).isNotInPast()
    })
  })
}

/**
 * validations includes:
 *
 * date {@link  isNotEmpty}
 *
 * date {@link  isValidMonthAndYearFormat}
 * */
const validateMonthAndYear = ({
  fieldName,
  formatMessage,
  dateToValidate,
  isRequired = true,
}: {
  fieldName: string
  formatMessage: IntlShape['formatMessage']
  dateToValidate?: string | null
  isRequired: boolean
}): void => {
  omitWhen(!isRequired, () => {
    test(fieldName, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(dateToValidate).isNotEmpty()
    })
  })
  omitWhen(!dateToValidate, () => {
    test(fieldName, formatMessage({ id: 'validate-mm-yyyy-format' }), () => {
      enforce(dateToValidate).isValidMonthAndYearFormat()
    })
  })
}

/**
 * validations includes:
 *
 * date {@link  isNotEmpty}
 *
 * date {@link  isValidMonthAndYearFormat}
 *
 * date {@link  isNotInFuture}
 *
 * date {@link  isNotBefore} 01/1950
 * */
export const validatePastMonthAndYear = ({
  fieldName,
  formatMessage,
  dateToValidate,
  minimumYear = MINIMUM_REASONABLE_YEAR,
  isRequired = true,
}: {
  fieldName: string
  formatMessage: IntlShape['formatMessage']
  dateToValidate?: string | null
  minimumYear: number
  isRequired: boolean
}): void => {
  validateMonthAndYear({ fieldName, formatMessage, dateToValidate, isRequired })

  omitWhen(!dateToValidate, () => {
    test(fieldName, formatMessage({ id: 'validate-date-must-be-in-past' }), () => {
      enforce(dateToValidate).isNotInFuture()
    })

    test(fieldName, formatMessage({ id: 'validate-date-not-before-x' }, { year: minimumYear.toString() }), () => {
      enforce(dateToValidate).isNotBefore(`01 / ${minimumYear}`)
    })
  })
}

/**
 * validations includes:
 *
 * both dates {@link  isNotEmpty}
 *
 * both dates {@link  isValidMonthAndYearFormat}
 *
 * both dates {@link  isNotInFuture}
 *
 * both dates {@link  isNotBefore} 01/1950
 *
 * endDateToValidate {@link  isNotBefore} startDateToValidate
 *
 * endDateToValidate {@link  isMonthYearAfter} startDateToValidate
 * */
export const validatePastStartAndEndDate = ({
  startDateFieldName,
  endDateFieldName,
  formatMessage,
  startDateToValidate,
  endDateToValidate,
  isRequired = true,
  minimumYear = MINIMUM_REASONABLE_YEAR,
}: {
  startDateFieldName: string
  endDateFieldName: string
  formatMessage: IntlShape['formatMessage']
  startDateToValidate: string | undefined | null
  endDateToValidate: string | undefined | null
  isRequired: boolean
  minimumYear?: number
}): void => {
  validatePastMonthAndYear({
    fieldName: startDateFieldName,
    dateToValidate: startDateToValidate,
    formatMessage,
    minimumYear,
    isRequired,
  })

  validatePastMonthAndYear({
    fieldName: endDateFieldName,
    dateToValidate: endDateToValidate,
    formatMessage,
    minimumYear,
    isRequired,
  })

  omitWhen(!endDateToValidate || !startDateToValidate, () => {
    test(startDateFieldName, formatMessage({ id: 'common-validate-date-must-be-before-end-date' }), () => {
      enforce(endDateToValidate).isNotBefore(assertValue(startDateToValidate, 'startDateToValidate'))
    })
    test(endDateFieldName, formatMessage({ id: 'common-validate-date-must-be-after-start-date' }), () => {
      enforce(endDateToValidate).isMonthYearAfter(assertValue(startDateToValidate, 'startDateToValidate'))
    })
  })
}

export const validateBusinessId = ({
  fieldName,
  formatMessage,
  idToValidate,
  isRequired = true,
}: {
  fieldName: string
  formatMessage: IntlShape['formatMessage']
  idToValidate: string | undefined | null
  isRequired: boolean
}): void => {
  omitWhen(!isRequired, () => {
    test(fieldName, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(idToValidate).isNotEmpty()
    })
  })
  omitWhen(!idToValidate, () => {
    test(fieldName, formatMessage({ id: 'validate-business-id' }), () => {
      enforce(idToValidate).isValidBusinessId()
    })
  })
}

export const validateSsn = ({
  fieldName,
  formatMessage,
  valueToValidate,
  isRequired = true,
}: {
  fieldName: string
  formatMessage: IntlShape['formatMessage']
  valueToValidate: string | undefined | null
  isRequired: boolean
}): void => {
  omitWhen(!isRequired, () => {
    test(fieldName, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(valueToValidate).isNotEmpty()
    })
  })
  omitWhen(!valueToValidate, () => {
    test(fieldName, formatMessage({ id: 'validate-common-finnish-ssn-format' }), () => {
      enforce(valueToValidate).isValidSsn()
    })
  })
}

export const validateDate = ({
  fieldName,
  formatMessage,
  valueToValidate,
  minMax = { minimumValue: new Date(1900, 0, 1), maximumValue: new Date() },
  isRequired = true,
}: {
  fieldName: string
  formatMessage: IntlShape['formatMessage']
  valueToValidate: string | null | undefined
  minMax?: {
    minimumValue: Date
    maximumValue: Date
  }
  isRequired: boolean
}): void => {
  omitWhen(!isRequired, () => {
    test(fieldName, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(valueToValidate).isNotEmpty()
    })
  })
  omitWhen(!valueToValidate, () => {
    test(fieldName, formatMessage({ id: 'common-date-input-description' }), () => {
      enforce(valueToValidate).isDate({
        format: 'DD.MM.YYYY',
        strictMode: true,
        delimiters: ['.'],
      })
    })

    omitWhen(!minMax, () => {
      test(
        fieldName,
        formatMessage(
          { id: 'common-validate-date-between' },
          {
            minValue: minMax.minimumValue.toLocaleDateString('fi-FI'),
            maxValue: minMax.maximumValue.toLocaleDateString('fi-FI'),
          },
        ),
        () => {
          enforce(fullDateStringToDate(valueToValidate)?.toISOString()).isAfter(minMax.minimumValue.toISOString())
        },
      )
      test(
        fieldName,
        formatMessage(
          { id: 'common-validate-date-between' },
          {
            minValue: minMax.minimumValue.toLocaleDateString('fi-FI'),
            maxValue: minMax.maximumValue.toLocaleDateString('fi-FI'),
          },
        ),
        () => {
          enforce(fullDateStringToDate(valueToValidate)?.toISOString()).isBefore(minMax.maximumValue.toISOString())
        },
      )
    })
  })
}

export const validateDomesticAddress = ({
  fieldName,
  formatMessage,
  valueToValidate,
  isRequired = true,
}: {
  fieldName: string
  formatMessage: IntlShape['formatMessage']
  valueToValidate: DomesticAddress | null | undefined
  isRequired: boolean
}): void => {
  validateShortTextInput(
    `${fieldName}.street`,
    formatMessage,
    valueToValidate?.street,
    MAX_DOMESTIC_STREET_ADDRESS_TEXT_FIELD_LENGTH,
    isRequired,
  )

  omitWhen(!isRequired, () => {
    test(`${fieldName}.postalCode`, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(valueToValidate?.postalCode).isNotEmpty()
    })
    test(`${fieldName}.postOffice`, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(valueToValidate?.postOffice).isNotEmpty()
    })
  })

  test(`${fieldName}.postalCode`, formatMessage({ id: 'validate-common-finnish-postcode-length' }), () => {
    enforce(valueToValidate?.postalCode).hasPostalCodeOnly()
  })
}

export const validateForeignAddress = ({
  fieldName,
  formatMessage,
  valueToValidate,
  isRequired = true,
}: {
  fieldName: string
  formatMessage: IntlShape['formatMessage']
  valueToValidate: ForeignAddress | null | undefined
  isRequired: boolean
}): void => {
  validateShortTextInput(
    `${fieldName}.street`,
    formatMessage,
    valueToValidate?.street,
    MAX_FOREIGN_STREET_ADDRESS_TEXT_FIELD_LENGTH,
    isRequired,
  )

  validateShortTextInput(
    `${fieldName}.foreignAddressLine1`,
    formatMessage,
    valueToValidate?.foreignAddressLine1,
    MAX_FOREIGN_ADDRESS_LINE_1_TEXT_FIELD_LENGTH,
    false,
  )

  validateShortTextInput(
    `${fieldName}.foreignAddressLine2`,
    formatMessage,
    valueToValidate?.foreignAddressLine2,
    MAX_FOREIGN_ADDRESS_LINE_2_TEXT_FIELD_LENGTH,
    isRequired,
  )

  validateSelect({
    fieldName: `${fieldName}.countryCode`,
    formatMessage,
    valueToValidate: valueToValidate?.countryCode,
    isRequired,
  })
}

export const validateSelect = ({
  fieldName,
  formatMessage,
  valueToValidate,
  isRequired = true,
}: {
  fieldName: string
  formatMessage: IntlShape['formatMessage']
  valueToValidate: string | null | undefined
  isRequired: boolean
}): void => {
  omitWhen(!isRequired, () => {
    test(fieldName, formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(valueToValidate).isNotEmpty()
      enforce(valueToValidate).notEquals('unset')
    })
  })
}

export const validatePhoneNumberInput = ({
  rootFieldName,
  dataToValidate,
  isRequired,
  formatMessage,
  validatePhoneCountryCode,
  validateAsFinnishMobileNumber,
}: {
  rootFieldName: string
  dataToValidate: DeepPartial<PhoneNumber> | null | undefined
  isRequired: boolean
  formatMessage: IntlShape['formatMessage']
  validatePhoneCountryCode?: boolean
  validateAsFinnishMobileNumber?: boolean
}): void => {
  group(rootFieldName, () => {
    omitWhen(!isRequired, () => {
      omitWhen(!validatePhoneCountryCode, () => {
        // targeting phoneCountry because phoneCountryCode is a hidden input and is set via phoneCountry
        test(`${rootFieldName}.phoneCountry`, formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(dataToValidate?.phoneCountryCode).isNotEmpty()
        })
      })
      validateSelect({
        fieldName: `${rootFieldName}.phoneCountry`,
        valueToValidate: dataToValidate?.phoneCountry,
        formatMessage,
        isRequired,
      })

      test(`${rootFieldName}.phoneNumber`, formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(dataToValidate?.phoneNumber).isNotEmpty()
      })
    })

    omitWhen(isRequired, () => {
      const bothFieldsEmpty = !dataToValidate?.phoneNumber && !dataToValidate?.phoneCountry
      // phoneCountry must be given if phoneNumber is set even if not required
      omitWhen(bothFieldsEmpty, () => {
        validateSelect({
          fieldName: `${rootFieldName}.phoneCountry`,
          valueToValidate: dataToValidate?.phoneCountry,
          formatMessage,
          isRequired: true,
        })
        // phoneNumber must be given if phoneCountry is set
        test(`${rootFieldName}.phoneNumber`, formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(dataToValidate?.phoneNumber).isNotEmpty()
        })
      })
    })

    // value validity checks
    omitWhen(!dataToValidate?.phoneNumber, () => {
      test(`${rootFieldName}.phoneNumber`, formatMessage({ id: 'common-validate-phone-number-format-error' }), () => {
        enforce(dataToValidate?.phoneNumber).isValidPhoneNumber()
      })

      omitWhen(!(validateAsFinnishMobileNumber && dataToValidate?.phoneCountry === COUNTRY_CODE_FINLAND), () => {
        test(
          `${rootFieldName}.phoneNumber`,
          formatMessage({ id: 'validate-finnish-mobile-phone-format-error' }),
          () => {
            enforce(PHONE_COUNTRY_CODE + dataToValidate?.phoneNumber).isValidFinnishMobilePhoneNumber()
          },
        )
      })
    })
  })
}

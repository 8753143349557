import { TransitionStep } from '@components/common/TransitionStep/TransitionStep'
import { useSelector } from '@xstate/react'
import { ActorRefFrom } from 'xstate'

import {
  ENSURE_BFF_AVAILABILITY,
  ERROR,
  GET_AND_STORE_INPUT_PARAMS,
  GET_CONFIG,
  initializeStepMachine,
} from './initializeStepMachine'

export type InitializeStepService = ActorRefFrom<typeof initializeStepMachine>
export interface InitializeStepProps {
  service: InitializeStepService
}

export const InitializeStep = ({ service }: InitializeStepProps): JSX.Element => {
  const state = useSelector(service, (s) => s)

  const isLoadingState =
    state.matches(GET_AND_STORE_INPUT_PARAMS) || state.matches(ENSURE_BFF_AVAILABILITY) || state.matches(GET_CONFIG)
  const isErrorState = state.matches(ERROR)

  return (
    <TransitionStep
      isLoading={isLoadingState}
      isErrored={isErrorState}
      onAbort={() => service.send({ type: 'EXIT' })}
      onRetry={() => service.send({ type: 'RETRY' })}
      error={undefined}
    />
  )
}

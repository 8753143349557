import { IconSize } from '@ui-components/assets/Icons'
import { Divider } from '@ui-components/decorative/Divider'
import { Icon, IconTypeEnum } from '@ui-components/decorative/IconComponent'
import { AdditionalText } from '@ui-components/typography/Typography'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

const IconInfoWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > *:first-child {
    margin-right: 15px;
  }
`

interface InfoFooterProps {
  text: ReactNode
}

export const InfoFooter = ({ text }: InfoFooterProps): JSX.Element => {
  return (
    <section>
      <Divider />
      <IconInfoWrapper>
        <div>
          <Icon iconType={IconTypeEnum.Info} size={IconSize.MEDIUM} />
        </div>
        <div>
          <AdditionalText tagName="div">{text}</AdditionalText>
        </div>
      </IconInfoWrapper>
    </section>
  )
}

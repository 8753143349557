import { debugLog } from '@utils/helpers'
import { removeSessionStorageItem, setSessionStorageItem } from '@utils/storage'
import { useActorRef } from '@xstate/react'
import * as R from 'remeda'
import { Actor, AnyMachineSnapshot, AnyStateMachine, InputFrom, StateFrom, StateValue } from 'xstate'

import { getInitialState } from '../utils/xstateUtils'

export const usePersistingActor = <TMachine extends AnyStateMachine>(
  machine: TMachine,
  shouldHydrateState: (state: StateFrom<TMachine>) => boolean = () => false,
  onActorSnapshot: (state: StateValue) => unknown = R.doNothing(),
  initialContext: InputFrom<TMachine> | null = null,
): Actor<TMachine> => {
  const sessionStorageKey = `${machine.id}-state`
  const initialState = getInitialState(sessionStorageKey, shouldHydrateState)

  const actor = useActorRef(
    machine,
    {
      ...(initialState ? { snapshot: initialState } : undefined),
      ...(initialContext ? { input: initialContext } : undefined),
    },
    {
      next: (snapshot: AnyMachineSnapshot) => {
        debugLog(`State (${machine.id}): ${JSON.stringify(snapshot)}`)
        window.history.pushState({ page: snapshot.value }, 'unused', window.location.href)
        const persistedState = actor.getPersistedSnapshot()
        setSessionStorageItem(sessionStorageKey, JSON.stringify(persistedState))
        onActorSnapshot(snapshot.value)
      },
      error: (error: unknown) => {
        console.error(error)
        throw error
      },
      complete: () => {
        removeSessionStorageItem(sessionStorageKey)
      },
    },
  )

  return actor
}

import { ModalVisualMode } from '@ui-components/containers/ModalTypes'
import { BodyText } from '@ui-components/typography/Typography'
import { IntlShape } from 'react-intl'

import { OpenModalEvent } from './modalMachine'

const Content = (props: { text: string }): JSX.Element => {
  return <BodyText tagName="p">{props.text}</BodyText>
}

export const getCancelWithoutSavingModalEvent = ({
  onConfirm,
  onCancel,
  intl,
  header,
  text,
  primaryActionText,
}: {
  onConfirm: () => void
  onCancel?: () => void
  intl: IntlShape
  header?: string
  text: string
  primaryActionText?: string
}): OpenModalEvent => {
  const modalHeader = header ?? intl.formatMessage({ id: 'common-re-edit-prompt-header' })
  return {
    type: 'OPEN',
    data: {
      header: modalHeader,
      children: <Content text={text} />,
      primaryButtonOptions: {
        label: intl.formatMessage({ id: 'common-cancel' }),
        onClick: onCancel,
      },
      secondaryButtonOptions: {
        label: primaryActionText ?? intl.formatMessage({ id: 'common-continue-anyway' }),
        onClick: onConfirm,
      },
      mode: ModalVisualMode.Warn,
    },
  }
}

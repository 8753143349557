import { assertValue, SecLoanOtherIncomeType, SecLoansValidationConstants } from '@shared'
import { YesNo } from '@ui-common/types/types'
import { toNumber } from '@ui-common/utils/helpers'
import { testIsValidMonetaryValue, validateTextArea } from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { IncomeInfoFormData as FormData } from './IncomeInfoStep'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  shouldAskSalaryBaseOnBackgroundInfo: boolean,
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> =>
  create((data, changedFields) => {
    only(changedFields)

    omitWhen(!shouldAskSalaryBaseOnBackgroundInfo, () => {
      testIsValidMonetaryValue('grossSalary', data.grossSalary, formatMessage)

      testIsValidMonetaryValue('netSalary', data.netSalary, formatMessage)

      omitWhen(!data.grossSalary || !data.netSalary, () => {
        test(
          'netSalary',
          formatMessage(
            { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
            {
              firstValue: formatMessage({ id: 'sec-loans-income-info-net-salary-form-label' }),
              secondValue: formatMessage({ id: 'sec-loans-income-info-gross-salary-form-label' }),
            },
          ),
          () => {
            const netSalary = assertValue(toNumber(data.netSalary), 'data.netSalary')
            const grossSalary = assertValue(toNumber(data.grossSalary), 'data.grossSalary')
            enforce(grossSalary >= netSalary).isTruthy()
          },
        )
      })
    })

    test('hasOtherIncome', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasOtherIncome).inside(Object.values(YesNo))
    })

    omitWhen(data.hasOtherIncome !== YesNo.Yes, () => {
      data.otherIncome?.forEach((income, index) => {
        test(`otherIncome.${index}.otherIncomeType`, formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(income?.otherIncomeType).inside(Object.values(SecLoanOtherIncomeType))
        })

        const { otherIncomeType } = income ?? {}

        const omitAmountPerMonthValidationCondition =
          otherIncomeType !== SecLoanOtherIncomeType.ChildAllowance &&
          otherIncomeType !== SecLoanOtherIncomeType.RentalIncome &&
          otherIncomeType !== SecLoanOtherIncomeType.RegularExpenseReimbursements &&
          otherIncomeType !== SecLoanOtherIncomeType.SideJob &&
          otherIncomeType !== SecLoanOtherIncomeType.HousingAllowance &&
          otherIncomeType !== SecLoanOtherIncomeType.Grant &&
          otherIncomeType !== SecLoanOtherIncomeType.ChildSupport &&
          otherIncomeType !== SecLoanOtherIncomeType.Pension &&
          otherIncomeType !== SecLoanOtherIncomeType.Other

        const omitAmountPerYearValidationCondition = otherIncomeType !== SecLoanOtherIncomeType.DividendIncome

        const omitDescriptionValidationCondition =
          otherIncomeType !== SecLoanOtherIncomeType.RentalIncome &&
          otherIncomeType !== SecLoanOtherIncomeType.RegularExpenseReimbursements &&
          otherIncomeType !== SecLoanOtherIncomeType.DividendIncome &&
          otherIncomeType !== SecLoanOtherIncomeType.SideJob &&
          otherIncomeType !== SecLoanOtherIncomeType.Grant &&
          otherIncomeType !== SecLoanOtherIncomeType.ChildSupport &&
          otherIncomeType !== SecLoanOtherIncomeType.Pension &&
          otherIncomeType !== SecLoanOtherIncomeType.Other

        omitWhen(omitAmountPerMonthValidationCondition, () => {
          testIsValidMonetaryValue(`otherIncome.${index}.amountPerMonth`, income?.amountPerMonth, formatMessage)
        })

        omitWhen(omitAmountPerYearValidationCondition, () => {
          testIsValidMonetaryValue(`otherIncome.${index}.amountPerYear`, income?.amountPerYear, formatMessage)
        })

        omitWhen(omitDescriptionValidationCondition, () => {
          validateTextArea(
            formatMessage,
            `otherIncome.${index}.description`,
            income?.description,
            SecLoansValidationConstants.OTHER_INCOME_DESCRIPTION_MAX_LENGTH,
          )
        })
      })
    })
  })

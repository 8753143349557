import { YesNo } from '@ui-common/types/types'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, only, Suite, test } from 'vest'

import { InsuranceInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changedFields) => {
    only(changedFields)

    test('isInterestedInInsurance', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.isInterestedInInsurance).inside(Object.values(YesNo))
    })
  })
}

import { roundNumberToFractionDigits } from '@shared/helpers'
import { getTotalAspSavings } from '@shared/sec-loans/calculation-helpers'
import { getSaleIncomeAndRemainingLoan } from '@shared/sec-loans/calculations'
import { OTHER_VALUE } from '@shared/sec-loans/constants'
import {
  ApplicantRole,
  BackgroundInfoData,
  HousingInfoData,
  LoanAmounts,
  OtherOccupation,
  SecLoanApplicationState,
  SecLoanHousingType,
  SecLoansApplicants,
  SecLoansFlow,
  SecLoansHousingLoanTargetType,
  SecLoansSource,
} from '@shared/sec-loans/types'
import { OptionalReturnType } from '@shared/typeHelpers'
import {
  AuthenticationMode,
  ENDPOINT_GET_CBS_TOKEN,
  ENDPOINT_GET_POST_OFFICE,
  ExitUrls,
  GetPostOfficeResponse,
  Language,
  Occupation,
} from '@shared/types'
import { MAX_REASONABLE_NUMBER, MIN_REASONABLE_NUMBER } from '@shared/validation'
import { CommonQueryParam } from '@ui-common/types/types'
import {
  debugLog,
  getFlow,
  getSourceChannel,
  numberToString,
  resolveAndStoreParam,
  toNumber,
} from '@ui-common/utils/helpers'
import { callEndpoint } from '@ui-common/utils/http/client'
import { hasPostalCodeOnly } from '@ui-common/utils/validation'
import { ColoredLabelProps } from '@ui-components/dataDisplay/ColoredLabel'
import { IntlShape } from 'react-intl'

import { FALLBACK_EXIT_URLS } from '../config'
import { SECOND_APPLICANT_PREFIX } from '../constants/constants'
import { ValidatedData, WizardData } from '../wizard/types'
import { getMapEnum } from './getMapEnum'
import { assertSecLoansFlow, assertSecLoansSourceChannel, isSecLoansFlow, isSecLoansSourceChannel } from './typeHelpers'

export const applicantNumber = (hasCoApplicant: boolean, isSecondApplicant: boolean): string => {
  if (hasCoApplicant) {
    return isSecondApplicant ? ' (2)' : ' (1)'
  }
  return ''
}

export const getApplicantColoredLabel = (
  hasCoApplicant: boolean,
  applicant: SecLoansApplicants,
  formatMessage: IntlShape['formatMessage'],
): ColoredLabelProps | undefined => {
  if (hasCoApplicant) {
    const { ApplicantStickerColors } = getMapEnum(formatMessage)
    return {
      color: ApplicantStickerColors[applicant],
      text: formatMessage({ id: `sec-loans-two-applicants-${applicant}-applicant-label` }),
    }
  }
  return undefined
}

export const getApplicant = (isSecondApplicant: boolean): SecLoansApplicants => {
  return isSecondApplicant ? SecLoansApplicants.SecondApplicant : SecLoansApplicants.FirstApplicant
}

export const getApplicantPrefix = (isSecondApplicant: boolean): '' | typeof SECOND_APPLICANT_PREFIX => {
  return isSecondApplicant ? SECOND_APPLICANT_PREFIX : ''
}

export const resolveAndStoreSourceChannel = (): SecLoansSource => {
  return resolveAndStoreParam(CommonQueryParam.SourceChannel, Object.values(SecLoansSource), SecLoansSource.Unknown)
}

export const resolveAndStoreFlow = (): SecLoansFlow => {
  return resolveAndStoreParam(CommonQueryParam.Flow, Object.values(SecLoansFlow), SecLoansFlow.Default)
}

export const isEmployee = (backgroundInfoData: BackgroundInfoData | undefined, isSecondApplicant: boolean): boolean => {
  const data = isSecondApplicant ? backgroundInfoData?.secondApplicant : backgroundInfoData
  if (!data) {
    return false
  }
  const { occupation, hasValidEmploymentRelationship } = data
  return (
    occupation === Occupation.Employed ||
    (occupation === OtherOccupation.OnFamilyLeave && !!hasValidEmploymentRelationship)
  )
}

export const isEntrepreneur = (
  backgroundInfoData: BackgroundInfoData | undefined,
  isSecondApplicant: boolean,
): boolean => {
  const data = isSecondApplicant ? backgroundInfoData?.secondApplicant : backgroundInfoData
  if (!data) {
    return false
  }
  return data.occupation === Occupation.Entrepreneur
}

export const isHomeOwner = (housingInfoData: HousingInfoData | undefined, isSecondApplicant: boolean): boolean => {
  const data = isSecondApplicant ? housingInfoData?.secondApplicant : housingInfoData
  if (!data) {
    return false
  }
  return data.housingType === SecLoanHousingType.HomeOwnership
}

export const getAndValidateApplicantData = <T extends { secondApplicant?: Y }, Y extends object>(
  validateApplicant: (isSecondApplicant: boolean) => boolean,
  hasCoApplicant: boolean | undefined,
  data: T | undefined,
): ValidatedData<T | undefined | { secondApplicant?: Y }> | undefined => {
  const isFirstApplicantValid = validateApplicant(false)
  const isSecondApplicantValid = hasCoApplicant ? validateApplicant(true) : false
  if (!isFirstApplicantValid && !isSecondApplicantValid) {
    return
  }
  const firstApplicantData = isFirstApplicantValid ? data : undefined

  if (!hasCoApplicant) {
    return getValidatedData(firstApplicantData, isFirstApplicantValid)
  }

  return {
    data: {
      ...firstApplicantData,
      secondApplicant: isSecondApplicantValid ? data?.secondApplicant : undefined,
    },
    isComplete: getCompletenessForTwoApplicants({ hasCoApplicant, isFirstApplicantValid, isSecondApplicantValid }),
  }
}

export const getCompletenessForTwoApplicants = (params: {
  hasCoApplicant: boolean
  isFirstApplicantValid: boolean
  isSecondApplicantValid: boolean
}): boolean => {
  const { hasCoApplicant, isFirstApplicantValid, isSecondApplicantValid } = params

  return !!(isFirstApplicantValid && (!hasCoApplicant || isSecondApplicantValid))
}

export const getValidatedData = <T>(
  data: T | undefined,
  isValid: boolean,
  keepInvalidData = false,
): ValidatedData<T | undefined> => {
  return {
    data: isValid || keepInvalidData ? data : undefined,
    isComplete: isValid,
  }
}

export const getOptionAndOtherFormValues = (
  value: number | undefined,
  minValue = MIN_REASONABLE_NUMBER,
  maxValue = MAX_REASONABLE_NUMBER,
): { option?: string; other?: string } => {
  const isOther = value !== undefined && (value < minValue || value > maxValue)
  const option = isOther ? OTHER_VALUE : numberToString(value)
  const other = isOther ? numberToString(value) : undefined
  return { option, other }
}

export const resolveValueFromFormFields = (option: string, other: string | undefined | null): number | undefined => {
  if (option === OTHER_VALUE) {
    return toNumber(other)
  }
  return toNumber(option)
}

export const getExitUrl = async (url: string, cbsTokenParameter?: string): Promise<string> => {
  const exitUrl = new URL(url)
  if (cbsTokenParameter) {
    try {
      const response = await callEndpoint(ENDPOINT_GET_CBS_TOKEN)
      exitUrl.searchParams.append(cbsTokenParameter, response.cbsToken)
    } catch (error) {
      debugLog(error)
    }
  }
  return exitUrl.href
}

export const fetchPostOffice = async (
  postcode: string | undefined,
  language: Language,
): Promise<string | null | undefined> => {
  if (postcode && hasPostalCodeOnly(postcode)) {
    return (await getPostOffice(postcode, language)).postOffice
  } else {
    return undefined
  }
}

const getPostOffice = async (postcode: string, language: Language): Promise<GetPostOfficeResponse> => {
  const response = await callEndpoint(ENDPOINT_GET_POST_OFFICE, {
    queryParams: {
      language,
      postcode,
    },
  })
  return response
}

export const getFallbackExitUrls = (): ExitUrls => {
  return {
    successUrl: FALLBACK_EXIT_URLS,
    failureUrl: FALLBACK_EXIT_URLS,
  }
}

export const isTargetTypeApartment = (targetType: SecLoansHousingLoanTargetType): boolean =>
  targetType === SecLoansHousingLoanTargetType.HousingStock
export const isTargetTypeRightOfResidence = (targetType: SecLoansHousingLoanTargetType): boolean =>
  targetType === SecLoansHousingLoanTargetType.RightOfResidence
export const isTargetTypePartOwnership = (targetType: SecLoansHousingLoanTargetType): boolean =>
  targetType === SecLoansHousingLoanTargetType.PartOwnership

export const getLoanAmounts = (
  wizardData: Pick<WizardData, 'applicantInfo' | 'targetInfo'>,
  totalCost: number = 0,
  ownMoney: number = 0,
): LoanAmounts => {
  const { targetInfo, applicantInfo } = wizardData ?? {}
  const { loanCostEstimateInfo } = targetInfo ?? {}
  const aspSavingsTotalAmount = getTotalAspSavings(applicantInfo?.backgroundInfoData)

  const currentResidenceInfoData = [
    applicantInfo?.currentResidenceInfoData,
    applicantInfo?.currentResidenceInfoData?.secondApplicant,
  ]

  const { saleIncomeBefore, saleIncomeAfter, remainingLoan } = getSaleIncomeAndRemainingLoan(currentResidenceInfoData)
  const ownFinancingShare = loanCostEstimateInfo?.ownFinancingShare ?? 0
  const ownWorkShare = loanCostEstimateInfo?.ownWorkShare ?? 0

  const loanAmount = roundNumberToFractionDigits(
    Math.max(0, totalCost - ownMoney - aspSavingsTotalAmount - saleIncomeBefore - ownFinancingShare - ownWorkShare),
  )
  const finalLoanAmount = saleIncomeAfter
    ? roundNumberToFractionDigits(Math.max(0, loanAmount - saleIncomeAfter))
    : undefined

  const remainingLoanAmount = remainingLoan ? roundNumberToFractionDigits(loanAmount + remainingLoan) : undefined
  const temporaryLoanAmount = finalLoanAmount === 0 ? loanAmount : remainingLoanAmount

  return {
    loanAmount,
    temporaryLoanAmount,
    finalLoanAmount,
  }
}

export const isPrimaryApplicantWaitingSecondaryApplicant = (
  applicantRole: ApplicantRole,
  applicationState: SecLoanApplicationState,
): boolean => {
  return (
    applicantRole === ApplicantRole.PrimaryApplicant &&
    applicationState === SecLoanApplicationState.WaitingForSecondaryApplicant
  )
}

export const getAuthenticationMode = (sourceChannel: SecLoansSource | undefined): AuthenticationMode => {
  return sourceChannel && [SecLoansSource.Netbank, SecLoansSource.SMobile].includes(sourceChannel)
    ? AuthenticationMode.Sso
    : AuthenticationMode.Interactive
}

export const getSecLoansSourceChannel = <TRequired extends boolean>(
  isRequired?: TRequired,
): OptionalReturnType<TRequired, SecLoansSource> => {
  const sourceChannel = getSourceChannel()

  if (isRequired) {
    return assertSecLoansSourceChannel(sourceChannel)
  }

  return isSecLoansSourceChannel(sourceChannel)
    ? sourceChannel
    : (undefined as OptionalReturnType<TRequired, SecLoansSource>)
}

export const getSecLoansFlow = <TRequired extends boolean>(
  isRequired?: TRequired,
): OptionalReturnType<TRequired, SecLoansFlow> => {
  const flow = getFlow()

  if (isRequired) {
    return assertSecLoansFlow(flow)
  }

  return isSecLoansFlow(flow) ? flow : (undefined as OptionalReturnType<TRequired, SecLoansFlow>)
}

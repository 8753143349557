import { ExitUrls } from '@shared-types'
import { usePersistingActor } from '@ui-common/hooks/usePersistingActor'
import { createContext, ReactNode, useMemo, useState } from 'react'
import { ActorRefFrom } from 'xstate'

import { createWizardMachine, shouldHydrateState } from '../wizard/wizardMachine'

interface ApplicationWizardContext {
  service: ActorRefFrom<ReturnType<typeof createWizardMachine>>
}

export const ApplicationWizardContext = createContext<ApplicationWizardContext>({} as ApplicationWizardContext)

export interface AuthenticatedState {
  authToken: string
  exitUrls: ExitUrls
}

interface Props {
  children: ReactNode
  authenticatedState?: AuthenticatedState
}

export const ApplicationWizardProvider = (props: Props): JSX.Element => {
  const [wizardMachine] = useState(() => createWizardMachine(props.authenticatedState))
  const service = usePersistingActor(wizardMachine, shouldHydrateState)
  const context = useMemo(() => ({ service }), [service])
  return <ApplicationWizardContext.Provider value={context}>{props.children}</ApplicationWizardContext.Provider>
}

import { assertValue } from '@shared/helpers'
import { SecLoansParam } from '@shared/sec-loans/types'
import { AppName, ConfigResponse } from '@shared/types'
import { debugLog, getConfig } from '@ui-common/utils/helpers'
import { resolveAndStoreLanguage } from '@ui-common/utils/language'
import { redirectToMaintenancePageIfNeeded } from '@ui-common/utils/statusCheck'
import { assign, fromPromise, sendParent, setup } from 'xstate'

import {
  getFallbackExitUrls,
  getSecLoansFlow,
  getSecLoansSourceChannel,
  resolveAndStoreFlow,
  resolveAndStoreSourceChannel,
} from '../../../utils/helpers'

export const GET_AND_STORE_INPUT_PARAMS = 'getAndStoreInputParams'
export const ENSURE_BFF_AVAILABILITY = 'ensureBffAvailability'
export const GET_CONFIG = 'getConfig'
export const ERROR = 'error'
export const ABORT_PROMPT = 'abortPrompt'

export type InitializeStepContext = ConfigResponse<SecLoansParam>

export type ExitEvent = { type: 'EXIT' }
export type RetryEvent = { type: 'RETRY' }

export type InitializeStepEvent = RetryEvent | ExitEvent

export const initializeStepMachine = setup({
  types: {
    context: {} as InitializeStepContext,
    events: {} as InitializeStepEvent,
  },
  actors: {
    getAndStoreInputParams: fromPromise(async () => {
      debugLog('Actor: getAndStoreInputParams')
      resolveAndStoreSourceChannel()
      resolveAndStoreFlow()
      resolveAndStoreLanguage()
    }),
    ensureBffAvailability: fromPromise(async () => {
      debugLog('Actor: ensureBffAvailability')
      await redirectToMaintenancePageIfNeeded(import.meta.env.VITE_ENV_ID, AppName.SecLoans)
    }),
    getConfig: fromPromise(() => {
      debugLog('Actor: getConfig')
      return getConfig(getSecLoansSourceChannel(true), getSecLoansFlow(true))
    }),
  },
  actions: {
    exit: sendParent(({ context }) => {
      debugLog('Action: Exit')
      return {
        type: 'SAVE_EXIT_URLS_AND_EXIT_WIZARD',
        data: { exitUrls: assertValue(context.exitUrls, 'context.exitUrls') },
      }
    }),
    storeConfig: assign((_, data: ConfigResponse<SecLoansParam>) => {
      debugLog('Action: Store config')
      return data
    }),
    proceedToNextStep: sendParent(({ context }) => {
      debugLog('Action: Proceed to next step')
      return {
        type: 'NEXT_STEP',
        data: context,
      }
    }),
    storeFallbackExitUrls: assign(() => {
      debugLog('Action: Store fallback exit URLs')
      return { exitUrls: getFallbackExitUrls() }
    }),
  },
}).createMachine({
  id: 'initializeStepMachine',
  initial: GET_AND_STORE_INPUT_PARAMS,
  states: {
    [GET_AND_STORE_INPUT_PARAMS]: {
      invoke: {
        src: 'getAndStoreInputParams',
        onDone: [
          {
            target: ENSURE_BFF_AVAILABILITY,
          },
        ],
      },
    },
    [ENSURE_BFF_AVAILABILITY]: {
      invoke: {
        src: 'ensureBffAvailability',
        onDone: [
          {
            target: GET_CONFIG,
          },
        ],
      },
    },
    [GET_CONFIG]: {
      invoke: {
        src: 'getConfig',
        onDone: {
          actions: [{ type: 'storeConfig', params: ({ event }) => event.output }, { type: 'proceedToNextStep' }],
        },
        onError: {
          actions: [{ type: 'storeFallbackExitUrls' }],
          target: ERROR,
        },
      },
    },
    [ERROR]: {
      on: {
        EXIT: { actions: { type: 'exit' } },
        RETRY: GET_CONFIG,
      },
    },
  },
})

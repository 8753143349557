import {
  assertValue,
  Bank,
  CurrentResidenceInfoBase,
  LoanTransferInfoData,
  PurchaseLoanInfoData,
  removeUndefinedValues,
  SecLoanApplicationType,
  SecLoanPurposeType,
  SecLoansApplicants,
  SecLoansCurrentResidenceSellingPlan,
  SecLoansLoanOrCredit,
  SecLoansLoanOrCreditType,
  SecLoansPurchaseLoanPurpose,
} from '@shared'
import { RenderFieldParams } from '@ui-common/hooks/useDynamicFormField'
import { YesNo } from '@ui-common/types/types'
import { convertUndefinedToNull } from '@ui-common/utils/form'
import { getYesNoDefault, numberToString, toNumber } from '@ui-common/utils/helpers'
import { MouseEvent } from 'react'
import { IntlShape } from 'react-intl'
import * as R from 'remeda'
import { v4 as uuidv4 } from 'uuid'

import {
  getCompletenessForTwoApplicants,
  getOptionAndOtherFormValues,
  getValidatedData,
  resolveValueFromFormFields,
} from '../../../../utils/helpers'
import {
  ApplicantInfoWizardData,
  ApplicantStepDataValidator,
  LoansAndCreditsInfoStepData,
  WizardData,
} from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { LoansAndCreditsInfoStepFormData, MAX_NUMBER_OF_QUICK_LOANS_OPTION, SecLoansLoanOrCreditForm } from './types'

type LoansAndCreditsFormData = {
  [P in keyof LoansAndCreditsInfoStepFormData as P extends `${string}LoansAndCredits`
    ? P
    : never]: LoansAndCreditsInfoStepFormData[P]
}

export const loansAndCreditsFirstApplicant = {
  hasProp: 'firstApplicantHasLoansAndCredits',
  dataProp: 'firstApplicantLoansAndCredits',
  ownership: SecLoansApplicants.FirstApplicant,
} as const
export type LoansAndCreditsFirstApplicant = typeof loansAndCreditsFirstApplicant

export const loansAndCreditsSecondApplicant = {
  hasProp: 'secondApplicantHasLoansAndCredits',
  dataProp: 'secondApplicantLoansAndCredits',
  ownership: SecLoansApplicants.SecondApplicant,
} as const
export type LoansAndCreditsSecondApplicant = typeof loansAndCreditsSecondApplicant

export const loansAndCreditsBothApplicants = {
  hasProp: 'hasSharedLoansAndCredits',
  dataProp: 'sharedLoansAndCredits',
  ownership: SecLoansApplicants.BothApplicants,
} as const

export type LoansAndCreditsBothApplicants = typeof loansAndCreditsBothApplicants

export type LoansAndCreditsApplicant =
  | LoansAndCreditsFirstApplicant
  | LoansAndCreditsSecondApplicant
  | LoansAndCreditsBothApplicants

export type RenderableLoansAndCreditsApplicant = LoansAndCreditsApplicant & {
  renderFields: RenderFieldParams<LoansAndCreditsInfoStepFormData>[]
  remove: (event: MouseEvent<HTMLButtonElement>, index: number) => void
  append: () => void
  data: SecLoansLoanOrCreditForm[]
  has: YesNo
}

export const getLoansAndCreditsForOwnership = (
  loansAndCreditsInfoStepData: LoansAndCreditsInfoStepData | undefined,
  ownership: SecLoansApplicants,
): SecLoansLoanOrCredit[] | undefined => {
  return loansAndCreditsInfoStepData?.loansAndCredits?.filter((lac) =>
    lac.ownership ? lac.ownership === ownership : ownership === SecLoansApplicants.FirstApplicant,
  )
}

const shouldConvertTransferredLoanToLoanAndCredit = (
  data: LoansAndCreditsInfoStepData | undefined,
  ownership: SecLoansApplicants,
  hasCoApplicant: boolean,
): boolean => {
  const last = data?.loansAndCredits?.findLast((v) => v.isReadOnly)
  if (!last) {
    return ownership === (hasCoApplicant ? SecLoansApplicants.BothApplicants : SecLoansApplicants.FirstApplicant)
  }
  return last.ownership === ownership
}

const convertLoansAndCreditToFormValue = (
  loansAndCreditsInfoStepData: LoansAndCreditsInfoStepData | undefined,
  loanType: SecLoanApplicationType,
  loanPurpose: SecLoanPurposeType,
  data: WizardData,
  lacApplicant: LoansAndCreditsApplicant,
  hasCoApplicant: boolean,
): LoansAndCreditsFormData => {
  const { ownership, hasProp, dataProp } = lacApplicant
  const isTransferPurpose = loanPurpose === SecLoanPurposeType.LoanTransferToSbank
  const housingLoanTransferData = data.targetInfo?.housingLoanInfo?.transferredLoanTargetData
  const purchaseLoanTransferData = isTransferPurpose ? data.targetInfo?.purchaseLoanInfo : undefined

  let loansAndCredits: SecLoansLoanOrCreditForm[] =
    getLoansAndCreditsForOwnership(loansAndCreditsInfoStepData, ownership)?.map((lac) => {
      const isStudentLoan = lac.loanOrCreditType === SecLoansLoanOrCreditType.StudentLoan

      return R.omit(
        {
          ...lac,
          id: uuidv4(),
          amount: numberToString(lac.amount),
          installment: numberToString(lac.installment),
          ...(isStudentLoan && { hasStartedPayingBackStudentLoan: lac.installment > 0 ? YesNo.Yes : YesNo.No }),
        },
        ['ownership'],
      )
    }) || []

  const currentResidenceProperty = getLoanFromCurrentResidence(data.applicantInfo, lacApplicant.ownership)

  if (currentResidenceProperty) {
    const isExisting = loansAndCredits.some(
      (loan) =>
        loan.amount === currentResidenceProperty.amount &&
        loan.lender === currentResidenceProperty.lender &&
        loan.loanOrCreditType === SecLoansLoanOrCreditType.Mortgage,
    )
    if (!isExisting) {
      loansAndCredits.push(currentResidenceProperty)
    }
  } else {
    loansAndCredits = loansAndCredits.filter((loan) => !loan.isReadOnly)
  }

  if (isTransferPurpose) {
    const existingLoanTransferLoan = loansAndCredits.findLast((loan) => loan.isReadOnly)

    const transferredLoan = getTransferredLoan(loanType, housingLoanTransferData, data, purchaseLoanTransferData)

    if (!existingLoanTransferLoan) {
      if (shouldConvertTransferredLoanToLoanAndCredit(loansAndCreditsInfoStepData, ownership, hasCoApplicant)) {
        loansAndCredits.push(transferredLoan)
      }
    } else {
      const transferredLoanData = R.omit(transferredLoan, ['id'])
      loansAndCredits = loansAndCredits.map((item) =>
        item.id === existingLoanTransferLoan.id ? { ...item, ...transferredLoanData } : item,
      )
    }
  }

  const yesNoDefault = loansAndCreditsInfoStepData === undefined ? undefined : YesNo.No
  const hasLoansAndCredits =
    loansAndCredits?.length > 0
      ? YesNo.Yes
      : getYesNoDefault(!!loansAndCreditsInfoStepData, loansAndCredits.length, yesNoDefault)

  return {
    [hasProp]: hasLoansAndCredits,
    [dataProp]: loansAndCredits || [],
  }
}

export const convertToFormValues = (
  loansAndCreditsInfoStepData: LoansAndCreditsInfoStepData | undefined,
  loanType: SecLoanApplicationType,
  loanPurpose: SecLoanPurposeType,
  data: WizardData,
  hasCoApplicant: boolean,
): LoansAndCreditsInfoStepFormData => {
  return convertUndefinedToNull({
    ...convertLoansAndCreditToFormValue(
      loansAndCreditsInfoStepData,
      loanType,
      loanPurpose,
      data,
      loansAndCreditsBothApplicants,
      hasCoApplicant,
    ),
    ...convertLoansAndCreditToFormValue(
      loansAndCreditsInfoStepData,
      loanType,
      loanPurpose,
      data,
      loansAndCreditsFirstApplicant,
      hasCoApplicant,
    ),
    ...convertLoansAndCreditToFormValue(
      loansAndCreditsInfoStepData,
      loanType,
      loanPurpose,
      data,
      loansAndCreditsSecondApplicant,
      hasCoApplicant,
    ),
    hasQuickLoans: getYesNoDefault(
      !!loansAndCreditsInfoStepData,
      loansAndCreditsInfoStepData?.numberOfQuickLoans,
      loansAndCreditsInfoStepData === undefined ? undefined : YesNo.No,
    ),
    quickLoansAmount: numberToString(loansAndCreditsInfoStepData?.quickLoansAmount),
    ...resolveNumberOfQuickLoans(loansAndCreditsInfoStepData?.numberOfQuickLoans),
    quickLoansInstallment: numberToString(loansAndCreditsInfoStepData?.quickLoansInstallment),
    secondApplicant: hasCoApplicant
      ? {
          hasQuickLoans: getYesNoDefault(
            !!loansAndCreditsInfoStepData,
            loansAndCreditsInfoStepData?.secondApplicant?.numberOfQuickLoans,
            loansAndCreditsInfoStepData === undefined ? undefined : YesNo.No,
          ),
          quickLoansAmount: numberToString(loansAndCreditsInfoStepData?.secondApplicant?.quickLoansAmount),
          ...resolveNumberOfQuickLoans(loansAndCreditsInfoStepData?.secondApplicant?.numberOfQuickLoans),
          quickLoansInstallment: numberToString(loansAndCreditsInfoStepData?.secondApplicant?.quickLoansInstallment),
        }
      : undefined,
  })
}

const resolveNumberOfQuickLoans = (numberOfQuickLoans: number | undefined) => {
  const formValues = getOptionAndOtherFormValues(numberOfQuickLoans, 1, MAX_NUMBER_OF_QUICK_LOANS_OPTION)
  return {
    numberOfQuickLoans: formValues.option,
    numberOfQuickLoansOther: formValues.other,
  }
}

const convertFormDataLoanCredit = (
  ownership: SecLoansApplicants,
): ((lac: SecLoansLoanOrCreditForm) => SecLoansLoanOrCredit) => {
  return ({ id: _id, hasStartedPayingBackStudentLoan: _hasStartedPayingBackStudentLoan, ...lac }) => {
    return {
      ...lac,
      amount: assertValue(toNumber(lac.amount), 'lac.amount'),
      installment: assertValue(toNumber(lac.installment), 'lac.installment'),
      ownership,
    }
  }
}

export const convertLoansAndCreditsToSubmitData = (formData?: LoansAndCreditsFormData): SecLoansLoanOrCredit[] => {
  if (!formData) {
    return []
  }
  const ret: SecLoansLoanOrCredit[] = []

  if (formData.hasSharedLoansAndCredits === YesNo.Yes && formData.sharedLoansAndCredits) {
    ret.push(...formData.sharedLoansAndCredits.map(convertFormDataLoanCredit(SecLoansApplicants.BothApplicants)))
  }
  if (formData.firstApplicantHasLoansAndCredits === YesNo.Yes && formData.firstApplicantLoansAndCredits) {
    ret.push(
      ...formData.firstApplicantLoansAndCredits.map(convertFormDataLoanCredit(SecLoansApplicants.FirstApplicant)),
    )
  }
  if (formData.secondApplicantHasLoansAndCredits === YesNo.Yes && formData.secondApplicantLoansAndCredits) {
    ret.push(
      ...formData.secondApplicantLoansAndCredits.map(convertFormDataLoanCredit(SecLoansApplicants.SecondApplicant)),
    )
  }
  return ret
}

export const convertToLoansAndCreditsInfoSubmitData = (
  formData: LoansAndCreditsInfoStepFormData,
  hasCoApplicant: boolean,
): LoansAndCreditsInfoStepData => {
  return removeUndefinedValues({
    loansAndCredits: convertLoansAndCreditsToSubmitData(formData),
    quickLoansAmount: toNumber(formData.quickLoansAmount),
    numberOfQuickLoans: formData.numberOfQuickLoans
      ? resolveValueFromFormFields(formData.numberOfQuickLoans, formData.numberOfQuickLoansOther)
      : undefined,
    quickLoansInstallment: toNumber(formData.quickLoansInstallment),
    secondApplicant: hasCoApplicant
      ? removeUndefinedValues({
          quickLoansAmount: toNumber(formData.secondApplicant?.quickLoansAmount),
          numberOfQuickLoans: formData.secondApplicant?.numberOfQuickLoans
            ? resolveValueFromFormFields(
                formData.secondApplicant.numberOfQuickLoans,
                formData.secondApplicant?.numberOfQuickLoansOther,
              )
            : undefined,
          quickLoansInstallment: toNumber(formData.secondApplicant?.quickLoansInstallment),
        })
      : undefined,
  })
}

export const getHousingLoanCreditType = (applicationType: SecLoanApplicationType): SecLoansLoanOrCreditType => {
  let loanType: SecLoansLoanOrCreditType

  switch (applicationType) {
    case SecLoanApplicationType.CottageLoan:
      loanType = SecLoansLoanOrCreditType.CottageLoan
      break

    case SecLoanApplicationType.HousingLoan:
      loanType = SecLoansLoanOrCreditType.Mortgage
      break

    case SecLoanApplicationType.PropertyInvestment:
      loanType = SecLoansLoanOrCreditType.InvestmentMortgage
      break
    default:
      loanType = SecLoansLoanOrCreditType.Other
      break
  }

  return loanType
}

export const getHousingLoanCreditTypeForPurchaseLoan = (
  purchasePurpose: SecLoansPurchaseLoanPurpose | undefined,
): SecLoansLoanOrCreditType => {
  let loanType: SecLoansLoanOrCreditType

  switch (purchasePurpose) {
    case SecLoansPurchaseLoanPurpose.Car:
      loanType = SecLoansLoanOrCreditType.CarLoan
      break
    case SecLoansPurchaseLoanPurpose.Consumption:
      loanType = SecLoansLoanOrCreditType.ConsumerCredit
      break
    case SecLoansPurchaseLoanPurpose.Investment:
      loanType = SecLoansLoanOrCreditType.InvestmentLoan
      break
    default:
      loanType = SecLoansLoanOrCreditType.Other
      break
  }

  return loanType
}

export const getAndValidateLoansAndCreditsInfoTypeData: ApplicantStepDataValidator<LoansAndCreditsInfoStepData> = (
  validationData,
  metadata,
) => {
  const loansAndCreditsInfoData = validationData.applicantInfo?.loansAndCreditsInfoData
  const { applicationType, applicationPurpose } = assertValue(metadata, 'metadata')
  const hasCoApplicant = !!validationData.applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant

  const formValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, hasCoApplicant)

  const formData = convertToFormValues(
    loansAndCreditsInfoData,
    applicationType,
    applicationPurpose,
    validationData,
    hasCoApplicant,
  )
  const isValid = isFormDataValid(formData, formValidator)

  const submitData = convertToLoansAndCreditsInfoSubmitData(formData, hasCoApplicant)
  const data = getValidatedData<LoansAndCreditsInfoStepData>(submitData, isValid)
  data.isComplete = getCompletenessForTwoApplicants({
    hasCoApplicant,
    isFirstApplicantValid: isValid,
    isSecondApplicantValid: !!submitData?.secondApplicant,
  })

  return data
}

export const getCreditType = (
  loanType: SecLoanApplicationType,
  purchaseLoanPurpose: SecLoansPurchaseLoanPurpose | undefined,
): SecLoansLoanOrCreditType => {
  return loanType === SecLoanApplicationType.PurchaseLoan
    ? getHousingLoanCreditTypeForPurchaseLoan(purchaseLoanPurpose)
    : getHousingLoanCreditType(loanType)
}

const getTransferredLoan = (
  loanType: SecLoanApplicationType,
  housingLoanTransferData: LoanTransferInfoData | undefined,
  data: WizardData,
  purchaseLoanTransferData: PurchaseLoanInfoData | undefined,
): SecLoansLoanOrCreditForm => {
  let loanAmount: string | undefined
  let installment: string | undefined
  let lender: Bank | undefined

  if (loanType !== SecLoanApplicationType.PurchaseLoan) {
    loanAmount = numberToString(housingLoanTransferData?.loanAmount)
    installment = numberToString(housingLoanTransferData?.loanMonthlyPayment)
    lender = housingLoanTransferData?.loanIssuer
  } else {
    loanAmount = numberToString(purchaseLoanTransferData?.loanAmount)
    installment = numberToString(purchaseLoanTransferData?.loanMonthlyPayment)
    lender = purchaseLoanTransferData?.loanIssuer
  }

  const transferredLoan: SecLoansLoanOrCreditForm = {
    id: uuidv4(),
    amount: loanAmount,
    isReadOnly: true,
    loanOrCreditType: getCreditType(loanType, data.targetInfo?.purchaseLoanInfo?.loanPurpose),
    installment,
    lender: assertValue(lender, 'lender'),
  }

  return transferredLoan
}

export const getTotalLoansCount = (
  firstApplicantLoansAndCredits: SecLoansLoanOrCreditForm[] | undefined,
  secondApplicantLoansAndCredits: SecLoansLoanOrCreditForm[] | undefined,
  sharedLoansAndCredits: SecLoansLoanOrCreditForm[] | undefined,
): number => {
  return (
    (firstApplicantLoansAndCredits?.length ?? 0) +
    (secondApplicantLoansAndCredits?.length ?? 0) +
    (sharedLoansAndCredits?.length ?? 0)
  )
}

export const getLoanTransferOwnership = (
  data: LoansAndCreditsFormData | SecLoansLoanOrCredit[] | undefined,
): SecLoansApplicants => {
  const lacs = Array.isArray(data) ? data : convertLoansAndCreditsToSubmitData(data)
  return lacs.find((v) => !!v.isReadOnly)?.ownership ?? SecLoansApplicants.FirstApplicant
}

const getLoanFromCurrentResidence = (
  applicantInfo: ApplicantInfoWizardData | undefined,
  applicant: SecLoansApplicants,
): SecLoansLoanOrCreditForm | undefined => {
  let currentResidenceInfo: CurrentResidenceInfoBase | null | undefined
  const ownership = applicantInfo?.housingInfoData?.ownership

  if (
    applicant === SecLoansApplicants.FirstApplicant &&
    ownership !== SecLoansApplicants.BothApplicants &&
    ownership !== SecLoansApplicants.SecondApplicant
  ) {
    currentResidenceInfo = applicantInfo?.currentResidenceInfoData
  } else if (applicant === SecLoansApplicants.SecondApplicant) {
    if (ownership === SecLoansApplicants.SecondApplicant) {
      currentResidenceInfo = applicantInfo?.currentResidenceInfoData
    } else {
      currentResidenceInfo = applicantInfo?.currentResidenceInfoData?.secondApplicant
    }
  } else if (applicant === SecLoansApplicants.BothApplicants && ownership === SecLoansApplicants.BothApplicants) {
    currentResidenceInfo = applicantInfo?.currentResidenceInfoData
  }

  if (!currentResidenceInfo) {
    return undefined
  }

  const { sellingPlan, remainingLoanAmount } = currentResidenceInfo

  if (
    (sellingPlan === SecLoansCurrentResidenceSellingPlan.SellingFirst ||
      sellingPlan === SecLoansCurrentResidenceSellingPlan.SellingAfter) &&
    remainingLoanAmount
  ) {
    return {
      id: uuidv4(),
      isReadOnly: true,
      loanOrCreditType: SecLoansLoanOrCreditType.Mortgage,
      lender: assertValue(currentResidenceInfo?.lender),
      amount: numberToString(currentResidenceInfo?.remainingLoanAmount),
      installment: numberToString(currentResidenceInfo?.remainingLoanInstallment),
    }
  }

  return undefined
}

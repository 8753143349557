import { AppId, Language } from '@shared'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'

export const useLocalizedTitle = (language: Language, appId: AppId): void => {
  const intl = useIntl()

  useEffect(() => {
    const siteHeaders: Record<AppId, string> = {
      [AppId.Bcr]: intl.formatMessage({ id: 'common-bcr-site-header' }),
      [AppId.CreditCard]: intl.formatMessage({ id: 'common-credit-card-site-header' }),
      [AppId.DigiLi]: intl.formatMessage({ id: 'common-digili-site-header' }),
      [AppId.Kyc]: intl.formatMessage({ id: 'common-kyc-site-header' }),
      [AppId.SecLoans]: intl.formatMessage({ id: 'common-sec-loans-site-header' }),
      [AppId.CorpKyc]: intl.formatMessage({ id: 'common-corp-kyc-site-header' }),
    }
    document.title = siteHeaders[appId]
  }, [appId, intl, language])
}

import { AuthenticationCancelledEvent } from '@components/common/authenticate-step/types'
import { ExitWizardEvent, FinishedEvent, NextStepEvent } from '@components/common/events'
import { GetDataDoneEvent } from '@components/common/get-data-step/getDataMachine'
import { simpleStepMachine } from '@components/state/simpleStepMachine'
import { AuthenticatedEvent, ContinueEvent } from '@components/state/types'
import { assertValue } from '@shared/helpers'
import { SecLoansEndpoint } from '@shared/sec-loans'
import {
  LoanApplicationResultResponse,
  SecLoanApplication,
  SecLoansApplicationMetadata,
  SecLoansGetApplicationResponse,
  SecLoansParam,
  SecLoansUserInfoResponse,
} from '@shared/sec-loans/types'
import { ConfigResponse, ExitUrls } from '@shared/types'
import { isAuthCallback } from '@ui-common/utils/authentication'
import { debugLog } from '@ui-common/utils/helpers'
import { callEndpoint, CustomRequestConfig } from '@ui-common/utils/http/client'
import { assign, fromPromise, setup, StateFrom } from 'xstate'

import { isPrimaryApplicantWaitingSecondaryApplicant } from '../utils/helpers'
import {
  APPLICANT_INFO_WIZARD_MACHINE_ID,
  ApplicantInfoPage,
} from './steps/applicant-info-phase/applicantInfoWizardMachine'
import { CatalogStepDataType } from './steps/catalog-step/types'
import { initializeStepMachine } from './steps/initialize-step/initializeStepMachine'
import { LOAN_INFO_WIZARD_MACHINE_ID, LoanInfoPage } from './steps/loan-info-phase/loanInfoWizardMachine'
import { LoanInfoWizardDataType } from './steps/loan-info-phase/types'
import { TARGET_INFO_WIZARD_MACHINE_ID, TargetInfoPage } from './steps/loan-target-phase/targetInfoWizardMachine'
import { ApplicantInfoWizardDataType, BaseWizardContext, TargetInfoWizardDataType, User, WizardData } from './types'
import { getAppData } from './wizardData'
import { PhaseStepType, PhaseType } from './wizardSteps'

export const PHASE_TARGET_INFO = 'PhaseTargetInfo'
export const PHASE_APPLICANT_INFO = 'PhaseApplicantInfo'
export const PHASE_LOAN_INFO = 'PhaseLoanInfo'

export const STEP_INITIALIZE = 'StepInitialize'
export const STEP_ENTRANCE = 'StepEntrance'
export const STEP_AUTHENTICATE = 'StepAuthenticate'
export const STEP_GET_BASIC_INFO = 'StepGetBasicInfo'
export const STEP_GET_APPLICATION = 'GetApplicationStep'
export const STEP_GET_ALL_APPLICATIONS = 'GetAllApplications'
export const STEP_CATALOG = 'StepCatalog'
export const STEP_STATUS = 'StepStatus'
export const STEP_POLL = 'StepPoll'
export const STEP_THE_END = 'StepTheEnd'
export const STEP_SUCCEEDED = 'SucceededStep'
export const STEP_ABORTED = 'AbortedStep'
export const STEP_CANCEL_APPLICATION = 'StepCancelApplication'
export const STEP_RETURN_APPLICATION = 'StepReturnApplication'

export type GoToStepEvent = {
  type: 'GOTO_STEP'
  phase: PhaseType
  step?: PhaseStepType
  isSecondApplicantMode?: boolean
}

export type GotoTheEndEvent = {
  type: 'GOTO_THE_END'
}

export type SectionDoneEvent = {
  type: 'SECTION_DONE'
}

export type ApplicationSaveEvent = {
  type: 'SAVE_APPLICATION'
  data: SecLoanApplication
}

export type BackToStatusEvent = {
  type: 'BACK_TO_STATUS'
}

export type ExitWithFallbackUrlsEvent = {
  type: 'SAVE_EXIT_URLS_AND_EXIT_WIZARD'
  data: { exitUrls: ExitUrls }
}

export type GetApplicationsAndStartOverEvent = {
  type: 'GET_APPLICATIONS_AND_START_OVER'
}

export type CancelApplicationEvent = {
  type: 'CANCEL_APPLICATION'
}

export type ReturnApplicationEvent = {
  type: 'RETURN_APPLICATION'
}

export type PollingDoneEvent = {
  type: 'done.invoke.StepPoll:invocation[0]'
  data: LoanApplicationResultResponse
}

export type WizardEvent =
  | ExitWizardEvent
  | ExitWithFallbackUrlsEvent
  | ContinueEvent
  | NextStepEvent<unknown>
  | GoToStepEvent
  | SectionDoneEvent
  | AuthenticatedEvent
  | AuthenticationCancelledEvent
  | PollingDoneEvent
  | GetDataDoneEvent<SecLoansUserInfoResponse>
  | GetDataDoneEvent<SecLoansGetApplicationResponse>
  | GetDataDoneEvent<SecLoansApplicationMetadata[]>
  | BackToStatusEvent
  | ApplicationSaveEvent
  | GotoTheEndEvent
  | GetApplicationsAndStartOverEvent
  | CancelApplicationEvent
  | ReturnApplicationEvent
  | FinishedEvent

export type SectionData = TargetInfoWizardDataType | ApplicantInfoWizardDataType | LoanInfoWizardDataType

export interface WizardContext extends BaseWizardContext, CatalogStepDataType, ConfigResponse<SecLoansParam> {
  data: WizardData
  authToken?: string
  stepToEdit?: TargetInfoPage | ApplicantInfoPage | LoanInfoPage
  user?: User
  loanApplicationStatusData?: LoanApplicationResultResponse
  isSecondApplicantMode: boolean
  shouldShowSentModal?: boolean
}

const initialContext: WizardContext = {
  data: {},
  user: undefined,
  isNewApplication: false,
  applicationMetadata: undefined,
  loanApplicationStatusData: undefined,
  isSecondApplicantMode: false,
  shouldShowSentModal: false,
}

export const shouldHydrateState = (state: StateFrom<typeof wizardMachine>): boolean => {
  return state?.value === STEP_AUTHENTICATE && isAuthCallback()
}

const POLL_RESULT_CONFIG: CustomRequestConfig = {
  maxRetries: 40,
  retryDelayMs: 3000,
}

export type AuthenticatedContext = Required<Pick<WizardContext, 'exitUrls' | 'authToken'>>

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createWizardMachine = (authenticatedContext?: AuthenticatedContext) => {
  return setup({
    types: {
      context: {} as WizardContext,
      events: {} as WizardEvent,
      input: {} as WizardContext,
    },
    actions: {
      saveInitialData: assign((_, config: ConfigResponse<SecLoansParam>) => {
        return config
      }),
      saveAuthenticationData: assign((_, authToken: string) => {
        return { authToken }
      }),
      saveEdit: assign(
        (_, options: { step?: TargetInfoPage | ApplicantInfoPage | LoanInfoPage; isSecondApplicantMode: boolean }) => {
          const { step, isSecondApplicantMode } = options
          return {
            stepToEdit: step,
            isSecondApplicantMode,
          }
        },
      ),
      storeBasicInfo: assign((_, user: SecLoansUserInfoResponse) => {
        return { user }
      }),
      storeCatalogData: assign((_, data: CatalogStepDataType) => {
        debugLog(`storeApplicationData: ${JSON.stringify(data)}`)
        return {
          ...data,
          applicationMetadata: data.applicationData,
        }
      }),
      storeApplication: assign((_, data: SecLoansGetApplicationResponse) => {
        return {
          applicationData: { ...data },
          applicationMetadata: { ...data },
          loanApplicationStatusData: data.applicationResult,
          catalogStepData: {
            applicationType: data.applicationType,
            applicationId: data.applicationId,
            applicationPurpose: data.applicationPurpose,
          },
          data: getAppData(data.data, data),
        }
      }),
      saveApplication: assign((_, data: SecLoanApplication) => {
        return {
          stepToEdit: undefined,
          data: getAppData(data.data, data),
        }
      }),
      saveAllApplications: assign(({ context }, data: SecLoansApplicationMetadata[]) => {
        return {
          user: {
            ...assertValue(context.user, 'context.user'),
            applications: data,
          },
        }
      }),
      saveLoanStatus: assign((_, data: LoanApplicationResultResponse) => {
        return { loanApplicationStatusData: data }
      }),
      disableSecondApplicantMode: assign(() => {
        return {
          isSecondApplicantMode: false,
        }
      }),
      setShouldShowSentModal: assign(() => {
        return {
          shouldShowSentModal: true,
        }
      }),
      clearLoanApplicationData: assign(({ context }) => {
        return {
          ...initialContext,
          user: context.user,
          authToken: context.authToken,
        }
      }),
    },
    actors: {
      poll: fromPromise(() => {
        debugLog('Service: poll')
        return callEndpoint(SecLoansEndpoint.ENDPOINT_GET_LOAN_APPLICATION_RESULT, {
          requestConfig: POLL_RESULT_CONFIG,
        })
      }),
      initializeStepMachine,
      simpleStepMachine,
    },
    guards: {
      isGotoTargetInfoEvent: (_, phase: string) => {
        return phase === TARGET_INFO_WIZARD_MACHINE_ID
      },
      isGotoApplicantInfoEvent: (_, phase: string) => {
        return phase === APPLICANT_INFO_WIZARD_MACHINE_ID
      },
      isGotoLoanInfoEvent: (_, phase: string) => {
        return phase === LOAN_INFO_WIZARD_MACHINE_ID
      },
      isNewApplication: (_, data: CatalogStepDataType) => {
        return !!data.isNewApplication
      },
      isPrimaryApplicantWaitingSecondaryApplicant: (_, data: SecLoansGetApplicationResponse) => {
        const { applicantRole, applicationState } = data
        return isPrimaryApplicantWaitingSecondaryApplicant(applicantRole, applicationState)
      },
      isSentApplication: (_, data: SecLoansGetApplicationResponse) => {
        return !!data?.applicationResult
      },
    },
  }).createMachine({
    id: 'wizardMachine',
    predictableActionArguments: true,
    initial: authenticatedContext ? STEP_GET_BASIC_INFO : STEP_INITIALIZE,
    context: authenticatedContext ? { ...initialContext, ...authenticatedContext } : initialContext,
    states: {
      [STEP_INITIALIZE]: {
        id: STEP_INITIALIZE,
        invoke: {
          id: 'initializeStepMachine',
          src: 'initializeStepMachine',
        },
        on: {
          NEXT_STEP: {
            target: STEP_ENTRANCE,
            actions: {
              type: 'saveInitialData',
              params: ({ event }) => (event as NextStepEvent<ConfigResponse<SecLoansParam>>).data,
            },
          },
          SAVE_EXIT_URLS_AND_EXIT_WIZARD: {
            target: STEP_ABORTED,
            actions: { type: 'saveInitialData', params: ({ event }) => event.data },
          },
        },
      },
      [STEP_ENTRANCE]: {
        id: STEP_ENTRANCE,
        invoke: {
          id: 'entranceStepMachine',
          src: 'simpleStepMachine',
        },
        on: {
          NEXT_STEP: { target: STEP_AUTHENTICATE },
        },
      },
      [STEP_AUTHENTICATE]: {
        id: STEP_AUTHENTICATE,
        on: {
          AUTHENTICATION_DONE: [
            {
              target: STEP_GET_BASIC_INFO,
              actions: { type: 'saveAuthenticationData', params: ({ event }) => event.data.authToken },
            },
          ],
          AUTHENTICATION_CANCELLED: {
            target: STEP_ENTRANCE,
          },
        },
      },
      [STEP_GET_BASIC_INFO]: {
        id: STEP_GET_BASIC_INFO,
        on: {
          GET_DATA_DONE: [
            {
              target: STEP_CATALOG,
              actions: {
                type: 'storeBasicInfo',
                params: ({ event }) => event.data as SecLoansUserInfoResponse,
              },
            },
          ],
        },
      },
      [STEP_GET_ALL_APPLICATIONS]: {
        id: STEP_GET_ALL_APPLICATIONS,
        on: {
          GET_DATA_DONE: [
            {
              target: STEP_CATALOG,
              actions: {
                type: 'saveAllApplications',
                params: ({ event }) => event.data as SecLoansApplicationMetadata[],
              },
            },
          ],
        },
      },
      [STEP_CATALOG]: {
        id: STEP_CATALOG,
        on: {
          NEXT_STEP: [
            {
              guard: { type: 'isNewApplication', params: ({ event }) => event.data as CatalogStepDataType },
              target: STEP_STATUS,
              actions: { type: 'storeCatalogData', params: ({ event }) => event.data as CatalogStepDataType },
            },
            {
              target: STEP_GET_APPLICATION,
              actions: { type: 'storeCatalogData', params: ({ event }) => event.data as CatalogStepDataType },
            },
          ],
        },
      },
      [STEP_GET_APPLICATION]: {
        id: STEP_GET_APPLICATION,
        on: {
          GET_DATA_DONE: [
            {
              guard: {
                type: 'isPrimaryApplicantWaitingSecondaryApplicant',
                params: ({ event }) => event.data as SecLoansGetApplicationResponse,
              },
              target: STEP_THE_END,
              actions: {
                type: 'storeApplication',
                params: ({ event }) => event.data as SecLoansGetApplicationResponse,
              },
            },
            {
              guard: { type: 'isSentApplication', params: ({ event }) => event.data as SecLoansGetApplicationResponse },
              target: STEP_THE_END,
              actions: {
                type: 'storeApplication',
                params: ({ event }) => event.data as SecLoansGetApplicationResponse,
              },
            },
            {
              target: STEP_STATUS,
              actions: {
                type: 'storeApplication',
                params: ({ event }) => event.data as SecLoansGetApplicationResponse,
              },
            },
          ],
        },
      },
      [STEP_STATUS]: {
        id: STEP_STATUS,
        on: {
          NEXT_STEP: { target: PHASE_TARGET_INFO },
          GOTO_STEP: [
            {
              target: PHASE_TARGET_INFO,
              guard: { type: 'isGotoTargetInfoEvent', params: ({ event }) => event.phase },
              actions: {
                type: 'saveEdit',
                params: ({ event }) => ({
                  isSecondApplicantMode: !!event.isSecondApplicantMode,
                  step: event.step,
                }),
              },
            },
            {
              target: PHASE_APPLICANT_INFO,
              guard: { type: 'isGotoApplicantInfoEvent', params: ({ event }) => event.phase },
              actions: {
                type: 'saveEdit',
                params: ({ event }) => ({
                  step: event.step,
                  isSecondApplicantMode: !!event.isSecondApplicantMode,
                }),
              },
            },
            {
              target: PHASE_LOAN_INFO,
              guard: { type: 'isGotoLoanInfoEvent', params: ({ event }) => event.phase },
              actions: {
                type: 'saveEdit',
                params: ({ event }) => ({
                  step: event.step,
                  isSecondApplicantMode: !!event.isSecondApplicantMode,
                }),
              },
            },
          ],
          CONTINUE: { target: STEP_POLL, actions: { type: 'setShouldShowSentModal' } },
          CANCEL_APPLICATION: { target: STEP_CANCEL_APPLICATION, actions: { type: 'clearLoanApplicationData' } },
          RETURN_APPLICATION: { target: STEP_RETURN_APPLICATION, actions: { type: 'clearLoanApplicationData' } },
        },
      },
      [PHASE_TARGET_INFO]: {
        id: PHASE_TARGET_INFO,
        on: {
          SECTION_DONE: { target: STEP_STATUS },
        },
      },
      [PHASE_APPLICANT_INFO]: {
        id: PHASE_APPLICANT_INFO,
        on: {
          SECTION_DONE: { target: STEP_STATUS, actions: { type: 'disableSecondApplicantMode' } },
        },
      },
      [PHASE_LOAN_INFO]: {
        id: PHASE_LOAN_INFO,
        on: {
          SECTION_DONE: { target: STEP_STATUS },
        },
      },
      [STEP_POLL]: {
        id: STEP_POLL,
        invoke: {
          src: 'poll',
          onDone: [
            {
              target: STEP_THE_END,
              actions: { type: 'saveLoanStatus', params: ({ event }) => event.output },
            },
          ],
          onError: [
            {
              target: STEP_THE_END,
            },
          ],
        },
      },
      [STEP_CANCEL_APPLICATION]: {
        id: STEP_CANCEL_APPLICATION,
        on: {
          GET_DATA_DONE: [{ target: STEP_GET_ALL_APPLICATIONS, actions: { type: 'clearLoanApplicationData' } }],
        },
      },
      [STEP_RETURN_APPLICATION]: {
        id: STEP_RETURN_APPLICATION,
        on: {
          GET_DATA_DONE: [{ target: STEP_GET_ALL_APPLICATIONS, actions: { type: 'clearLoanApplicationData' } }],
        },
      },
      [STEP_THE_END]: {
        id: STEP_THE_END,
        on: {
          FINISHED: { target: STEP_SUCCEEDED },
        },
      },
      [STEP_SUCCEEDED]: {
        id: STEP_SUCCEEDED,
        type: 'final',
      },
      [STEP_ABORTED]: {
        id: STEP_ABORTED,
        type: 'final',
      },
    },
    on: {
      EXIT_WIZARD: { target: `.${STEP_ABORTED}` },
      SAVE_APPLICATION: { actions: { type: 'saveApplication', params: ({ event }) => event.data } },
      BACK_TO_STATUS: { target: `.${STEP_STATUS}` },
      GET_APPLICATIONS_AND_START_OVER: {
        target: `.${STEP_GET_ALL_APPLICATIONS}`,
        actions: { type: 'clearLoanApplicationData' },
      },
    },
  })
}

export const wizardMachine = createWizardMachine()

import { SecLoansValidationConstants } from '@shared'
import { validateTextArea } from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { CommonMunicipality } from '../../../../utils/locationInfo'
import { LocationInfoFormData as FormData } from './LocationInfoStep'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changedFields) => {
    only(changedFields)

    test('location', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.location).inside(Object.values(CommonMunicipality))
    })

    omitWhen(data.location !== CommonMunicipality.Other, () => {
      test('otherLocation', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.otherLocation).isNotEmpty()
      })
    })

    validateTextArea(
      formatMessage,
      'preciseLocation',
      data.preciseLocation,
      SecLoansValidationConstants.PRECISE_LOCATION_MAX_LENGTH,
    )
  })
}

import { ConstructionMaterial, SecLoansValidationConstants } from '@shared'
import {
  validateDecimal,
  validateFutureMonthAndYear,
  validateIntegerFromListOrOther,
} from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, only, Suite, test } from 'vest'

import { ConstructionLoanTargetInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changedFields) => {
    only(changedFields)

    test('constructionMaterial', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.constructionMaterial).inside(Object.values(ConstructionMaterial))
    })

    validateIntegerFromListOrOther(
      'numberOfFloors',
      formatMessage,
      data.numberOfFloors,
      data.numberOfFloorsOther,
      SecLoansValidationConstants.MIN_NUMBER_OF_FLOORS,
      SecLoansValidationConstants.MAX_NUMBER_OF_FLOORS,
    )

    validateFutureMonthAndYear({
      fieldName: 'desiredCompletionDate',
      formatMessage,
      dateToValidate: data.desiredCompletionDate,
      isRequired: true,
    })

    validateDecimal(formatMessage, 'livingSquareArea', data.livingSquareArea, 1, 999.9, 1, true)

    validateDecimal(formatMessage, 'totalSquareArea', data.totalSquareArea, 1, 999.9, 1, true)
  })
}

import { OpenModalEvent } from '@components/common/Modal/modalMachine'
import { SecLoansApplicationMetadata } from '@sec-loans-types'
import { getAndValidateLoansAndCreditsInfoTypeData } from '@sec-loans-ui/wizard/steps/applicant-info-phase/loans-and-credits-info-step/loansAndCreditsInfoStepUtils'
import { WizardData } from '@sec-loans-ui/wizard/types'
import { getAppData } from '@sec-loans-ui/wizard/wizardData'
import { ModalVisualMode } from '@ui-components/containers/ModalTypes'
import { ReactNode } from 'react'
import { IntlShape } from 'react-intl'

export const getHasDependantChanges = ({
  wizardData,
  changedData,
  applicationMetadata,
}: {
  wizardData: WizardData
  changedData: WizardData
  applicationMetadata: SecLoansApplicationMetadata
}): boolean => {
  const updatedData = getAppData(changedData, applicationMetadata)
  return [
    wizardData.targetInfo?.isComplete && !updatedData.targetInfo?.isComplete,
    wizardData.applicantInfo?.isComplete && !updatedData.applicantInfo?.isComplete,
    wizardData.loanInfo?.isComplete && !updatedData.loanInfo?.isComplete,
  ].some(Boolean)
}

export const showDependantChangesModal = (
  onSubmit: (data: WizardData) => void,
  modalSend: (openModalEvent: OpenModalEvent) => void,
  children: ReactNode,
  intl: IntlShape,
  data: WizardData,
): void => {
  const openEvent: OpenModalEvent = {
    type: 'OPEN',
    data: {
      header: intl.formatMessage({ id: 'sec-loans-dependant-changes-modal-header' }),
      children,
      primaryButtonOptions: {
        label: intl.formatMessage({ id: 'common-cancel' }),
        type: 'primary',
      },
      secondaryButtonOptions: {
        label: intl.formatMessage({ id: 'common-continue-anyway' }),
        onClick: () => onSubmit(data),
      },
      mode: ModalVisualMode.Help,
    },
  }
  modalSend(openEvent)
}

export const onFormSubmitCheckDependantData = ({
  wizardData,
  changedData,
  applicationMetadata,
  submit,
  modalSend,
  children,
  intl,
}: {
  wizardData: WizardData
  changedData: WizardData
  applicationMetadata: SecLoansApplicationMetadata
  submit: (data: WizardData) => void
  modalSend: (openModalEvent: OpenModalEvent) => void
  children: ReactNode
  intl: IntlShape
}): void => {
  let dependenciesAppliedData: WizardData = changedData
  if (dependenciesAppliedData.applicantInfo?.loansAndCreditsInfoData) {
    const { data: loansAndCreditsInfoData, isComplete } =
      getAndValidateLoansAndCreditsInfoTypeData(dependenciesAppliedData, applicationMetadata) ?? {}

    dependenciesAppliedData = {
      ...dependenciesAppliedData,
      applicantInfo: {
        ...dependenciesAppliedData.applicantInfo,
        loansAndCreditsInfoData,
        isComplete: !!(dependenciesAppliedData.applicantInfo.isComplete && isComplete),
      },
    }
  }
  const dependantChanges = getHasDependantChanges({
    wizardData,
    changedData: dependenciesAppliedData,
    applicationMetadata,
  })
  if (dependantChanges) {
    showDependantChangesModal(submit, modalSend, children, intl, dependenciesAppliedData)
  } else {
    submit(dependenciesAppliedData)
  }
}

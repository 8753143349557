import { updatePageFocus } from '@ui-common/utils'
import { useEffect } from 'react'

export const useSrPageFocusUpdate = (state: unknown): void => {
  useEffect(() => {
    const timeoutIds = updatePageFocus()
    return () => {
      timeoutIds.forEach((t) => clearTimeout(t))
    }
  }, [state])
}

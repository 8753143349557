import {
  ExitWizardEvent,
  FinishedEvent,
  GoToSummaryEvent,
  NextStepEvent,
  PreviousStepEvent,
  StartEvent,
} from '@components/common/events'
import { WizardMachineStep } from '@components/common/types'
import { simpleStepMachine } from '@components/state/simpleStepMachine'
import { ContinueEvent, EditableMachineContext } from '@components/state/types'
import {
  AdditionalInfoData,
  AdditionalInfoDataType,
  AddressInfoData,
  AddressInfoDataType,
  BackgroundInfoBase,
  BackgroundInfoDataType,
  BackgroundInfoSecondApplicantData,
  CollateralInfoData,
  CollateralInfoDataType,
  ConstructionLoanTargetInfoDataType,
  CreditInfoBase,
  CreditInfoData,
  CreditInfoDataType,
  CurrentResidenceInfoBase,
  CurrentResidenceInfoData,
  CurrentResidenceInfoDataType,
  EmploymentInfoBase,
  EmploymentInfoData,
  EmploymentInfoDataType,
  EntrepreneurInfoBase,
  EntrepreneurInfoData,
  EntrepreneurInfoDataType,
  ExpensesInfoBase,
  ExpensesInfoData,
  ExpensesInfoDataType,
  GuaranteesInfoData,
  GuaranteesInfoDataType,
  HousingInfoBase,
  HousingInfoData,
  HousingInfoDataType,
  HousingLoanInfo,
  HousingLoanInfoDataType,
  IncomeInfoBase,
  IncomeInfoData,
  IncomeInfoDataType,
  InstallmentInfoData,
  InstallmentInfoDataType,
  InsuranceInfoData,
  InsuranceInfoDataType,
  LivingExpensesInfoData,
  LivingExpensesInfoDataType,
  LoanAdditionalInfoData,
  LoanAdditionalInfoDataType,
  LoanBackgroundInfoData,
  LoanBackgroundInfoDataType,
  LoanCostEstimateInfoDataType,
  LoansAndCreditsInfoBase,
  LoansAndCreditsInfoData,
  LoansAndCreditsInfoDataType,
  LoanTransferTargetInfoDataType,
  LocationInfoData,
  LocationInfoDataType,
  MonthlySavingsInfoBase,
  MonthlySavingsInfoData,
  MonthlySavingsInfoDataType,
  NumberOfApplicantsInfoData,
  NumberOfApplicantsInfoDataType,
  OptionalAndNullable,
  PaymentPlanInfoData,
  PaymentPlanInfoDataType,
  PriceInfoData,
  PriceInfoDataType,
  PurchaseLoanInfoData,
  PurchaseLoanInfoDataType,
  RealEstateLivingExpensesInfoData,
  RealEstateLivingExpensesInfoDataType,
  ReferenceRateInfoData,
  ReferenceRateInfoDataType,
  RenovationsInfoData,
  RenovationsInfoDataType,
  ResidenceInfoData,
  ResidenceInfoDataType,
  SecLoanApplication,
  SecLoansApplicationMetadata,
  SecLoansUserInfoResponse,
  TargetAdditionalInfoData,
  TargetAdditionalInfoDataType,
  TargetTypeInfoData,
  TargetTypeInfoDataType,
  WealthInfoData,
  WealthInfoDataType,
} from '@shared'
import { AnyActorRef } from 'xstate'

import { FormStateNotifierProps } from './Steps'

export interface BaseWizardContext {
  applicationMetadata?: SecLoansApplicationMetadata
}

export interface WizardPhaseContext<T> extends BaseWizardContext, EditableMachineContext {
  stepToEdit?: T
}

export type ProgressFormSendType = 'SUBMIT' | 'VALIDATE' | 'VALIDATION_FAILED'
export type ProgressFormAsyncValidateSubmit = (
  validated: (result: 'valid' | 'invalid' | 'server-error') => void,
) => Promise<void> | undefined
export type ProgressFormType = (
  updatedData: WizardData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  send: ({ type, data }: { type: any; data: WizardData }) => void,
  asyncValidateSubmit?: ProgressFormAsyncValidateSubmit,
) => void

export interface WizardStepProps extends FormStateNotifierProps {
  parentService: AnyActorRef
  showAbortPromptModal: (event: React.BaseSyntheticEvent) => void
  onFormStepSubmit: ProgressFormType
}

export interface WizardStepWithSecondApplicantModeProps extends WizardStepProps {
  isSecondApplicantMode: boolean
}

export type WizardStep = ({ parentService, showAbortPromptModal }: WizardStepProps) => JSX.Element

export type ApplicantInfoWizardStep = ({
  parentService,
  showAbortPromptModal,
  isSecondApplicantMode,
}: WizardStepWithSecondApplicantModeProps) => JSX.Element

export type SimpleWizardStepProps = WizardMachineStep<typeof simpleStepMachine>

export interface WizardPhaseProps extends FormStateNotifierProps {
  showAbortPromptModal: (event: React.BaseSyntheticEvent) => void
  onFinished: () => void
  onAbort: () => void
  onFormStepSubmit: ProgressFormType
}

export type SectionWizardEvent<TDataType> =
  | ExitWizardEvent
  | ContinueEvent
  | NextStepEvent<TDataType>
  | PreviousStepEvent
  | FinishedEvent
  | StartEvent<BaseWizardContext>
  | GoToSummaryEvent<TDataType>

export type User = SecLoansUserInfoResponse

export interface SecLoansApplicationData {
  applicationData?: SecLoanApplication
}

export type HousingLoanApplicationData = WizardData

export interface HierarchicalApplicationData {
  isComplete?: boolean
}

export interface TargetInfoWizardDataType extends HierarchicalApplicationData {
  targetInfo?: TargetInfoWizardData
}

export interface ApplicantInfoWizardDataType extends HierarchicalApplicationData {
  applicantInfo?: ApplicantInfoWizardData
}

export interface LoanInfoWizardDataType extends HierarchicalApplicationData {
  loanInfo?: LoanInfoWizardData
}

export interface WizardData
  extends TargetInfoWizardDataType,
    ApplicantInfoWizardDataType,
    LoanInfoWizardDataType,
    HierarchicalApplicationData {}

export interface PriceInfoStepDataType extends PriceInfoDataType, HierarchicalApplicationData {}

export type PriceInfoStepData = PriceInfoData

export interface LocationInfoStepDataType extends LocationInfoDataType, HierarchicalApplicationData {}

export type LocationInfoStepData = LocationInfoData

export interface AddressInfoStepDataType extends AddressInfoDataType, HierarchicalApplicationData {}

export type AddressInfoStepData = AddressInfoData

export type AdditionalInfoStepData = AdditionalInfoData

export interface AdditionalInfoStepDataType extends AdditionalInfoDataType, HierarchicalApplicationData {}

export type TargetAdditionalInfoStepData = TargetAdditionalInfoData

export interface TargetAdditionalInfoStepDataType extends TargetAdditionalInfoDataType, HierarchicalApplicationData {}

export type LoanAdditionalInfoStepData = LoanAdditionalInfoData

export interface LoanAdditionalInfoStepDataType extends LoanAdditionalInfoDataType, HierarchicalApplicationData {}

export interface TargetTypeInfoStepDataType extends TargetTypeInfoDataType, HierarchicalApplicationData {}
export interface LoanTransferTargetInfoStepDataType
  extends LoanTransferTargetInfoDataType,
    HierarchicalApplicationData {}

export type TargetTypeInfoStepData = TargetTypeInfoData

export type LoanTransferTargetInfoStepData = HousingLoanInfo

export type PurchaseLoanInfoStepData = PurchaseLoanInfoData

export interface PurchaseLoanInfoStepDataType extends PurchaseLoanInfoDataType, HierarchicalApplicationData {}

export type ResidenceInfoStepData = ResidenceInfoData

export interface ResidenceInfoStepDataType extends ResidenceInfoDataType, HierarchicalApplicationData {}

export interface RenovationsInfoStepDataType extends RenovationsInfoDataType, HierarchicalApplicationData {}

export type RenovationsInfoStepData = RenovationsInfoData

export interface LivingExpensesInfoStepDataType extends LivingExpensesInfoDataType, HierarchicalApplicationData {}

export type LivingExpensesInfoStepData = LivingExpensesInfoData

export type RealEstateLivingExpensesInfoStepData = RealEstateLivingExpensesInfoData

export interface RealEstateLivingExpensesInfoStepDataType
  extends RealEstateLivingExpensesInfoDataType,
    HierarchicalApplicationData {}

export interface TargetInfoWizardData
  extends PriceInfoStepDataType,
    LocationInfoStepDataType,
    AddressInfoStepDataType,
    TargetAdditionalInfoStepDataType,
    HousingLoanInfoDataType,
    ResidenceInfoStepDataType,
    RealEstateLivingExpensesInfoStepDataType,
    LivingExpensesInfoStepDataType,
    PurchaseLoanInfoStepDataType,
    RenovationsInfoStepDataType,
    LoanCostEstimateInfoDataType,
    ConstructionLoanTargetInfoDataType,
    HierarchicalApplicationData {}

export interface EmploymentInfoStepDataType extends EmploymentInfoDataType, HierarchicalApplicationData {}

export type EmploymentInfoStepBase = EmploymentInfoBase

export type EmploymentInfoStepData = EmploymentInfoData

export type EntrepreneurInfoStepBase = EntrepreneurInfoBase
export interface EntrepreneurInfoStepDataType extends EntrepreneurInfoDataType, HierarchicalApplicationData {}

export type HousingInfoStepData = HousingInfoData
export type EntrepreneurInfoStepData = EntrepreneurInfoData

export type BackgroundInfoStepBase = BackgroundInfoBase

export interface BackgroundInfoStepData extends BackgroundInfoStepBase {
  secondApplicant?: BackgroundInfoSecondApplicantData
}

export interface BackgroundInfoStepDataType extends BackgroundInfoDataType, HierarchicalApplicationData {}

export interface HousingInfoStepDataType extends HousingInfoDataType, HierarchicalApplicationData {}

export type HousingInfoStepBase = HousingInfoBase

export interface IncomeInfoStepDataType extends IncomeInfoDataType, HierarchicalApplicationData {}

export type IncomeInfoStepBase = IncomeInfoBase
export type IncomeInfoStepData = IncomeInfoData

export type NumberOfApplicantsInfoStepData = NumberOfApplicantsInfoData

export interface NumberOfApplicantsInfoStepDataType
  extends NumberOfApplicantsInfoDataType,
    HierarchicalApplicationData {}

export type WealthInfoStepData = WealthInfoData

export interface WealthInfoStepDataType extends WealthInfoDataType, HierarchicalApplicationData {}

export type CreditInfoStepBase = CreditInfoBase

export type CreditInfoStepData = CreditInfoData

export interface CreditInfoStepDataType extends CreditInfoDataType, HierarchicalApplicationData {}

export type CurrentResidenceInfoStepBase = CurrentResidenceInfoBase

export type CurrentResidenceInfoStepData = CurrentResidenceInfoData

export interface CurrentResidenceInfoStepDataType extends CurrentResidenceInfoDataType, HierarchicalApplicationData {}

export interface ExpensesInfoStepDataType extends ExpensesInfoDataType, HierarchicalApplicationData {}

export type ExpensesInfoStepData = ExpensesInfoData

export type ExpensesInfoStepBase = ExpensesInfoBase

export type LoanBackgroundInfoStepData = LoanBackgroundInfoData

export interface LoanBackgroundInfoStepDataType extends LoanBackgroundInfoDataType, HierarchicalApplicationData {}

export type InstallmentInfoStepData = InstallmentInfoData

export interface InstallmentInfoStepDataType extends InstallmentInfoDataType, HierarchicalApplicationData {}

export type GuaranteesInfoStepData = GuaranteesInfoData

export interface GuaranteesInfoStepDataType extends GuaranteesInfoDataType, HierarchicalApplicationData {}

export type LoansAndCreditsInfoStepBase = LoansAndCreditsInfoBase

export type LoansAndCreditsInfoStepData = LoansAndCreditsInfoData

export interface LoansAndCreditsInfoStepDataType extends LoansAndCreditsInfoDataType, HierarchicalApplicationData {}

export interface ApplicantInfoWizardData
  extends NumberOfApplicantsInfoStepDataType,
    ExpensesInfoStepDataType,
    IncomeInfoStepDataType,
    HousingInfoStepDataType,
    WealthInfoStepDataType,
    BackgroundInfoStepDataType,
    EmploymentInfoStepDataType,
    EntrepreneurInfoStepDataType,
    CurrentResidenceInfoStepDataType,
    CreditInfoStepDataType,
    LoansAndCreditsInfoStepDataType,
    InstallmentInfoStepDataType,
    AdditionalInfoStepDataType,
    GuaranteesInfoStepDataType,
    HierarchicalApplicationData {}

export interface PaymentPlanInfoStepDataType extends PaymentPlanInfoDataType, HierarchicalApplicationData {}

export type PaymentPlanInfoStepData = PaymentPlanInfoData
export interface ReferenceRateStepDataType extends ReferenceRateInfoDataType, HierarchicalApplicationData {}

export type ReferenceRateStepInfoData = ReferenceRateInfoData

export interface InsuranceInfoStepDataType extends InsuranceInfoDataType, HierarchicalApplicationData {}

export type InsuranceInfoStepData = InsuranceInfoData

export interface CollateralInfoStepDataType extends CollateralInfoDataType, HierarchicalApplicationData {}

export type CollateralInfoStepData = CollateralInfoData

export type MonthlySavingsInfoStepData = MonthlySavingsInfoData

export interface MonthlySavingsInfoStepDataType extends MonthlySavingsInfoDataType, HierarchicalApplicationData {}

export type MonthlySavingsInfoStepBase = MonthlySavingsInfoBase
export interface LoanInfoWizardData
  extends LoanBackgroundInfoStepDataType,
    PaymentPlanInfoStepDataType,
    ReferenceRateStepDataType,
    InsuranceInfoStepDataType,
    CollateralInfoStepDataType,
    MonthlySavingsInfoStepDataType,
    LoanAdditionalInfoStepDataType,
    HierarchicalApplicationData {}

export type StepDataValidator<T> = (validationData: WizardData, applicationMetadata?: SecLoansApplicationMetadata) => T

export type ValidatedData<T> = { data: T; isComplete: boolean }

export type ApplicantStepDataValidator<T> = (
  validationData: WizardData,
  applicationMetadata?: SecLoansApplicationMetadata,
) => ValidatedData<T | undefined> | undefined | null

export interface PhaseDataHandler<TStepData> {
  location: OptionalAndNullable<WizardData>
  validate: StepDataValidator<TStepData>
}

export interface ApplicantPhaseDataHandler<TStepData> {
  location: WizardData
  validate: ApplicantStepDataValidator<TStepData>
}

export enum SecondaryApplicantChoice {
  SubmitApplication = 'submitApplication',
  ReturnApplication = 'returnApplication',
}

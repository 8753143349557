import { HTMLProps } from 'react'

export const ImplicitlySubmittableForm = (props: { isLoading?: boolean } & HTMLProps<HTMLFormElement>): JSX.Element => {
  const { children, isLoading, ...withoutChildren } = props
  return (
    <form {...withoutChildren}>
      {children}
      <input type="submit" disabled={!!isLoading} aria-hidden="true" style={{ display: 'none' }} value=" " />
    </form>
  )
}

import { OTHER_VALUE } from '@shared'
import { YesNo } from '@ui-common-types'
import { FormGroupOption } from '@ui-components/global/ComponentProps'
import { FieldError, FieldErrors, FieldPath, FieldValues, get, Primitive } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import * as R from 'remeda'

export const VALIDATION_ERROR_DELAY_MS = process.env.NODE_ENV === 'test' ? 50 : 750 // Do not slow down tests too much
export const INPUT_DEBOUNCE_DELAY_MS = process.env.NODE_ENV === 'test' ? 0 : 1000
export const hasError = (fieldError: FieldError | undefined): boolean => fieldError?.message !== undefined
export const hasErrorArray = (fieldError: FieldError[] | undefined): boolean => {
  if (Array.isArray(fieldError)) {
    return fieldError.some(hasError)
  }
  return hasError(fieldError)
}

const trimIfString = <T>(value: T) => (R.isString(value) ? value.trim() : value)

export const trimStrings = <T>(data: T): T => {
  if (typeof data === 'object' && data !== null) {
    for (const key in data) {
      if (data[key] !== null && data[key] !== undefined) {
        if (typeof data[key] === 'object') {
          trimStrings(data[key])
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data[key] = trimIfString(data[key]) as any
      }
    }
  }
  return trimIfString(data) as T
}

export const buildOptionList = <Enum extends string | number>(
  keys: Enum[],
  primaryTexts: Record<Enum, string>,
  secondaryTexts?: Record<Enum, string>,
  labelFormatter?: (value: string | number) => string,
): FormGroupOption[] =>
  keys.map((key) => ({
    key: key + '',
    label: labelFormatter ? labelFormatter(primaryTexts[key] ?? '') : (primaryTexts[key] ?? ''),
    description: secondaryTexts ? secondaryTexts[key] : undefined,
  }))

export const buildNumberList = (start: number, end: number, moreOption?: string): FormGroupOption[] => {
  const options = Array.from({ length: end - start + 1 }, (_, index) => ({
    key: index + start + '',
    label: index + start + '',
  }))
  moreOption && options.push({ key: OTHER_VALUE, label: moreOption })
  return options
}

export const commonYesNoOptions = (
  intl: IntlShape,
  shouldUseFirstPersonForm = false,
): { key: YesNo; label: string }[] => [
  {
    key: YesNo.No,
    label: shouldUseFirstPersonForm
      ? intl.formatMessage({ id: 'common-no-me' })
      : intl.formatMessage({ id: 'common-no' }),
  },
  {
    key: YesNo.Yes,
    label: intl.formatMessage({ id: 'common-yes' }),
  },
]

export const trimPhoneNumber = (value: string): string => {
  const trimmed = value.replace(/[ \-()]/g, '')
  return trimmed.startsWith('0') ? trimmed.substring(1) : trimmed
}

export const convertUndefinedToNull = <T>(formData: T): T => {
  if (formData === undefined) {
    return null as T
  }

  if (typeof formData !== 'object' || formData === null) {
    return formData
  }

  if (Array.isArray(formData)) {
    return formData.map(convertUndefinedToNull) as T
  }

  return Object.entries(formData).reduce((acc, [key, value]) => {
    acc[key as keyof T] = convertUndefinedToNull(value)
    return acc
  }, {} as T)
}

export const convertEmptyStringsToUndefined = (data: FieldValues | Primitive): FieldValues | Primitive => {
  if (typeof data === 'object' && data !== null) {
    for (const key in data) {
      if (data[key] !== null && data[key] !== undefined) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data[key] = convertEmptyStringsToUndefined(data[key]) as any
      }
    }
  }
  if (typeof data === 'string' && data === '') {
    return undefined
  }
  return data
}

export const getFieldError = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>(
  errors: FieldErrors<TFieldValues>,
  name: TFieldName,
): FieldError | undefined => {
  return get(errors, name)
}

import { assertValue, MAX_REASONABLE_NUMBER, SecLoanPurposeType, SecLoansValidationConstants } from '@shared'
import { formatNumber, toNumber } from '@ui-common/utils/helpers'
import { isValidPercentageValue, testIsValidMonetaryValue } from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { LoanBackgroundInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  hasCoApplicant: boolean,
  totalCost: number | undefined,
  applicationPurpose: SecLoanPurposeType,
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changeFields) => {
    only(changeFields)

    omitWhen(applicationPurpose === SecLoanPurposeType.Construction, () => {
      testIsValidMonetaryValue('ownMoney', data.ownMoney, formatMessage, MAX_REASONABLE_NUMBER, true, 0)

      test(
        `ownMoney`,
        formatMessage(
          { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
          {
            firstValue: formatMessage({
              id: 'sec-loans-loan-background-own-money-label',
            }),
            secondValue: formatMessage({ id: 'sec-loans-loan-background-info-cost-label' }),
          },
        ),
        () => {
          const ownMoney = assertValue(data.ownMoney, 'data.ownMoney')
          enforce(formatNumber(ownMoney) < Number(toNumber(totalCost))).isTruthy()
        },
      )
    })

    omitWhen(!hasCoApplicant, () => {
      test(
        'firstApplicantSharesOwnership',
        formatMessage(
          { id: 'common-validate-value-decimal' },
          {
            min: SecLoansValidationConstants.MIN_OWNERSHIP_SHARE,
            max: SecLoansValidationConstants.MAX_OWNERSHIP_SHARE,
            decimals: 2,
          },
        ),
        () => {
          const value = assertValue(data.firstApplicantSharesOwnership, 'data.firstApplicantSharesOwnership')
          enforce(isValidPercentageValue(value)).isTruthy()
          const numericValue = parseFloat(value.replaceAll(',', '.'))
          enforce(numericValue).isBetween(
            SecLoansValidationConstants.MIN_OWNERSHIP_SHARE,
            SecLoansValidationConstants.MAX_OWNERSHIP_SHARE,
          )
        },
      )
    })
  })
}

import {
  assertValue,
  LivingExpensesInfoData,
  OptionalAndNullable,
  SecLoanPurposeType,
  SecLoansHousingLoanTargetType,
} from '@shared'
import { convertUndefinedToNull } from '@ui-common/utils/form'
import { formatFinnishCurrencyValue, isNumber, numberToString, toNumber } from '@ui-common/utils/helpers'
import { COMMON_PLACEHOLDER } from '@ui-components/global/constants'
import { IntlShape } from 'react-intl'

import {
  isTargetTypeApartment,
  isTargetTypePartOwnership,
  isTargetTypeRightOfResidence,
} from '../../../../utils/helpers'
import { LivingExpensesInfoStepData, StepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { LivingExpensesInfoFormData } from './LivingExpensesInfoStep'

export const needsHousingCompanyLoanPayment = (
  isPayingCompanyLoanShare: boolean | undefined,
  targetType: SecLoansHousingLoanTargetType,
  applicationPurpose: SecLoanPurposeType,
): boolean => {
  if (isPayingCompanyLoanShare === undefined) {
    return false
  }
  const isRightOfResidence = isTargetTypeRightOfResidence(targetType)
  const isPartOwnership = isTargetTypePartOwnership(targetType)
  const isLoanTransfer = applicationPurpose === SecLoanPurposeType.LoanTransferToSbank

  return isPayingCompanyLoanShare === false && !isLoanTransfer && !isRightOfResidence && !isPartOwnership
}

export const convertToFormValues = (
  livingExpensesInfo: LivingExpensesInfoStepData | undefined,
): OptionalAndNullable<LivingExpensesInfoFormData> => {
  return convertUndefinedToNull({
    maintenanceFee: numberToString(livingExpensesInfo?.maintenanceFee),
    housingCompanyLoanPayment: numberToString(livingExpensesInfo?.housingCompanyLoanPayment),
    otherExpenses: numberToString(livingExpensesInfo?.otherExpenses),
  })
}

export const convertToLivingExpensesInfoSubmitData = (
  formData: LivingExpensesInfoFormData,
): LivingExpensesInfoStepData => {
  const { maintenanceFee, housingCompanyLoanPayment, otherExpenses } = formData
  return {
    maintenanceFee: assertValue(toNumber(maintenanceFee), 'maintenanceFee'),
    housingCompanyLoanPayment: toNumber(housingCompanyLoanPayment),
    otherExpenses: assertValue(toNumber(otherExpenses), 'otherExpenses'),
  }
}

export const hasTotalLivingExpenses = (
  maintenanceFee: number | undefined,
  housingCompanyLoanPayment: number | undefined,
  otherExpenses: number | undefined,
  isPayingCompanyLoanShare: boolean | undefined,
): boolean => {
  if (isPayingCompanyLoanShare !== undefined && isPayingCompanyLoanShare === false) {
    return maintenanceFee !== undefined && housingCompanyLoanPayment !== undefined && otherExpenses !== undefined
  }

  return maintenanceFee !== undefined && otherExpenses !== undefined
}

export const calculateTotalLivingExpenses = (
  maintenanceFee: number | undefined,
  housingCompanyLoanPayment: number | undefined,
  otherExpenses: number | undefined,
  isPayingCompanyLoanShare: boolean | undefined,
): number => {
  const hasTotal = hasTotalLivingExpenses(
    maintenanceFee,
    housingCompanyLoanPayment,
    otherExpenses,
    isPayingCompanyLoanShare,
  )
  if (!hasTotal) {
    return 0
  }
  // We are only asking housingCompanyLoanPayment (rahoitusvastike) when user is not going to pay housing company loan share (yhtiölainaosuus)
  if (isPayingCompanyLoanShare !== undefined && isPayingCompanyLoanShare === false) {
    return (maintenanceFee ?? 0) + (housingCompanyLoanPayment ?? 0) + (otherExpenses ?? 0)
  }
  return (maintenanceFee ?? 0) + (otherExpenses ?? 0)
}

export const resolveTotalLivingExpenses = (
  maintenanceFee: number | undefined,
  housingCompanyLoanPayment: number | undefined,
  otherExpenses: number | undefined,
  isPayingCompanyLoanShare: boolean | undefined,
): string => {
  if (isPayingCompanyLoanShare === false && !isNumber(housingCompanyLoanPayment)) {
    return COMMON_PLACEHOLDER
  }
  if (!isNumber(maintenanceFee) || !isNumber(otherExpenses)) {
    return COMMON_PLACEHOLDER
  }

  const livingExpenses = calculateTotalLivingExpenses(
    maintenanceFee,
    housingCompanyLoanPayment,
    otherExpenses,
    isPayingCompanyLoanShare,
  )
  return formatFinnishCurrencyValue(livingExpenses)
}

export const getAndValidateLivingExpensesInfoTypeData: StepDataValidator<LivingExpensesInfoData | undefined | null> = (
  validationData,
  metadata,
) => {
  const createValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, validationData)
  const targetType = validationData.targetInfo?.housingLoanInfo?.targetTypeInfo?.targetType

  const isTypeApartment = targetType && isTargetTypeApartment(targetType)
  const isTypePartOwnership = targetType && isTargetTypePartOwnership(targetType)
  const isTypeRightOfResidence = targetType && isTargetTypeRightOfResidence(targetType)
  const isConstructionPurpose = metadata?.applicationPurpose === SecLoanPurposeType.Construction

  if (isConstructionPurpose || (!isTypeApartment && !isTypeRightOfResidence && !isTypePartOwnership)) {
    return null
  }

  const livingExpensesInfoData = validationData.targetInfo?.livingExpensesInfoData
  const isValidData = isFormDataValid(convertToFormValues(livingExpensesInfoData), createValidator)

  return isValidData ? livingExpensesInfoData : undefined
}

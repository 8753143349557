import { SecLoanChildrenAgeGroup, SecLoansApplicants, SecLoansCreditCardTypes } from '@shared'
import { getCommonLocalizedOtherOption } from '@ui-common/utils/getCommonLocalizedEnums'
import { TextBadgeColors } from '@ui-components/global/ComponentProps'
import { IntlShape } from 'react-intl'

export const getMapEnum = (
  formatMessage: IntlShape['formatMessage'],
): {
  expenseInfoChildAgeLabelText: Record<SecLoanChildrenAgeGroup, string>
  creditCardTypeTexts: Record<SecLoansCreditCardTypes, string>
  ApplicantStickerColors: Record<SecLoansApplicants, TextBadgeColors>
} => {
  const otherOption = getCommonLocalizedOtherOption(formatMessage)

  const ApplicantStickerColors: Record<SecLoansApplicants, TextBadgeColors> = {
    [SecLoansApplicants.FirstApplicant]: 'info',
    [SecLoansApplicants.SecondApplicant]: 'warning',
    [SecLoansApplicants.BothApplicants]: 'neutral',
  }

  const expenseInfoChildAgeLabelText: Record<SecLoanChildrenAgeGroup, string> = {
    [SecLoanChildrenAgeGroup.ZeroToTen]: SecLoanChildrenAgeGroup.ZeroToTen,
    [SecLoanChildrenAgeGroup.ElevenToFifteen]: SecLoanChildrenAgeGroup.ElevenToFifteen,
    [SecLoanChildrenAgeGroup.SixteenToEighteen]: SecLoanChildrenAgeGroup.SixteenToEighteen,
  }

  const creditCardTypeTexts: Record<SecLoansCreditCardTypes, string> = {
    [SecLoansCreditCardTypes.Visa]: SecLoansCreditCardTypes.Visa,
    [SecLoansCreditCardTypes.MasterCard]: SecLoansCreditCardTypes.MasterCard,
    [SecLoansCreditCardTypes.AmericanExpress]: SecLoansCreditCardTypes.AmericanExpress,
    [SecLoansCreditCardTypes.DinersClub]: SecLoansCreditCardTypes.DinersClub,
    [SecLoansCreditCardTypes.Other]: otherOption,
  }

  return {
    creditCardTypeTexts,
    expenseInfoChildAgeLabelText,
    ApplicantStickerColors,
  }
}

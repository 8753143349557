import { YesNo } from '@ui-common-types'

import {
  BaseWizardContext,
  HierarchicalApplicationData,
  ResidenceInfoStepData,
  ResidenceInfoStepDataType,
} from '../../../types'

export interface ResidenceInfoStepContext extends ResidenceInfoStepDataType, BaseWizardContext {}

export type ResidenceInfoFormData = Omit<
  ResidenceInfoStepData,
  | 'numberOfRooms'
  | 'numberOfFloors'
  | 'livingSquareArea'
  | 'totalSquareArea'
  | 'lotSquareArea'
  | 'isPipeRenovationDone'
  | 'numberOfApartments'
  | 'hasSalesSite'
  | 'isCollateralForSBankLoan'
> &
  HierarchicalApplicationData & {
    livingSquareArea?: string
    totalSquareArea?: string
    lotSquareArea?: string
    numberOfRooms?: string
    numberOfRoomsOther?: string
    numberOfFloors?: string
    numberOfFloorsOther?: string
    isPipeRenovationDone: YesNo
    numberOfApartments?: string
    hasSalesSite: YesNo
    isCollateralForSBankLoan: YesNo
  }

export enum ResidenceAdditionalUiDataTypes {
  NumberOfRoomsOther = 'number of rooms (other)',
  NumberOfFloorsOther = 'number of floors (other)',
}

export enum ResidenceDataTypes {
  ResidenceType = 'residence type',
  LotType = 'lot ownership',
  NumberOfRooms = 'number of rooms',
  RoomLayout = 'room layout',
  NumberOfFloors = 'number of floors',
  BuildYear = 'year built',
  PipeRenovationDone = 'pipe renovation done',
  RenovationHistory = 'renovation history',
  PlannedRenovations = 'planned renovations',
  NumberOfApartments = 'number of apartments',
  SalesSite = 'sale ad origin',
  SalesSiteId = 'sales site id',
  SalesSiteOther = 'other sales site',
  HasSalesSite = 'has sale site',
  LivingArea = 'living area',
  TotalArea = 'total area',
  HeatingType = 'heating type',
  LotArea = 'lot area',
  RealEstateId = 'real estate id',
  IsCollateralForSBankLoan = 'is collateral for sbank loan',
  RenovationNeeds = 'renovation needs',
  TransactionDateKnown = 'transaction date',
  TransactionDate = 'exact or estimated transaction date',
}

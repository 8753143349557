import {
  assertValue,
  calculateCost,
  CurrentResidenceInfoData,
  getMoneyFromCurrentResidence,
  getPercentDifference,
  LoanAmounts,
  LoanBackgroundInfoData,
  OptionalAndNullable,
  SecLoanPurposeType,
  SecLoansCurrentResidenceSellingPlan,
} from '@shared'
import { convertUndefinedToNull } from '@ui-common/utils/form'
import { numberToString, toNumber } from '@ui-common/utils/helpers'
import { COMMON_PLACEHOLDER } from '@ui-components/global/constants'
import { IntlShape } from 'react-intl'

import { getLoanAmounts } from '../../../../utils/helpers'
import { StepDataValidator, WizardData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { shouldSkipLoanBackgroundStep } from '../loanInfoUtils'
import { createFormValidator } from './formValidator'
import { LoanBackgroundInfoFormData, LoanBackgroundInfoStepData } from './types'

export const convertToFormValues = (
  loanBackgroundInfoStepData: LoanBackgroundInfoStepData | undefined | null,
): OptionalAndNullable<LoanBackgroundInfoFormData> => {
  return convertUndefinedToNull({
    ownMoney: numberToString(loanBackgroundInfoStepData?.ownMoney),
    firstApplicantSharesOwnership: numberToString(loanBackgroundInfoStepData?.firstApplicantSharesOwnership),
  })
}

export const convertToLoanBackgroundInfoSubmitData = (
  formData: LoanBackgroundInfoFormData,
  loanAmounts: LoanAmounts,
): LoanBackgroundInfoStepData => {
  const { loanAmount, temporaryLoanAmount, finalLoanAmount } = loanAmounts

  return {
    ownMoney: toNumber(formData.ownMoney),
    firstApplicantSharesOwnership: toNumber(formData.firstApplicantSharesOwnership),
    loanAmount,
    temporaryLoanAmount,
    finalLoanAmount,
  }
}

export const getAndValidateLoanBackgroundInfoTypeData: StepDataValidator<LoanBackgroundInfoData | undefined | null> = (
  validationData,
  applicationMetadata,
) => {
  const metadata = assertValue(applicationMetadata, 'applicationMetadata')
  if (shouldSkipLoanBackgroundStep(metadata)) {
    return null
  }

  // These values are calculated again in case some dependant data has been changed
  const { loanBackgroundInfoData, totalCost } = getLoanBackgroundInfoData(metadata.applicationPurpose, validationData)

  const hasCoApplicant = !!validationData.applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant
  const applicationPurpose = metadata.applicationPurpose
  const formValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, hasCoApplicant, totalCost, applicationPurpose)

  return isFormDataValid(convertToFormValues(loanBackgroundInfoData), formValidator)
    ? loanBackgroundInfoData
    : undefined
}

export const getLoanBackgroundInfoData = (
  applicationPurpose: SecLoanPurposeType,
  wizardData: WizardData,
): { loanBackgroundInfoData: LoanBackgroundInfoData; totalCost: number } => {
  const { loanInfo } = wizardData
  const targetInfo = assertValue(wizardData.targetInfo, 'targetInfo')
  const { ownMoney, firstApplicantSharesOwnership } = loanInfo?.loanBackgroundInfoData ?? {}
  const totalCost = calculateCost(applicationPurpose, targetInfo)
  const { loanAmount, finalLoanAmount, temporaryLoanAmount } = getLoanAmounts(wizardData, totalCost, ownMoney)

  return {
    loanBackgroundInfoData: {
      loanAmount,
      finalLoanAmount,
      temporaryLoanAmount,
      ownMoney,
      firstApplicantSharesOwnership,
    },
    totalCost: assertValue(totalCost, 'totalCost'),
  }
}

export const getTotalMoneyFromCurrentResidence = (
  currentResidenceInfoData: CurrentResidenceInfoData | undefined,
): number => {
  const firstApplicantData = currentResidenceInfoData ?? {}
  const secondApplicantData = currentResidenceInfoData?.secondApplicant ?? {}

  const isFirstApplicantSellingFirst =
    firstApplicantData?.sellingPlan === SecLoansCurrentResidenceSellingPlan.SellingFirst
  const isSecondApplicantSellingFirst =
    secondApplicantData?.sellingPlan === SecLoansCurrentResidenceSellingPlan.SellingFirst

  const firstApplicantMoney = isFirstApplicantSellingFirst ? getMoneyFromCurrentResidence(firstApplicantData) : 0
  const secondApplicantMoney = isSecondApplicantSellingFirst ? getMoneyFromCurrentResidence(secondApplicantData) : 0

  return (firstApplicantMoney ?? 0) + (secondApplicantMoney ?? 0)
}

export const resolveSecondApplicantSharesOwnership = (firstApplicantSharesOwnership: number | undefined): string => {
  if (firstApplicantSharesOwnership === undefined) {
    return COMMON_PLACEHOLDER
  }

  const result = getPercentDifference(firstApplicantSharesOwnership)
  return assertValue(numberToString(result), 'result')
}

import {
  assertValue,
  Bank,
  SecLoansApplicants,
  SecLoansCurrentResidenceSellingPlan,
  SecLoansExpenseShareForNewFlat,
} from '@shared'
import { YesNo } from '@ui-common/types/types'
import { formatNumber } from '@ui-common/utils/helpers'
import { testIsValidMonetaryValue } from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { isApplicantNotHomeOwner } from './currentResidenceInfoStepUtils'
import { CurrentResidenceInfoStepFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  applicantWithHomeOwnership: SecLoansApplicants,
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changeFields) => {
    only(changeFields)

    omitWhen(isApplicantNotHomeOwner(applicantWithHomeOwnership, false), () => {
      test('sellingPlan', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.sellingPlan).inside(Object.values(SecLoansCurrentResidenceSellingPlan))
      })

      omitWhen(!data.sellingPlan || data.sellingPlan === SecLoansCurrentResidenceSellingPlan.NotForSale, () => {
        testIsValidMonetaryValue('price', data.price, formatMessage)
        test('hasRemainingLoan', formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(data.hasRemainingLoan).inside(Object.values(YesNo))
        })
        omitWhen(
          !data.price ||
            !data.expenseShareForNewFlatAmount ||
            data?.expenseShareForNewFlat !== SecLoansExpenseShareForNewFlat.OtherAmount,
          () => {
            testIsValidMonetaryValue(
              'expenseShareForNewFlatAmount',
              data.expenseShareForNewFlatAmount,
              formatMessage,
              undefined,
              undefined,
              0,
            )
            test(
              'expenseShareForNewFlatAmount',
              formatMessage(
                { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
                {
                  firstValue: formatMessage({ id: 'common-monetary-amount' }),
                  secondValue: formatMessage({ id: 'sec-loans-current-residence-price-label' }),
                },
              ),
              () => {
                const price = assertValue(data.price, 'data.price')
                const expenseShareForNewFlatAmount = assertValue(
                  data.expenseShareForNewFlatAmount,
                  'data.expenseShareForNewFlatAmount',
                )
                enforce(formatNumber(price) >= formatNumber(expenseShareForNewFlatAmount)).isTruthy()
              },
            )
          },
        )

        omitWhen(data.hasRemainingLoan !== YesNo.Yes, () => {
          testIsValidMonetaryValue('remainingLoanAmount', data.remainingLoanAmount, formatMessage)
          test(
            'remainingLoanAmount',
            formatMessage(
              { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
              {
                firstValue: formatMessage({ id: 'common-monetary-amount' }),
                secondValue: formatMessage({ id: 'sec-loans-current-residence-price-label' }),
              },
            ),
            () => {
              const price = assertValue(data.price, 'data.price')
              const remainingLoanAmount = assertValue(data.remainingLoanAmount, 'data.remainingLoanAmount')
              enforce(formatNumber(price) > formatNumber(remainingLoanAmount)).isTruthy()
            },
          )
          testIsValidMonetaryValue('remainingLoanInstallment', data.remainingLoanInstallment, formatMessage)
          test('lender', formatMessage({ id: 'validate-required-field-missing' }), () => {
            enforce(data.lender).inside(Object.values(Bank))
          })
          test('expenseShareForNewFlat', formatMessage({ id: 'validate-required-field-missing' }), () => {
            enforce(data.expenseShareForNewFlat).inside(Object.values(SecLoansExpenseShareForNewFlat))
          })
          omitWhen(!data.remainingLoanAmount || !data.remainingLoanInstallment, () => {
            test(
              'remainingLoanInstallment',
              formatMessage(
                { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
                {
                  firstValue: formatMessage({ id: 'sec-loans-current-residence-remaining-loan-installment-label' }),
                  secondValue: formatMessage({ id: 'common-monetary-amount' }),
                },
              ),
              () => {
                const remainingLoanAmount = assertValue(data.remainingLoanAmount, 'data.remainingLoanAmount')
                const remainingLoanInstallment = assertValue(
                  data.remainingLoanInstallment,
                  'data.remainingLoanInstallment',
                )
                enforce(formatNumber(remainingLoanAmount) >= formatNumber(remainingLoanInstallment)).isTruthy()
              },
            )
          })
          omitWhen(
            !data.expenseShareForNewFlat || data.expenseShareForNewFlat === SecLoansExpenseShareForNewFlat.All,
            () => {
              testIsValidMonetaryValue(
                'expenseShareForNewFlatAmount',
                data.expenseShareForNewFlatAmount,
                formatMessage,
                undefined,
                undefined,
                0,
              )
            },
          )
        })
      })
    })

    omitWhen(isApplicantNotHomeOwner(applicantWithHomeOwnership, true), () => {
      test('secondApplicant.sellingPlan', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.secondApplicant?.sellingPlan).inside(Object.values(SecLoansCurrentResidenceSellingPlan))
      })

      omitWhen(
        !data.secondApplicant?.sellingPlan ||
          data.secondApplicant?.sellingPlan === SecLoansCurrentResidenceSellingPlan.NotForSale,
        () => {
          testIsValidMonetaryValue('secondApplicant.price', data.secondApplicant?.price, formatMessage)
          test('secondApplicant.hasRemainingLoan', formatMessage({ id: 'validate-required-field-missing' }), () => {
            enforce(data.secondApplicant?.hasRemainingLoan).inside(Object.values(YesNo))
          })
          omitWhen(
            !data.secondApplicant?.price ||
              !data.secondApplicant?.expenseShareForNewFlatAmount ||
              data.secondApplicant?.expenseShareForNewFlat !== SecLoansExpenseShareForNewFlat.OtherAmount,
            () => {
              testIsValidMonetaryValue(
                'secondApplicant.expenseShareForNewFlatAmount',
                data.secondApplicant?.expenseShareForNewFlatAmount,
                formatMessage,
                undefined,
                undefined,
                0,
              )
              test(
                'secondApplicant.expenseShareForNewFlatAmount',
                formatMessage(
                  { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
                  {
                    firstValue: formatMessage({ id: 'common-monetary-amount' }),
                    secondValue: formatMessage({ id: 'sec-loans-current-residence-price-label' }),
                  },
                ),
                () => {
                  const price = assertValue(data.secondApplicant?.price, 'data.secondApplicant.price')
                  const expenseShareForNewFlatAmount = assertValue(
                    data.secondApplicant?.expenseShareForNewFlatAmount,
                    'data.secondApplicant.expenseShareForNewFlatAmount',
                  )
                  enforce(formatNumber(price) >= formatNumber(expenseShareForNewFlatAmount)).isTruthy()
                },
              )
            },
          )
          omitWhen(
            !data.secondApplicant?.hasRemainingLoan || data.secondApplicant?.hasRemainingLoan === YesNo.No,
            () => {
              testIsValidMonetaryValue(
                'secondApplicant.remainingLoanAmount',
                data.secondApplicant?.remainingLoanAmount,
                formatMessage,
              )
              test(
                'secondApplicant.remainingLoanAmount',
                formatMessage(
                  { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
                  {
                    firstValue: formatMessage({ id: 'common-monetary-amount' }),
                    secondValue: formatMessage({ id: 'sec-loans-current-residence-price-label' }),
                  },
                ),
                () => {
                  const price = assertValue(data.secondApplicant?.price, 'data.secondApplicant.price')
                  const remainingLoanAmount = assertValue(
                    data.secondApplicant?.remainingLoanAmount,
                    'data.secondApplicant.remainingLoanAmount',
                  )
                  enforce(formatNumber(price) > formatNumber(remainingLoanAmount)).isTruthy()
                },
              )
              testIsValidMonetaryValue(
                'secondApplicant.remainingLoanInstallment',
                data.secondApplicant?.remainingLoanInstallment,
                formatMessage,
              )
              test('secondApplicant.lender', formatMessage({ id: 'validate-required-field-missing' }), () => {
                enforce(data.secondApplicant?.lender).inside(Object.values(Bank))
              })
              test(
                'secondApplicant.expenseShareForNewFlat',
                formatMessage({ id: 'validate-required-field-missing' }),
                () => {
                  enforce(data.secondApplicant?.expenseShareForNewFlat).inside(
                    Object.values(SecLoansExpenseShareForNewFlat),
                  )
                },
              )
              omitWhen(
                !data.secondApplicant?.expenseShareForNewFlat ||
                  data.secondApplicant?.expenseShareForNewFlat === SecLoansExpenseShareForNewFlat.All,
                () => {
                  testIsValidMonetaryValue(
                    'secondApplicant.expenseShareForNewFlatAmount',
                    data.secondApplicant?.expenseShareForNewFlatAmount,
                    formatMessage,
                    undefined,
                    undefined,
                    0,
                  )
                },
              )
              omitWhen(
                !data.secondApplicant?.remainingLoanAmount || !data.secondApplicant?.remainingLoanInstallment,
                () => {
                  test(
                    'secondApplicant.remainingLoanInstallment',
                    formatMessage(
                      { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
                      {
                        firstValue: formatMessage({
                          id: 'sec-loans-current-residence-remaining-loan-installment-label',
                        }),
                        secondValue: formatMessage({ id: 'common-monetary-amount' }),
                      },
                    ),
                    () => {
                      const remainingLoanAmount = assertValue(
                        data.secondApplicant?.remainingLoanAmount,
                        'data.secondApplicant.remainingLoanAmount',
                      )
                      const remainingLoanInstallment = assertValue(
                        data.secondApplicant?.remainingLoanInstallment,
                        'data.secondApplicant.remainingLoanInstallment',
                      )
                      enforce(formatNumber(remainingLoanAmount) >= formatNumber(remainingLoanInstallment)).isTruthy()
                    },
                  )
                },
              )
            },
          )
        },
      )
    })
  })
}

import { assertValue } from '@shared'

import { StorageKey } from '../types'
import { debugLog } from './helpers'
import { getSessionStorageItem } from './storage'

const AUTH_CODE_MAX_LENGTH = 64

enum AuthQueryParam {
  AuthCode = 'code',
  AuthToken = 'id_token',
  AuthState = 'state',
  AuthErrorCode = 'error',
}

export enum AuthErrorCode {
  Cancelled = 'cancelled',
  Failed = 'failed',
  AccessDenied = 'access_denied',
  LoginRequired = 'login_required',
  TemporarilyUnavailable = 'temporarily_unavailable',
  ConsentRequired = 'consent_required',
  ServerError = 'server_error',
  UnmetAuthenticationRequirements = 'unmet_authentication_requirements',
}

const userAuthErrorCodes = [
  AuthErrorCode.AccessDenied,
  AuthErrorCode.Cancelled,
  AuthErrorCode.ConsentRequired,
  AuthErrorCode.LoginRequired,
  AuthErrorCode.Failed,
]

const validateCode = (code: string) => {
  return code.length > 0 && code.length <= AUTH_CODE_MAX_LENGTH
}

const isAuthErrorCode = (error: string | null): error is AuthErrorCode => {
  return Object.values(AuthErrorCode).includes(error as AuthErrorCode)
}

export const isAuthenticationServerError = (error: string | null): boolean => {
  return isAuthErrorCode(error) && !userAuthErrorCodes.includes(error)
}

export const cleanUpAuthQueryParams = (): void => {
  history.replaceState(null, '', ' ')
}

export type AuthCallbackResult = {
  authError?: AuthErrorCode | 'invalid' | 'cannotRedirect' | 'notAvailable'
  authErrorDescription?: string
  authCode?: string
  url?: string
  isBackNavigation?: boolean
}

type Params = {
  [AuthQueryParam.AuthCode]: string | null
  [AuthQueryParam.AuthErrorCode]: string | null
}

type ParsedParams = Pick<Params, AuthQueryParam.AuthCode | AuthQueryParam.AuthErrorCode>

export const parseParams = (hash: string): ParsedParams => {
  let params: Params = {
    code: null,
    error: null,
  }

  hash = decodeURIComponent(hash)

  if (hash && hash.length > 1) {
    params = hash
      .substring(1)
      .split('&')
      .reduce((obj, curr) => {
        const [key, value] = curr.split('=')
        return { ...obj, [assertValue(key, 'key')]: value }
      }, params)
  }

  const code = params[AuthQueryParam.AuthCode]
  const error = params[AuthQueryParam.AuthErrorCode]

  return { code, error }
}

export const isAuthCallback = (): boolean => {
  const { code, error } = parseParams(window.location.hash)
  return !!code || !!error
}

export const getAuthCallbackResult = (): AuthCallbackResult => {
  const { href, hash } = window.location
  debugLog('Current URL', href)
  const { code, error } = parseParams(hash)
  if (code === null && error === null) {
    // both query parameters are missing, so this is not a callback URL from authentication service
    return {
      authError: 'notAvailable',
      isBackNavigation: !!getSessionStorageItem(StorageKey.SignStarted),
    }
  } else if (!!code && validateCode(code)) {
    return { authCode: code }
  }
  let authError: AuthCallbackResult['authError']
  if (error === AuthErrorCode.AccessDenied) {
    authError = AuthErrorCode.Cancelled
  } else if (isAuthErrorCode(error)) {
    authError = error
  } else {
    authError = 'invalid'
  }
  return { url: href, authError }
}

export const parseAuthCode = async (): Promise<AuthCallbackResult> => {
  const result = getAuthCallbackResult()
  if (result.authError) {
    throw result
  }
  return result
}

export const isCancelledCallbackResult = (data: AuthCallbackResult): boolean => {
  const error = data?.authError
  const result = error === AuthErrorCode.Cancelled || error === AuthErrorCode.AccessDenied
  return result
}

import {
  MAX_REASONABLE_NUMBER,
  SecLoanHomeOwnershipHousingType,
  SecLoanHousingType,
  SecLoansApplicants,
  SecLoansApplicationMetadata,
  SecLoansValidationConstants,
} from '@shared'
import { YesNo } from '@ui-common/types/types'
import {
  testIsValidMonetaryValue,
  validateIntegerFromListOrOther,
  validatePastMonthAndYear,
} from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { HousingInfoFormData as FormData } from './HousingInfoStep'
import {
  getIsBuyOrConstructHousingLoan,
  getIsTransferHousingLoan,
  isHousingTypeWithApplicantOwnership,
} from './housingInfoStepUtils'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  hasCoApplicant: boolean,
  applicationMetadata: SecLoansApplicationMetadata,
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  const isBuyOrConstructHousingLoan = getIsBuyOrConstructHousingLoan(applicationMetadata)
  const isTransferHousingLoan = getIsTransferHousingLoan(applicationMetadata)

  return create((data, changedFields) => {
    only(changedFields)

    test('housingType', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.housingType).inside(Object.values(SecLoanHousingType))
    })

    omitWhen(!hasCoApplicant || !isBuyOrConstructHousingLoan, () => {
      test('isSameHousehold', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.isSameHousehold).inside(Object.values(YesNo))
      })
      omitWhen(!isHousingTypeWithApplicantOwnership(data.housingType) || data.isSameHousehold === YesNo.No, () => {
        test('ownership', formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(data.ownership).inside(Object.values(SecLoansApplicants))
        })
      })
      omitWhen(data.isSameHousehold === YesNo.Yes, () => {
        test('secondApplicant.housingType', formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(data.secondApplicant?.housingType).inside(Object.values(SecLoanHousingType))
        })
        omitWhen(data.secondApplicant?.housingType !== SecLoanHousingType.HomeOwnership, () => {
          test('secondApplicant.homeOwnershipType', formatMessage({ id: 'validate-required-field-missing' }), () => {
            enforce(data.secondApplicant?.homeOwnershipType).inside(Object.values(SecLoanHomeOwnershipHousingType))
          })
        })
      })
    })

    omitWhen(data.housingType !== SecLoanHousingType.HomeOwnership, () => {
      test('homeOwnershipType', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.homeOwnershipType).inside(Object.values(SecLoanHomeOwnershipHousingType))
      })
    })

    test('numberOfAdults', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.numberOfAdults).isBetween(1, 2)
    })

    validateIntegerFromListOrOther(
      'numberOfChildren',
      formatMessage,
      data.numberOfChildren,
      data.numberOfChildrenOther,
      SecLoansValidationConstants.MIN_NUMBER_OF_CHILDREN,
      SecLoansValidationConstants.MAX_NUMBER_OF_CHILDREN,
    )

    omitWhen(isBuyOrConstructHousingLoan, () => {
      omitWhen(isTransferHousingLoan, () => {
        testIsValidMonetaryValue(
          'monthlyLivingExpenses',
          data.monthlyLivingExpenses,
          formatMessage,
          MAX_REASONABLE_NUMBER,
          true,
          0,
        )
      })

      validatePastMonthAndYear({
        fieldName: 'moveInDate',
        formatMessage,
        dateToValidate: data.moveInDate,
        minimumYear: SecLoansValidationConstants.MINIMUM_MOVE_IN_YEAR_IN_PAST,
        isRequired: true,
      })
    })
  })
}

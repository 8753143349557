import {
  assertValue,
  calculateLoanTimeInMonths,
  convertMonthsToYearsAndMonths,
  OptionalAndNullable,
  PaymentPlanInfoData,
  SecLoanApplicationType,
  SecLoanPurposeType,
} from '@shared'
import { convertUndefinedToNull } from '@ui-common/utils/form'
import { isNumber, numberToString, toNumber } from '@ui-common/utils/helpers'
import { COMMON_PLACEHOLDER } from '@ui-components/global/constants'
import { IntlShape } from 'react-intl'

import { PaymentPlanInfoStepData, StepDataValidator, WizardData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { getRenovationLoanAmount } from '../../loan-target-phase/loan-cost-estimate-info-step/loanCostEstimateInfoStepUtils'
import { createFormValidator } from './formValidator'
import { PaymentPlanInfoFormData } from './PaymentPlanInfoStep'

export const convertToFormValues = (
  paymentPlanInfoStepData: PaymentPlanInfoStepData | undefined,
): OptionalAndNullable<PaymentPlanInfoFormData> => {
  const approximatedRepayment = paymentPlanInfoStepData?.approximatedRepayment
  return convertUndefinedToNull({
    methodOfRepayment: paymentPlanInfoStepData?.methodOfRepayment,
    repaymentFreeMonthsAmount: numberToString(paymentPlanInfoStepData?.repaymentFreeMonthsAmount),
    loanTimeInYears: numberToString(paymentPlanInfoStepData?.loanTimeInYears),
    approximatedRepayment: numberToString(approximatedRepayment),
  })
}

export const convertToPaymentPlanSubmitData = (formData: PaymentPlanInfoFormData): PaymentPlanInfoStepData => {
  const {
    methodOfRepayment: submitMethodOfRepayment,
    repaymentFreeMonthsAmount: submitRepaymentFreeMonthsAmount,
    loanTimeInYears: submitLoanTimeInYears,
    approximatedRepayment: submitApproximatedRepayment,
  } = formData
  return {
    methodOfRepayment: submitMethodOfRepayment,
    repaymentFreeMonthsAmount: assertValue(
      toNumber(submitRepaymentFreeMonthsAmount),
      'submitRepaymentFreeMonthsAmount',
    ),
    loanTimeInYears: toNumber(submitLoanTimeInYears),
    approximatedRepayment: toNumber(submitApproximatedRepayment),
  }
}

export const getLoanAmount = (
  wizardData: WizardData,
  applicationType: SecLoanApplicationType,
  applicationPurpose: SecLoanPurposeType,
): number | undefined => {
  const isLoanTransfer = applicationPurpose === SecLoanPurposeType.LoanTransferToSbank
  const isPurchaseLoan = applicationType === SecLoanApplicationType.PurchaseLoan
  const isRenovationLoan = applicationPurpose === SecLoanPurposeType.Renovation
  const { targetInfo, loanInfo } = wizardData
  if (isLoanTransfer && !isPurchaseLoan) {
    const loanAmount = targetInfo?.housingLoanInfo?.transferredLoanTargetData?.loanAmount
    return assertValue(loanAmount, 'loanAmount')
  }

  if (isRenovationLoan) {
    const { costEstimate, ownFinancingShare } = assertValue(
      targetInfo?.loanCostEstimateInfo,
      'targetInfo.loanCostEstimateInfo',
    )
    return getRenovationLoanAmount(costEstimate, ownFinancingShare)
  }

  if (isPurchaseLoan) {
    const loanAmount = targetInfo?.purchaseLoanInfo?.loanAmount
    return assertValue(loanAmount, 'loanAmount')
  }

  return loanInfo?.loanBackgroundInfoData?.finalLoanAmount ?? loanInfo?.loanBackgroundInfoData?.loanAmount
}

export const getAndValidatePaymentPlanInfoTypeData: StepDataValidator<PaymentPlanInfoData | undefined> = (
  validationData,
  applicationMetadata,
) => {
  const metadata = assertValue(applicationMetadata, 'applicationMetadata')
  const loanAmount = getLoanAmount(validationData, metadata.applicationType, metadata.applicationPurpose)

  return isFormDataValid(
    convertToFormValues(validationData.loanInfo?.paymentPlanInfoData),
    (formatMessage: IntlShape['formatMessage']) => createFormValidator(formatMessage, metadata, loanAmount),
  )
    ? validationData.loanInfo?.paymentPlanInfoData
    : undefined
}

export const getFormattedLoanTimeInYearsAndMonths = (
  formatMessage: IntlShape['formatMessage'],
  totalLoanAmount: number,
  approximatedRepayment: string | undefined,
  loanInterestRate: number,
): string => {
  const approximatedRepaymentNumber = toNumber(approximatedRepayment)

  if (isNumber(approximatedRepaymentNumber)) {
    const calculatedMonths = calculateLoanTimeInMonths(totalLoanAmount, loanInterestRate, approximatedRepaymentNumber)
    const { years, months } = convertMonthsToYearsAndMonths(calculatedMonths)
    return isNumber(years) && isNumber(months)
      ? formatMessage({ id: 'sec-loans-payment-plan-calculated-estimated-loan-time' }, { years, months })
      : COMMON_PLACEHOLDER
  }

  return COMMON_PLACEHOLDER
}

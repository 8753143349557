import {
  ANALYTICS_PRODUCT_NOT_APPLICABLE,
  AnalyticsData,
  AnalyticsService,
  NotAvailable,
  sendCustomDataEventToAnalytics,
  sendCustomEventToAnalytics,
  sendPageViewEventToAnalytics,
} from '@components/common/analytics'
import { Language, SecLoansFlow, SecLoansSource } from '@shared'

import { PhaseStep, PhaseStepType, RootStepType, StepType } from '../wizard/wizardSteps'

// Transforms "StepXyzInfo" into "DetailsXyz"
export type AnalyticsPhaseStep<S extends string> = S extends `Step${infer N}`
  ? AnalyticsPhaseStep<`Details${N}`>
  : S extends `${infer N}Info`
    ? AnalyticsPhaseStep<`${N}`>
    : S
export function toAnalyticsPhaseStep<T extends PhaseStepType>(p: T): AnalyticsPhaseStep<T> {
  return p.replace(/^Step/, 'Details').replace(/Info$/g, '') as AnalyticsPhaseStep<T>
}

export type SecLoansPageViewEventName =
  | 'EntraceStep'
  | 'RootStep'
  | 'EntranceRootStep'
  | 'StatusRootStep'
  | 'CatalogRootStep'
  | 'TheEndRootStep'
  | AnalyticsPhaseStep<PhaseStepType>

export type SecLoansModalViewEventName =
  | 'AbortModal'
  | 'PollAbortModal'
  | 'DiscardChangesModal'
  | 'ConfirmGoBackModal'
  | 'DeleteApplicationModal'
  | 'ApplicationSentModal'
  | 'ErrorPromptModal'
  | 'TooManyLoansModal'

export type SecLoansCustomEventName =
  | 'CancelButton'
  | 'ExitButton'
  | 'ConfirmButton'
  | 'ContinueButton'
  | 'BackButton'
  | 'SaveButton'
  | 'EditButton'
  | 'ShowAllLoanApplicationsButton'
  | 'DeleteApplicationButton'
  | 'SelectDraftApplication'
  | 'CreateNewApplication'
  | 'SubmitApplication'
  | 'ContinueToBookMeeting'
  | 'Exit'

export type SecLoansCustomEventCategory = SecLoansPageViewEventName | SecLoansModalViewEventName

export type SecLoansAnalyticsData = {
  readonly sourceChannel?: SecLoansSource
  readonly flow?: SecLoansFlow
  readonly language: Language
  readonly appVersion: string
}

export const analyticsStepToPageViewEventNameMap: Record<StepType, SecLoansPageViewEventName> = {
  [RootStepType.Root]: 'RootStep',
  [RootStepType.Entrance]: 'EntranceRootStep',
  [RootStepType.Status]: 'StatusRootStep',
  [RootStepType.Catalog]: 'CatalogRootStep',
  [RootStepType.TheEnd]: 'TheEndRootStep',
  ...(Object.fromEntries(PhaseStep.map((v) => [v, toAnalyticsPhaseStep(v)])) as Record<
    PhaseStepType,
    AnalyticsPhaseStep<PhaseStepType>
  >),
}

export class SecLoansAnalyticsService extends AnalyticsService<string, SecLoansCustomEventName> {
  private readonly analyticsData: AnalyticsData

  constructor(readonly data?: SecLoansAnalyticsData) {
    super()
    this.analyticsData = {
      src: data?.sourceChannel ?? NotAvailable,
      flow: data?.flow ?? NotAvailable,
      product: ANALYTICS_PRODUCT_NOT_APPLICABLE,
      version: data?.appVersion ?? NotAvailable,
      ui_lang: data?.language ?? NotAvailable,
    }
  }

  public sendPageViewEvent = (step: StepType): void => {
    sendPageViewEventToAnalytics(this.resolveAnalyticsPage(step), this.analyticsData)
  }

  public sendCustomEvent = (category: SecLoansCustomEventCategory, name: SecLoansCustomEventName): void => {
    sendCustomEventToAnalytics(category, name, this.analyticsData)
  }

  public sendCustomDataEvent = (data: { [key: string]: string }): void => {
    sendCustomDataEventToAnalytics(data)
  }

  public sendModalViewEvent = (modal: SecLoansModalViewEventName): void => {
    sendPageViewEventToAnalytics(modal, this.analyticsData)
  }

  public sendStepCustomEvent = (step: StepType, name: SecLoansCustomEventName): void => {
    this.sendCustomEvent(this.resolveAnalyticsPage(step), name)
  }

  public sendModalCustomEvent = (category: SecLoansModalViewEventName, name: SecLoansCustomEventName): void => {
    this.sendCustomEvent(category, name)
  }

  resolveAnalyticsPage = (step: StepType): SecLoansPageViewEventName => {
    return analyticsStepToPageViewEventNameMap[step]
  }
}

import {
  BackgroundInfoData,
  BackgroundInfoSecondApplicantData,
  IncomeInfoData,
  Occupation,
  OptionalAndNullable,
  OtherOccupation,
  SecLoanOtherIncome,
} from '@shared'
import { YesNo } from '@ui-common/types/types'
import { convertUndefinedToNull } from '@ui-common/utils/form'
import { getYesNoDefault, numberToString, toNumber } from '@ui-common/utils/helpers'
import { IntlShape } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'

import { getAndValidateApplicantData } from '../../../../utils/helpers'
import { ApplicantStepDataValidator, IncomeInfoStepBase, IncomeInfoStepData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { IncomeInfoFormData, OtherFormIncome } from './IncomeInfoStep'

export const convertToFormValues = (
  incomeInfo: IncomeInfoStepBase | undefined,
): OptionalAndNullable<IncomeInfoFormData> => {
  return convertUndefinedToNull({
    netSalary: numberToString(incomeInfo?.netSalary),
    grossSalary: numberToString(incomeInfo?.grossSalary),
    otherIncome: (incomeInfo?.otherIncome?.map((o) => ({
      ...o,
      amountPerMonth: o.amountPerMonth ? numberToString(o.amountPerMonth) : undefined,
      amountPerYear: o.amountPerYear ? numberToString(o.amountPerYear) : undefined,
      id: uuidv4(),
    })) ?? []) as OtherFormIncome[],
    hasOtherIncome: getYesNoDefault(!!incomeInfo, incomeInfo?.otherIncome?.length, YesNo.No),
  })
}

export const convertToIncomeInfoSubmitData = (formData: IncomeInfoFormData): IncomeInfoStepData => {
  const otherIncome: SecLoanOtherIncome[] = formData.otherIncome?.map((e: OtherFormIncome) => {
    return {
      ...e,
      id: undefined,
      amountPerMonth: toNumber(e.amountPerMonth),
      amountPerYear: toNumber(e.amountPerYear),
    }
  })
  return {
    grossSalary: toNumber(formData.grossSalary),
    netSalary: toNumber(formData.netSalary),
    otherIncome,
  }
}

export const shouldAskSalaryBasedOnBackgroundInfo = (
  backgroundInfoData: BackgroundInfoData | BackgroundInfoSecondApplicantData | undefined,
): boolean => {
  const occupation = backgroundInfoData?.occupation
  const hasValidEmploymentRelationship = backgroundInfoData?.hasValidEmploymentRelationship
  if (occupation && [Occupation.Unemployed, Occupation.Student, OtherOccupation.Other].includes(occupation)) {
    return false
  }
  if (!hasValidEmploymentRelationship && [OtherOccupation.OnFamilyLeave].includes(occupation as OtherOccupation)) {
    return false
  }
  return true
}

export const getAndValidateIncomeInfoTypeData: ApplicantStepDataValidator<IncomeInfoData> = (validationData) => {
  let incomeInfoData = validationData.applicantInfo?.incomeInfoData
  const hasCoApplicant = !!validationData.applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant

  const shouldAskSalary = shouldAskSalaryBasedOnBackgroundInfo(validationData.applicantInfo?.backgroundInfoData)
  const shouldAskSecondApplicantSalary = shouldAskSalaryBasedOnBackgroundInfo(
    validationData.applicantInfo?.backgroundInfoData?.secondApplicant,
  )

  if (incomeInfoData && !shouldAskSalary) {
    incomeInfoData = { ...incomeInfoData, netSalary: undefined, grossSalary: undefined }
  }

  if (incomeInfoData?.secondApplicant && !shouldAskSecondApplicantSalary) {
    incomeInfoData = {
      ...incomeInfoData,
      secondApplicant: {
        ...incomeInfoData.secondApplicant,
        netSalary: undefined,
        grossSalary: undefined,
      },
    }
  }

  const validateApplicant = (isSecondApplicant: boolean) => {
    const applicantData = isSecondApplicant ? incomeInfoData?.secondApplicant : incomeInfoData
    const createValidator = (formatMessage: IntlShape['formatMessage']) =>
      createFormValidator(formatMessage, isSecondApplicant ? shouldAskSecondApplicantSalary : shouldAskSalary)

    return isFormDataValid(convertToFormValues(applicantData), createValidator)
  }

  return getAndValidateApplicantData(validateApplicant, hasCoApplicant, incomeInfoData)
}

export const isIncomeDataComplete = (data: IncomeInfoStepData, askSalaryBasedOnBackgroundInfo: boolean): boolean =>
  askSalaryBasedOnBackgroundInfo ? !!data?.grossSalary && !!data?.netSalary : true

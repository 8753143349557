import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, only, Suite, test } from 'vest'

import { NumberOfApplicantsInfoFormData as FormData, SecLoansAloneOrTogether } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changeFields) => {
    only(changeFields)

    test('aloneOrTogether', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.aloneOrTogether).inside(Object.values(SecLoansAloneOrTogether))
    })
  })
}

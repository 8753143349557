import {
  assertValue,
  CurrentResidenceInfoData,
  OptionalAndNullable,
  SecLoanHomeOwnershipHousingType,
  SecLoansApplicants,
  SecLoansCurrentResidenceSellingPlan,
  SecLoansInvestment,
  SecLoansProperty,
  SecLoansPropertyType,
} from '@shared'
import { YesNo } from '@ui-common/types/types'
import { convertUndefinedToNull } from '@ui-common/utils/form'
import { getYesNoDefault, numberToString, toNumber } from '@ui-common/utils/helpers'
import { IntlShape } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'

import { getValidatedData } from '../../../../utils/helpers'
import { ApplicantInfoWizardData, ApplicantStepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { WealthInfoStepData } from './types'
import { SecLoansPropertyForm, WealthInfoStepFormData } from './WealthInfoStep'

const getPropertyType = (ownershipType: SecLoanHomeOwnershipHousingType) => {
  switch (ownershipType) {
    case SecLoanHomeOwnershipHousingType.HousingShareCooperative:
      return SecLoansPropertyType.HousingStock
    case SecLoanHomeOwnershipHousingType.DetachedHouseOnLeasedLand:
    case SecLoanHomeOwnershipHousingType.DetachedHouseOnOwnLand:
      return SecLoansPropertyType.Residential
    default:
      return SecLoansPropertyType.Other
  }
}

const getCurrentResidenceAsProperty = (
  properties: SecLoansProperty[] | undefined,
  currentResidenceInfoData: CurrentResidenceInfoData | undefined | null,
  ownershipType: SecLoanHomeOwnershipHousingType | undefined,
  ownership: SecLoansApplicants | undefined,
): SecLoansPropertyForm | undefined => {
  if (
    currentResidenceInfoData &&
    ownershipType &&
    ownership &&
    (currentResidenceInfoData.sellingPlan === SecLoansCurrentResidenceSellingPlan.SellingAfter ||
      currentResidenceInfoData.sellingPlan === SecLoansCurrentResidenceSellingPlan.SellingFirst)
  ) {
    const readOnlyProperty = properties?.findLast(
      (property) => property.isReadOnly === true && property?.ownership === ownership,
    )

    const companyDebtAmount =
      readOnlyProperty?.companyDebtAmount && ownershipType === SecLoanHomeOwnershipHousingType.HousingShareCooperative
        ? numberToString(readOnlyProperty.companyDebtAmount)
        : undefined

    const monthlyPaymentToCompanyDebt =
      readOnlyProperty?.monthlyPaymentToCompanyDebt &&
      ownershipType === SecLoanHomeOwnershipHousingType.HousingShareCooperative
        ? numberToString(readOnlyProperty.monthlyPaymentToCompanyDebt)
        : undefined

    return {
      id: uuidv4(),
      isReadOnly: true,
      value: numberToString(currentResidenceInfoData.price),
      ownership,
      propertyType: getPropertyType(ownershipType),
      hasCompanyDebt: getYesNoDefault(!!readOnlyProperty, readOnlyProperty?.companyDebtAmount, YesNo.No),
      companyDebtAmount,
      monthlyPaymentToCompanyDebt,
    }
  }
}

const getCurrentResidenceProperties = (applicantInfo: ApplicantInfoWizardData | undefined) => {
  const { currentResidenceInfoData } = applicantInfo || {}

  const properties: SecLoansPropertyForm[] = []
  const { homeOwnershipType, ownership } = applicantInfo?.housingInfoData ?? {}
  const { homeOwnershipType: secondApplicantOwnershipType } = applicantInfo?.housingInfoData?.secondApplicant ?? {}

  const firstApplicantsCurrentResidence = getCurrentResidenceAsProperty(
    applicantInfo?.wealthInfoData?.properties,
    currentResidenceInfoData,
    homeOwnershipType,
    ownership,
  )
  const secondApplicantsCurrentResidence = getCurrentResidenceAsProperty(
    applicantInfo?.wealthInfoData?.properties,
    currentResidenceInfoData?.secondApplicant,
    secondApplicantOwnershipType,
    SecLoansApplicants.SecondApplicant,
  )

  if (firstApplicantsCurrentResidence) {
    properties.push(firstApplicantsCurrentResidence)
  }

  if (secondApplicantsCurrentResidence) {
    properties.push(secondApplicantsCurrentResidence)
  }

  return properties
}

export const convertToFormValues = (
  applicantInfo: ApplicantInfoWizardData | undefined,
): OptionalAndNullable<WealthInfoStepFormData> => {
  const { wealthInfoData } = applicantInfo || {}

  const currentResidenceProperties = getCurrentResidenceProperties(applicantInfo)

  const data = convertUndefinedToNull({
    hasSavings: getYesNoDefault(!!wealthInfoData, wealthInfoData?.savingsAmount, YesNo.No),
    savingsAmount: numberToString(wealthInfoData?.savingsAmount),
    ownership: wealthInfoData?.ownership,
    hasProperties:
      currentResidenceProperties.length > 0
        ? YesNo.Yes
        : getYesNoDefault(!!wealthInfoData, wealthInfoData?.properties?.length, YesNo.No),
    properties: currentResidenceProperties.concat(
      wealthInfoData?.properties
        ?.filter((p) => !p.isReadOnly)
        .map((p) => ({
          ...p,
          isReadOnly: p.isReadOnly === true,
          id: uuidv4(),
          hasCompanyDebt: getYesNoDefault(!!p, p?.companyDebtAmount, YesNo.No),
          companyDebtAmount: numberToString(p.companyDebtAmount),
          monthlyPaymentToCompanyDebt: numberToString(p.monthlyPaymentToCompanyDebt),
          value: numberToString(p.value),
        })) || [],
    ),
    hasInvestments: getYesNoDefault(!!wealthInfoData, wealthInfoData?.investments?.length, YesNo.No),
    investments:
      wealthInfoData?.investments?.map((i) => ({
        ...i,
        id: uuidv4(),
        value: numberToString(i.value),
      })) || [],
  })

  return data
}

export const convertToWealthInfoSubmitData = (formData: WealthInfoStepFormData): WealthInfoStepData => {
  const properties =
    formData.hasProperties === YesNo.Yes
      ? formData.properties?.map((property): SecLoansProperty => {
          const hasCompanyDebt = property?.hasCompanyDebt === YesNo.Yes
          const value = assertValue(toNumber(property.value), 'property.value')

          return {
            isReadOnly: property.isReadOnly,
            propertyType: property.propertyType,
            companyDebtAmount: hasCompanyDebt ? toNumber(property.companyDebtAmount) : undefined,
            monthlyPaymentToCompanyDebt: hasCompanyDebt ? toNumber(property.monthlyPaymentToCompanyDebt) : undefined,
            ownership: property.ownership ?? SecLoansApplicants.FirstApplicant,
            value,
          }
        })
      : undefined
  const investments =
    formData.hasInvestments === YesNo.Yes
      ? formData.investments?.map((investment): SecLoansInvestment => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, ...rest } = investment
          const value = assertValue(toNumber(investment.value), 'investment.value')
          return {
            ...rest,
            value,
            ownership: investment.ownership ?? SecLoansApplicants.FirstApplicant,
          }
        })
      : undefined

  if (formData.savingsAmount) {
    return {
      savingsAmount: toNumber(formData.savingsAmount),
      ownership: formData.ownership ?? SecLoansApplicants.FirstApplicant,
      properties,
      investments,
    }
  }
  return {
    properties,
    investments,
  }
}

export const getAndValidateWealthInfoTypeData: ApplicantStepDataValidator<WealthInfoStepData> = (validationData) => {
  const hasCoApplicant = !!validationData.applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant

  const formValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, hasCoApplicant)

  const formData = convertToFormValues(validationData.applicantInfo)
  const isValid = isFormDataValid(formData, formValidator)

  const submitData = isValid
    ? convertToWealthInfoSubmitData(formData as WealthInfoStepFormData) // safe cast since formData is convertable if valid
    : validationData.applicantInfo?.wealthInfoData

  return getValidatedData(submitData, isValid, true)
}

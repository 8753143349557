import { Bank, SecLoansHousingLoanTargetType } from '@shared'
import { YesNo } from '@ui-common/types/types'

import { BaseWizardContext, HierarchicalApplicationData, TargetTypeInfoStepDataType } from '../../../types'

export interface LoanTransferTargetInfoStepContext extends TargetTypeInfoStepDataType, BaseWizardContext {}

export type LoanTransferTargetInfoFormData = HierarchicalApplicationData & {
  loanAmount: string
  resaleEstimate: string
  purchasePrice: string
  moveInMonth: string
  loanIssuer?: Bank
  loanMarginPercentage?: string | null
  loanMonthlyPayment: string | null
  isLoanForFirstTimePurchase: YesNo
  isAspLoan: YesNo
  loanTargetType: SecLoansHousingLoanTargetType
}

export enum LoanTransferTargetInputs {
  Header = 'header',
  LoanTargetType = 'loanTarget',
  LoanAmount = 'loanAmount',
  ResaleEstimate = 'resaleEstimate',
  PurchasePrice = 'purchasePrice',
  MoveInMonth = 'moveInMonth',
  LoanIssuer = 'loanIssuer',
  LoanMargin = 'loanMargin',
  LoanPayment = 'loanPayment',
  IsFirstTimeBuyerLoan = 'isFirstTimeBuyer',
  isAspLoan = 'isAspLoan',
}

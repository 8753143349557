import { convertEmptyStringsToUndefined, trimStrings } from '@ui-common/utils'
import { FormDevTool } from '@ui-common/utils/FormDevTool'
import { ReactNode, useCallback } from 'react'
import {
  DefaultValues,
  FieldErrors,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form'
import * as R from 'remeda'

import { useScrollToFormError } from './useScrollToFormError'

export interface UseWizardFormReturn<TFieldValues extends FieldValues> extends UseFormReturn<TFieldValues> {
  FormDevTool: () => ReactNode
  triggerScrollToError: () => void
}

export interface UseWizardFormProps<TFieldValues extends FieldValues = FieldValues> extends UseFormProps<TFieldValues> {
  shouldConvertEmptyStringsToUndefined?: boolean
  hasAsyncValidation?: boolean
}

export const useWizardFormShouldUnregisterAsFalse = <TFieldValues extends FieldValues = FieldValues>({
  resolver,
  defaultValues = {} as DefaultValues<TFieldValues>,
}: UseWizardFormProps<TFieldValues> = {}): UseWizardFormReturn<TFieldValues> => {
  return useWizardForm({ resolver, defaultValues, shouldUnregister: false })
}

export const useWizardForm = <TFieldValues extends FieldValues = FieldValues>({
  resolver,
  defaultValues = {} as DefaultValues<TFieldValues>,
  shouldUnregister = true,
  mode = 'onTouched',
  shouldConvertEmptyStringsToUndefined = true,
  hasAsyncValidation = false,
}: UseWizardFormProps<TFieldValues> = {}): UseWizardFormReturn<TFieldValues> => {
  const { handleSubmit, formState, ...rest } = useForm<TFieldValues>({
    mode,
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: false,
    resolver,
    defaultValues,
    shouldUnregister,
  })

  // If the form is using async validation by doing the validation requests in an onSubmit handler we need to
  // subscribe to formState.errors proxy by accessing it here and to get the automatic scrolling working with useScrollToFormError.
  // TODO: DIG-5194 Delete hasAsyncValidation flag and this if statement when async validation is done in vest validator
  if (hasAsyncValidation) {
    void formState.errors
  }
  const { scrollToFirstErroredFormElement, triggerScrollToError } = useScrollToFormError(formState)

  const wrappedHandleSubmit = useCallback(
    (submitHandler: SubmitHandler<TFieldValues>, errorHandler?: SubmitErrorHandler<TFieldValues>) => {
      const processFormData = (data: TFieldValues) => {
        // Data has to be cloned because react-hook-form can mutate the data after submit
        const commonParams = [data, R.clone, trimStrings] as const
        return shouldConvertEmptyStringsToUndefined
          ? R.pipe(...commonParams, convertEmptyStringsToUndefined)
          : R.pipe(...commonParams)
      }

      const wrappedSubmitHandler = async (data: TFieldValues) => {
        const processedFormData = processFormData(data)
        await submitHandler(processedFormData as TFieldValues)
      }
      const wrappedErrorHandler = (errors: FieldErrors<TFieldValues>) => {
        scrollToFirstErroredFormElement(errors)
        if (errorHandler) {
          errorHandler(errors)
        }
      }
      return handleSubmit(wrappedSubmitHandler, wrappedErrorHandler)
    },
    [handleSubmit, scrollToFirstErroredFormElement, shouldConvertEmptyStringsToUndefined],
  )

  return {
    handleSubmit: wrappedHandleSubmit,
    triggerScrollToError,
    FormDevTool,
    formState,
    ...rest,
  }
}

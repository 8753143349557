import { AnalyticsContextValue } from '@components/common/analytics'
import { getLanguage } from '@ui-common/utils/language'
import { createContext, useContext, useMemo } from 'react'

import { SecLoansAnalyticsService } from '../services/analyticsService'
import { getSecLoansFlow, getSecLoansSourceChannel } from '../utils/helpers'

type SecLoansAnalyticsContextValue = AnalyticsContextValue<SecLoansAnalyticsService>

const SecLoansAnalyticsContext = createContext<SecLoansAnalyticsContextValue>({
  analyticsService: new SecLoansAnalyticsService(undefined),
})

export const SecLoansAnalyticsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const analyticsProviderValue = useMemo(
    () => ({
      analyticsService: new SecLoansAnalyticsService({
        appVersion: import.meta.env.VITE_APP_VERSION,
        flow: getSecLoansFlow(),
        sourceChannel: getSecLoansSourceChannel(),
        language: getLanguage(),
      }),
    }),
    [],
  )

  return (
    <SecLoansAnalyticsContext.Provider value={analyticsProviderValue}>{children}</SecLoansAnalyticsContext.Provider>
  )
}

export const useAnalyticsService = () => {
  const context = useContext(SecLoansAnalyticsContext)

  if (context === undefined) {
    throw new Error('useAnalytics must be used within a SecLoansAnalyticsProvider')
  }

  return context
}

import { MonthlySavingsInfoData } from '@shared'
import { YesNo } from '@ui-common/types/types'
import { convertUndefinedToNull } from '@ui-common/utils/form'
import { getYesNoDefault, numberToString, toNumber } from '@ui-common/utils/helpers'
import { IntlShape } from 'react-intl'

import { MonthlySavingsInfoStepData, StepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { MonthlySavingsInfoStepFormData } from './types'

const getApplicantFormData = (
  monthlySavingsData: MonthlySavingsInfoStepData | undefined,
  isSecondApplicant: boolean,
): MonthlySavingsInfoStepFormData => {
  const data = isSecondApplicant ? monthlySavingsData?.secondApplicant : monthlySavingsData
  return convertUndefinedToNull({
    isInterestedInSavings: getYesNoDefault(!!data, data?.savingsAmount, YesNo.No),
    savingsAmount: numberToString(data?.savingsAmount),
  })
}

const getApplicantSubmitData = (
  formData: MonthlySavingsInfoStepFormData,
  isSecondApplicant: boolean,
): MonthlySavingsInfoData => {
  const data = isSecondApplicant ? formData?.secondApplicant : formData
  return {
    savingsAmount: data?.isInterestedInSavings === YesNo.Yes ? toNumber(data.savingsAmount) : undefined,
  }
}

export const convertToFormValues = (
  monthlySavingsData: MonthlySavingsInfoStepData | undefined,
  hasCoApplicant: boolean,
): MonthlySavingsInfoStepFormData => {
  return {
    ...getApplicantFormData(monthlySavingsData, false),
    secondApplicant: hasCoApplicant ? getApplicantFormData(monthlySavingsData, true) : undefined,
  }
}

export const convertToStepData = (
  formData: MonthlySavingsInfoStepFormData,
  hasCoApplicant: boolean,
): MonthlySavingsInfoStepData => ({
  ...getApplicantSubmitData(formData, false),
  secondApplicant: hasCoApplicant ? getApplicantSubmitData(formData, true) : undefined,
})

export const getAndValidateMonthlySavingsInfoTypeData: StepDataValidator<MonthlySavingsInfoStepData | undefined> = (
  validationData,
) => {
  const hasCoApplicant = !!validationData.applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant
  const data = validationData.loanInfo?.monthlySavingsInfoData
  const formValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, hasCoApplicant)

  return isFormDataValid(convertToFormValues(data, hasCoApplicant), formValidator) ? data : undefined
}

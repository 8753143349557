import { SecLoansCompanyForm, SecLoansIndustrySector } from '@sec-loans-types'
import { SecLoansValidationConstants } from '@shared'
import {
  MINIMUM_REASONABLE_YEAR,
  validateBusinessId,
  validatePastMonthAndYear,
  validateShortTextInput,
} from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, group, only, Suite, test } from 'vest'

import { previousEmploymentValidator } from '../employment-info-step/formValidator'
import { EntrepreneurInfoFormData as FormData } from './EntrepreneurInfoStep'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> =>
  create((data, changedFields) => {
    only(changedFields)

    group('companyName', () => {
      validateShortTextInput(
        'companyName',
        formatMessage,
        data.companyName,
        SecLoansValidationConstants.SHORT_TEXT_INPUT_MAX_LENGTH,
        true,
      )
    })

    group('businessId', () => {
      validateBusinessId({ fieldName: 'businessId', formatMessage, idToValidate: data.businessId, isRequired: true })
    })

    test('legalFormOfTheCompany', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.legalFormOfTheCompany).inside(Object.values(SecLoansCompanyForm))
    })

    test('industrySector', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.industrySector).inside(Object.values(SecLoansIndustrySector))
    })

    validatePastMonthAndYear({
      fieldName: 'entrepreneurshipStart',
      formatMessage,
      dateToValidate: data.entrepreneurshipStart,
      minimumYear: MINIMUM_REASONABLE_YEAR,
      isRequired: true,
    })

    previousEmploymentValidator(formatMessage, data, data.entrepreneurshipStart)
  })

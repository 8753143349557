import { SecLoansLoanOrCreditType } from '@sec-loans-types'
import { assertValue, MAX_REASONABLE_NUMBER, MIN_REASONABLE_NUMBER } from '@shared'
import { Bank } from '@shared-types'
import { YesNo } from '@ui-common/types/types'
import { formatNumber } from '@ui-common/utils/helpers'
import { testIsValidMonetaryValue, validateIntegerFromListOrOther } from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import {
  loansAndCreditsBothApplicants,
  loansAndCreditsFirstApplicant,
  loansAndCreditsSecondApplicant,
} from './loansAndCreditsInfoStepUtils'
import { LoansAndCreditsInfoStepFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  hasCoApplicant: boolean,
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changeFields) => {
    only(changeFields)
    for (const { hasProp, dataProp } of [
      loansAndCreditsBothApplicants,
      loansAndCreditsFirstApplicant,
      loansAndCreditsSecondApplicant,
    ]) {
      const hasLoansAndCredits = data[hasProp]
      const loansAndCredits = data[dataProp]
      test(hasProp, formatMessage({ id: 'validate-required-field-missing' }), () => {
        const atLeastOneChoiceExists = !!(
          data[loansAndCreditsBothApplicants.hasProp] ||
          data[loansAndCreditsFirstApplicant.hasProp] ||
          data[loansAndCreditsSecondApplicant.hasProp]
        )
        enforce(atLeastOneChoiceExists).isTruthy()
      })

      omitWhen(hasLoansAndCredits !== YesNo.Yes, () => {
        Object.values(loansAndCredits ?? []).forEach((lac, index) => {
          const isReadOnly = !!lac.isReadOnly

          omitWhen(isReadOnly, () => {
            test(
              `${dataProp}.${index}.loanOrCreditType`,
              formatMessage({ id: 'validate-required-field-missing' }),
              () => {
                enforce(lac.loanOrCreditType).inside(Object.values(SecLoansLoanOrCreditType))
              },
            )

            test(`${dataProp}.${index}.lender`, formatMessage({ id: 'validate-required-field-missing' }), () => {
              enforce(lac.lender).inside(Object.values(Bank))
            })

            testIsValidMonetaryValue(`${dataProp}.${index}.amount`, lac.amount, formatMessage)

            omitWhen(lac.loanOrCreditType !== SecLoansLoanOrCreditType.StudentLoan, () => {
              test(
                `${dataProp}.${index}.hasStartedPayingBackStudentLoan`,
                formatMessage({ id: 'validate-required-field-missing' }),
                () => {
                  enforce(lac.hasStartedPayingBackStudentLoan).inside(Object.values(YesNo))
                },
              )
            })

            const isNotPayingBackStudentLoan =
              lac.loanOrCreditType === SecLoansLoanOrCreditType.StudentLoan &&
              lac.hasStartedPayingBackStudentLoan === YesNo.No

            testIsValidMonetaryValue(
              `${dataProp}.${index}.installment`,
              lac.installment,
              formatMessage,
              MAX_REASONABLE_NUMBER,
              true,
              isNotPayingBackStudentLoan ? 0 : MIN_REASONABLE_NUMBER,
            )

            test(
              `${dataProp}.${index}.installment`,
              formatMessage(
                { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
                {
                  firstValue: formatMessage({
                    id: 'sec-loans-loans-and-credits-info-loan-credit-installment-form-label',
                  }),
                  secondValue: formatMessage({ id: 'sec-loans-loans-and-credits-info-loan-credit-amount-form-label' }),
                },
              ),
              () => {
                const installment = assertValue(lac.installment, 'lac.installment')
                const amount = assertValue(lac.amount, 'lac.amount')
                enforce(formatNumber(installment) <= formatNumber(amount)).isTruthy()
              },
            )
          })
        })
      })
    }

    test('hasQuickLoans', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasQuickLoans).inside(Object.values(YesNo))
    })

    omitWhen(data.hasQuickLoans !== YesNo.Yes, () => {
      validateIntegerFromListOrOther(
        'numberOfQuickLoans',
        formatMessage,
        data.numberOfQuickLoans,
        data.numberOfQuickLoansOther,
      )

      testIsValidMonetaryValue('quickLoansAmount', data.quickLoansAmount, formatMessage)

      testIsValidMonetaryValue('quickLoansInstallment', data.quickLoansInstallment, formatMessage)

      test(
        'quickLoansInstallment',
        formatMessage(
          { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
          {
            firstValue: formatMessage({
              id: 'sec-loans-applicant-info-quick-loans-installment-label',
            }),
            secondValue: formatMessage({ id: 'common-monetary-amount' }),
          },
        ),
        () => {
          const quickLoansInstallment = assertValue(data.quickLoansInstallment, 'data.quickLoansInstallment')
          const quickLoansAmount = assertValue(data.quickLoansAmount, 'data.quickLoansAmount')
          enforce(formatNumber(quickLoansInstallment) <= formatNumber(quickLoansAmount)).isTruthy()
        },
      )
    })

    omitWhen(!hasCoApplicant, () => {
      test('secondApplicant.hasQuickLoans', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.secondApplicant?.hasQuickLoans).inside(Object.values(YesNo))
      })

      omitWhen(data.secondApplicant?.hasQuickLoans !== YesNo.Yes, () => {
        validateIntegerFromListOrOther(
          'secondApplicant.numberOfQuickLoans',
          formatMessage,
          data.secondApplicant?.numberOfQuickLoans,
          data.secondApplicant?.numberOfQuickLoansOther,
        )

        testIsValidMonetaryValue(
          'secondApplicant.quickLoansAmount',
          data.secondApplicant?.quickLoansAmount,
          formatMessage,
        )

        testIsValidMonetaryValue(
          'secondApplicant.quickLoansInstallment',
          data.secondApplicant?.quickLoansInstallment,
          formatMessage,
        )

        test(
          'secondApplicant.quickLoansInstallment',
          formatMessage(
            { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
            {
              firstValue: formatMessage({
                id: 'sec-loans-applicant-info-quick-loans-installment-label',
              }),
              secondValue: formatMessage({ id: 'common-monetary-amount' }),
            },
          ),
          () => {
            const quickLoansInstallment = assertValue(
              data.secondApplicant?.quickLoansInstallment,
              'data.secondApplicant.quickLoansInstallment',
            )
            const quickLoansAmount = assertValue(
              data.secondApplicant?.quickLoansAmount,
              'data.secondApplicant.quickLoansAmount',
            )
            enforce(formatNumber(quickLoansInstallment) <= formatNumber(quickLoansAmount)).isTruthy()
          },
        )
      })
    })
  })
}

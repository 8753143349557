import { SecLoansValidationConstants } from '@shared'
import { validateTextArea } from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, only, Suite } from 'vest'

import { AdditionalInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changedFields) => {
    only(changedFields)
    const isAdditionalInfoRequired = false

    validateTextArea(
      formatMessage,
      'additionalInfo',
      data.additionalInfo,
      SecLoansValidationConstants.ADDITIONAL_INFO_MAX_LENGTH,
      isAdditionalInfoRequired,
    )
  })
}

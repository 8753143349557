import { OptionalAndNullable, SecLoansApplicationMetadata } from '@shared'
import { debugLog } from '@ui-common/utils/helpers'
import { FieldName, FieldValues } from 'react-hook-form'
import { IntlShape, MessageDescriptor } from 'react-intl'
import { Suite } from 'vest'

import { getApplicantInfoPhaseData } from './steps/applicant-info-phase/phaseData'
import { getLoanInfoPhaseData } from './steps/loan-info-phase/phaseData'
import { getTargetInfoPhaseData } from './steps/loan-target-phase/phaseData'
import { WizardData } from './types'

export const getAppData = (applicationData: WizardData, metadata: SecLoansApplicationMetadata): WizardData => {
  const targetInfoPhaseData = getTargetInfoPhaseData(applicationData, metadata)
  const targetInfoValidated: WizardData = { ...applicationData, targetInfo: targetInfoPhaseData?.targetInfo }
  const applicantInfoPhaseData = getApplicantInfoPhaseData(targetInfoValidated, metadata)
  const targetAndApplicantInfoValidated: WizardData = {
    ...applicationData,
    targetInfo: targetInfoPhaseData?.targetInfo,
    applicantInfo: applicantInfoPhaseData?.applicantInfo,
  }
  const loanInfoPhaseData = getLoanInfoPhaseData(targetAndApplicantInfoValidated, metadata)

  const appData: WizardData = {
    ...targetInfoPhaseData,
    ...applicantInfoPhaseData,
    ...loanInfoPhaseData,
  }

  appData.isComplete =
    targetInfoPhaseData?.targetInfo?.isComplete &&
    applicantInfoPhaseData?.applicantInfo?.isComplete &&
    loanInfoPhaseData?.loanInfo?.isComplete

  return appData
}

export const isFormDataValid = <TDataParam extends FieldValues>(
  data: OptionalAndNullable<TDataParam> | undefined,
  createFormValidator: (
    formatMessage: IntlShape['formatMessage'],
  ) => Suite<string, string, (data: TDataParam, changedFields?: FieldName<TDataParam>[]) => void>,
): boolean => {
  if (!data) {
    return false
  }

  const intlMock = { formatMessage: (_message: MessageDescriptor) => '' }
  const suite = createFormValidator(intlMock.formatMessage)
  const runAllValidations = undefined
  const result = suite(data as TDataParam, runAllValidations)

  const errors = result.getErrors()
  debugLog('isFormDataValid', errors)

  return !!result.valid
}

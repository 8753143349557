import { AdditionalInfoData } from '@shared'
import { convertUndefinedToNull } from '@ui-common/utils/form'
import { resolveFormEmptyString } from '@ui-common/utils/helpers'

import { getValidatedData } from '../../../../utils/helpers'
import { AdditionalInfoStepData, ApplicantStepDataValidator, WizardData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { AdditionalInfoFormData } from './types'

export const getAndValidateAdditionalInfoTypeData: ApplicantStepDataValidator<AdditionalInfoData> = ({
  applicantInfo,
}: WizardData) => {
  const additionalInfoData = applicantInfo?.additionalInfoData

  const isValid = isFormDataValid(additionalInfoData, createFormValidator)
  return getValidatedData(additionalInfoData, isValid)
}

export const convertToFormValues = (
  additionalInfoStepData: AdditionalInfoStepData | undefined,
): AdditionalInfoFormData => {
  const data = {
    additionalInfo: additionalInfoStepData?.additionalInfo,
  }
  return convertUndefinedToNull(data)
}

export const convertToSubmitData = (formData: AdditionalInfoFormData): AdditionalInfoStepData => {
  return {
    additionalInfo: resolveFormEmptyString(formData?.additionalInfo),
  }
}

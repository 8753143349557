import { MAX_REASONABLE_NUMBER } from '@shared'
import { YesNo } from '@ui-common/types/types'
import { testIsValidMonetaryValue } from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { MonthlySavingsInfoStepFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  hasCoApplicant: boolean,
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changedFields) => {
    only(changedFields)

    test('isInterestedInSavings', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.isInterestedInSavings).inside(Object.values(YesNo))
    })

    omitWhen(data.isInterestedInSavings !== YesNo.Yes, () => {
      testIsValidMonetaryValue('savingsAmount', data.savingsAmount, formatMessage, MAX_REASONABLE_NUMBER)
    })

    omitWhen(!hasCoApplicant, () => {
      test('secondApplicant.isInterestedInSavings', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.secondApplicant?.isInterestedInSavings).inside(Object.values(YesNo))
      })

      omitWhen(data.secondApplicant?.isInterestedInSavings !== YesNo.Yes, () => {
        testIsValidMonetaryValue(
          'secondApplicant.savingsAmount',
          data.secondApplicant?.savingsAmount,
          formatMessage,
          MAX_REASONABLE_NUMBER,
        )
      })
    })
  })
}

import {
  BestTimeToReach,
  Language,
  MaritalStatus,
  Occupation,
  OtherOccupation,
  SchoolDegree,
  SecLoansEmploymentType,
  SecLoansValidationConstants,
} from '@shared'
import { PHONE_COUNTRY_CODE, YesNo } from '@ui-common/types/types'
import {
  hasPostalCodeOnly,
  testIsValidMonetaryValue,
  validatePersonName,
  validateShortTextInput,
  validateSsn,
} from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { BackgroundInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  isFirstTimeHomeBuyerQuestionRequired: boolean,
  isSecondApplicant: boolean,
  firstApplicantSsn?: string,
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changeFields) => {
    only(changeFields)

    test('isFinnishCitizen', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.isFinnishCitizen).inside(Object.values(YesNo))
    })

    test('maritalStatus', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.maritalStatus).inside(Object.values(MaritalStatus))
    })

    omitWhen(isSecondApplicant, () => {
      test('bestTimeToReach', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.bestTimeToReach).inside(Object.values(BestTimeToReach))
      })
    })

    test('highestDegree', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.highestDegree).inside(Object.values(SchoolDegree))
    })

    omitWhen(!isFirstTimeHomeBuyerQuestionRequired, () => {
      test('isFirstHomeBuyer', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.isFirstHomeBuyer).inside(Object.values(YesNo))
      })
    })

    omitWhen(data.isFirstHomeBuyer !== YesNo.Yes, () => {
      test('isAspSaver', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.isAspSaver).inside(Object.values(YesNo))
      })

      omitWhen(data.isAspSaver !== YesNo.Yes, () => {
        testIsValidMonetaryValue('aspAmount', data.aspAmount, formatMessage)
      })
    })

    test('occupation', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.occupation).inside([...Object.values(Occupation), ...Object.values(OtherOccupation)])
    })

    omitWhen(data.occupation !== OtherOccupation.OnFamilyLeave, () => {
      test('hasValidEmploymentRelationship', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.hasValidEmploymentRelationship).inside(Object.values(YesNo))
      })
    })

    omitWhen(data.occupation !== Occupation.Employed, () => {
      test('employmentType', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.employmentType).inside(Object.values(SecLoansEmploymentType))
      })
    })

    test('phoneNumber', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.phoneNumber).isNotEmpty()
    })

    omitWhen(!data.phoneNumber, () => {
      test('phoneNumber', formatMessage({ id: 'validate-finnish-mobile-phone-format-error' }), () => {
        enforce(PHONE_COUNTRY_CODE + data.phoneNumber).isValidFinnishMobilePhoneNumber()
      })
    })

    omitWhen(!data.email, () => {
      test('email', () => {
        enforce(data.email).isValidEmail(formatMessage)
      })
    })

    omitWhen(!isSecondApplicant, () => {
      validatePersonName(
        'firstName',
        formatMessage,
        data.firstName,
        SecLoansValidationConstants.SHORT_TEXT_INPUT_MAX_LENGTH,
        true,
      )

      validatePersonName(
        'lastName',
        formatMessage,
        data.lastName,
        SecLoansValidationConstants.SHORT_TEXT_INPUT_MAX_LENGTH,
        true,
      )

      validateSsn({ fieldName: 'ssn', formatMessage, valueToValidate: data.ssn, isRequired: true })

      omitWhen(!firstApplicantSsn, () => {
        test('ssn', formatMessage({ id: 'sec-loans-validate-must-differ-from-primary-applicant' }), () => {
          enforce(data.ssn?.toUpperCase()).notEquals(firstApplicantSsn?.toUpperCase())
        })
      })

      validateShortTextInput(
        'streetAddress',
        formatMessage,
        data.streetAddress,
        SecLoansValidationConstants.SHORT_TEXT_INPUT_MAX_LENGTH,
        true,
      )

      test('postcode', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.postcode).isNotEmpty()
      })
      test('postcode', formatMessage({ id: 'validate-common-finnish-postcode-length' }), () => {
        enforce(data.postcode).hasPostalCodeOnly()
      })
      omitWhen(!hasPostalCodeOnly(data.postcode ?? '') || data.postOffice === undefined, () => {
        test('postcode', formatMessage({ id: 'common-unknown-postcode' }), () => {
          enforce(data.postOffice).isNotNull()
          enforce(data.postOffice).longerThan(0)
        })
      })

      test('serviceLanguage', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.serviceLanguage).inside(Object.values(Language))
      })
    })
  })
}

import { MAX_REASONABLE_NUMBER } from '@shared'
import { YesNo } from '@ui-common/types/types'
import { testIsValidMonetaryValue } from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { RenovationsInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changedFields) => {
    only(changedFields)

    test('hasUpcomingRenovations', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasUpcomingRenovations).inside(Object.values(YesNo))
    })

    test('hasOtherPurchases', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasUpcomingRenovations).inside(Object.values(YesNo))
    })

    omitWhen(data.hasUpcomingRenovations !== YesNo.Yes, () => {
      testIsValidMonetaryValue(
        'renovationsAmount',
        data.renovationsAmount,
        formatMessage,
        MAX_REASONABLE_NUMBER,
        true,
        0,
      )
    })

    omitWhen(data.hasOtherPurchases !== YesNo.Yes, () => {
      testIsValidMonetaryValue(
        'otherPurchasesAmount',
        data.otherPurchasesAmount,
        formatMessage,
        MAX_REASONABLE_NUMBER,
        true,
        0,
      )
    })
  })
}

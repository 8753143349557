import { modalMachine } from '@components/common/Modal/modalMachine'
import { useActorRef } from '@xstate/react'
import { createContext, ReactNode, useMemo } from 'react'
import { ActorRefFrom } from 'xstate'

interface GlobalServiceContext {
  modalService: ActorRefFrom<typeof modalMachine>
  isModalOpen: boolean
}

export const GlobalServicesContext = createContext({} as GlobalServiceContext)

export const GlobalServiceProvider = (props: { children: ReactNode }) => {
  const modalService = useActorRef(modalMachine)
  const state = modalService.getSnapshot()

  const serviceMemo = useMemo(
    () => ({ modalService, isModalOpen: !!state.context.isOpen }),
    [modalService, state.context.isOpen],
  )

  return <GlobalServicesContext.Provider value={serviceMemo}>{props.children}</GlobalServicesContext.Provider>
}

import { OptionalAndNullable, SecLoansApplicants } from '@shared'
import { YesNo } from '@ui-common/types/types'
import { convertUndefinedToNull } from '@ui-common/utils/form'
import { getYesNoDefault, numberToString, toNumber } from '@ui-common/utils/helpers'
import { IntlShape } from 'react-intl'

import { getValidatedData } from '../../../../utils/helpers'
import { ApplicantStepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { InstallmentInfoFormData, InstallmentInfoStepData } from './types'

export const convertToFormValues = (
  stepData: InstallmentInfoStepData | undefined,
): OptionalAndNullable<InstallmentInfoFormData> => {
  return convertUndefinedToNull({
    totalInstallment: numberToString(stepData?.totalInstallment),
    monthlyInstallment: numberToString(stepData?.monthlyInstallment),
    installmentOwnership: stepData?.installmentOwnership,
    hasInstallment: getYesNoDefault(!!stepData, stepData?.hasInstallment),
  })
}

export const convertToInstallmentInfoSubmitData = (
  formData: InstallmentInfoFormData,
): InstallmentInfoStepData | undefined => {
  if (!formData) {
    return undefined
  }
  return {
    installmentOwnership:
      formData.hasInstallment === YesNo.Yes && !formData.installmentOwnership
        ? SecLoansApplicants.FirstApplicant
        : formData.installmentOwnership,
    monthlyInstallment: toNumber(formData.monthlyInstallment),
    totalInstallment: toNumber(formData.totalInstallment),
    hasInstallment: formData.hasInstallment === YesNo.Yes,
  }
}

export const getValidatedInstallmentInfoStepData: ApplicantStepDataValidator<InstallmentInfoStepData> = (
  validationData,
) => {
  const installmentInfoData = validationData.applicantInfo?.installmentInfoData
  const hasCoApplicant = !!validationData.applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant

  const formValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, hasCoApplicant)
  const isValid = isFormDataValid(convertToFormValues(installmentInfoData), formValidator)

  return getValidatedData(installmentInfoData, isValid)
}

import {
  assertValue,
  Bank,
  MAX_REASONABLE_NUMBER,
  SecLoanPurposeType,
  SecLoansPurchaseLoanPurpose,
  SecLoansValidationConstants,
} from '@shared'
import { formatNumber } from '@ui-common/utils/helpers'
import { testIsValidMonetaryValue, validateShortTextInput } from '@ui-common/utils/validation'
import { FieldName } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { PurchaseLoanInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  loanPurpose: SecLoanPurposeType,
): Suite<string, string, (data: FormData, changedFields?: FieldName<FormData>[]) => void> => {
  return create((data, changedFields) => {
    only(changedFields)

    testIsValidMonetaryValue('loanAmount', data.loanAmount, formatMessage)

    test('loanPurpose', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.loanPurpose).inside(Object.values(SecLoansPurchaseLoanPurpose))
    })

    omitWhen(data.loanPurpose !== SecLoansPurchaseLoanPurpose.Other, () => {
      validateShortTextInput(
        'otherPurpose',
        formatMessage,
        data.otherPurpose,
        SecLoansValidationConstants.PURCHASE_LOAN_OTHER_PURPOSE_MAX_LENGTH,
        true,
      )
    })

    omitWhen(loanPurpose !== SecLoanPurposeType.LoanTransferToSbank, () => {
      test('loanIssuer', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.loanIssuer).inside(Object.values(Bank))
      })
      testIsValidMonetaryValue('loanMonthlyPayment', data.loanMonthlyPayment, formatMessage, MAX_REASONABLE_NUMBER)

      test(
        'loanMonthlyPayment',
        formatMessage(
          { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
          {
            firstValue: formatMessage({
              id: 'sec-loans-loan-transfer-target-loan-montly-installment-form-label',
            }),
            secondValue: formatMessage({ id: 'sec-loans-loan-transfer-target-loan-amount-form-label' }),
          },
        ),
        () => {
          const loanMonthlyPayment = assertValue(data.loanMonthlyPayment, 'data.loanMonthlyPayment')
          const loanAmount = assertValue(data.loanAmount, 'data.loanAmount')
          enforce(formatNumber(loanMonthlyPayment) <= formatNumber(loanAmount)).isTruthy()
        },
      )
    })
  })
}
